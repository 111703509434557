import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardTitle type definitions ...">
type RuleNames = "modalOrganizationRoot";

export interface ModalOrganizationProps {
  children: React.ReactNode
}

export type ModalOrganizationStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardTitle style definition ...">
const useStyles = createUseStyles<RuleNames, ModalOrganizationStyleProps, Theme>(
  (theme) => {
    return {
      modalOrganizationRoot: {
        ...theme.typography.h5,
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(4)
      },
    };
  }
);
// </editor-fold>

const ModalOrganization = (props: ModalOrganizationProps) => {
  const { children } = props
  const { modalOrganizationRoot } = useStyles()
  return (
    <h6 className={modalOrganizationRoot}>
      {children}
    </h6>
  );
};

export default ModalOrganization;