import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../../styles/theme";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

// <editor-fold desc="Alert type definitions ...">
type RuleNames = "alertRoot" | "alertTitle" | "alertMessage" | "alertIcon";
type AllowedSeverity = "error" | "info" | "warning" | "success";

export interface AlertProps {
  severity?: AllowedSeverity
  title: string
  message: string
}

export type AlertStyleProps = {
  severity: AllowedSeverity
};
// </editor-fold>

// <editor-fold desc="Alert style definition ...">
const useStyles = createUseStyles<RuleNames, AlertStyleProps, Theme>(
  (theme) => {
    return {
      alertRoot: {
        padding: theme.spacing(3),
        color: ({severity}) => theme.palette[severity].extraDark,
        backgroundColor: ({severity}) => theme.palette[severity].extraLight,
        display: "flex",
        margin: [theme.spacing(4), 0],
        [theme.breakpoints.up('tablet')]: {
          margin: [theme.spacing(6), 0],
        },
        [theme.breakpoints.up('desktop')]: {
          margin: [theme.spacing(8), 0],
        },
      },
      alertTitle: {
        ...theme.typography.h5,
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: theme.spacing(2)
      },
      alertMessage: {
        ...theme.typography.p
      },
      alertIcon: ({severity}) => {
        return {
          color: theme.palette[severity].main,
        }},
    };
  }
);
// </editor-fold>

const getIcon = (severity: AllowedSeverity, alertIcon: string) => {
  switch (severity) {
    case "error": {
      return <ErrorOutlineIcon className={alertIcon} />
    }
    case "warning": {
      return <WarningAmberIcon className={alertIcon} />
    }
    case "info": {
      return <InfoOutlinedIcon className={alertIcon} />
    }
    case "success": {
      return <CheckCircleOutlineOutlinedIcon className={alertIcon} />
    }
    default: {
      console.error("severity is not defined")
    }
  }
}

const Alert = (props: AlertProps) => {
  const {
    severity = "error",
    title,
    message
  } = props

  const { alertRoot, alertTitle, alertMessage, alertIcon } = useStyles({severity})
  return (
    <div className={alertRoot}>
      {getIcon(severity, alertIcon)}
      <div style={{marginLeft: 8}}>
        {alertTitle ? <h5 className={alertTitle}>{title}</h5> : null}
        <p className={alertMessage}>{message}</p>
      </div>
    </div>
  );
};

export default Alert;
