import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardImg type definitions ...">
type RuleNames = string;

export interface CardImgProps {
  src: string;
  alt: string;
}

export type CardImgStyleProps = {
  imageUrl: string
};
// </editor-fold>

// <editor-fold desc="CardBody style definition ...">
const useStyles = createUseStyles<RuleNames, CardImgStyleProps, Theme>(
  (theme) => {
    return {
      cardImgRoot: {
        backgroundImage: ({imageUrl}) => `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: "223px"
      }
    };
  }
);
// </editor-fold>

const CardImg = (props: CardImgProps) => {
  const { src, alt } = props
  const { cardImgRoot } = useStyles({imageUrl: src})
  return (
    <div className={cardImgRoot} />
  )
};

export default CardImg;
