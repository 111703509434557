import { createTheme } from "@material-ui/core/styles";


export const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#4F89BC"
            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: "#4F89BC"
            }
        },
        MuiPickersClockNumber: {
            clockNumberSelected:{
                backgroundColor: "#4F89BC"
            }
        }
        ,
        MuiPickersClockPointer:{
            pointer:{
                backgroundColor: "#4F89BC"
            }
        }
    }
})

