import { Checkbox, FormControl, InputLabel, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { Option } from "./SelectField";

// <editor-fold desc="SelectField type definitions ...">
type RuleNames = 'multipleSelectFilterRoot'
    | 'multipleSelectFilterLabel'
    | 'multipleSelectFilterInput'
    | 'multipleSelectFilterItem';

export interface Item {
    id: number;
    name: string;
    value: string | number
}

export interface SelectFieldProps {
    id: string;
    name: string;
    label: string;
    value?: string;
    autoComplete?: string;
    margin?: 'default' | 'dense' | 'none';
    disabled?: boolean;
    handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    handleRemoveSelection?: (id: number) => void;
    required?: boolean;
    options: Option[] | undefined;
    multiple?: boolean;
    defaultValueText?: string
}

export type SelectFieldStyleProps = {
    disabled: boolean;
    margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="TextField style definition ...">
const useStyles = createUseStyles<RuleNames, SelectFieldStyleProps, Theme>(
    (theme) => {
        return {
            multipleSelectFilterRoot: {
                margin: ({ margin }) => {
                    switch (margin) {
                        case 'none': {
                            return 0
                        }
                        case 'dense': {
                            return `${theme.spacing(2)}px 0px`
                        }
                        default: {
                            return `${theme.spacing(4)}px 0px`
                        }
                    }
                },
                width: "250px",
            },
            multipleSelectFilterInput: {
                boxShadow: [0, 3, 2, "#E9ECEF0D"],
                border: [1, "solid", "#DEE2E6"],
                borderRadius: theme.spacing(1),
                padding: theme.spacing(2),
                ...theme.typography.input
            },
            multipleSelectFilterLabel: {
                color: "#596675",
                ...theme.typography.input
            },
            multipleSelectFilterItem: {
                '&:hover': {
                    backgroundColor: theme.palette.action.hover
                }
            }
        }
    }
);
// </editor-fold>

// Currently saves selected items in component. Should be somewhere else?
const MultipleSelectFilter = (props: SelectFieldProps) => {
    const {
        id,
        name,
        label,
        value,
        margin = 'default',
        disabled = false,
        required = false,
        options,
        handleChange,
        defaultValueText
    } = props
    const {
        multipleSelectFilterRoot,
        multipleSelectFilterLabel,
        multipleSelectFilterInput,
        multipleSelectFilterItem,
    } = useStyles({ disabled, margin })

    return (
        <div className={multipleSelectFilterRoot}>
            <label className={multipleSelectFilterLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
            <div style={{ width: "100%", marginTop: 4 }}>
                <select
                    style={{width: "100%", boxSizing: "border-box"}}
                    className={multipleSelectFilterInput}
                    id={multipleSelectFilterInput}
                    onChange={handleChange}
                    value={value}
                >
                    <option value={""}>{defaultValueText}</option>
                    {options?.map(option => {
                        const { name, id } = option
                        return (
                            <option value={id}>{name}</option>
                        )
                    })}
                </select>
            </div>
        </div>
    );
};

export default MultipleSelectFilter;