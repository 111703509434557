interface Color {
  main: string;
  light: string;
  dark: string;
  contrastText: string;
}

interface SeverityColor extends Color {
  extraLight: string;
  extraDark: string;
}

export interface Palette {
  primary: Color;
  secondary: Color;
  error: SeverityColor;
  warning: SeverityColor;
  success: SeverityColor;
  info: SeverityColor;
  common: {
    grey: "#757575";
    test: "rgba(0, 0, 0, 0.08)";
    white: "#fff";
    black: "rgba(0, 0, 0, 0.87)";
    transparent: "rgba(0, 0, 0, 0)";
  };
  background: {
    paper: "#fff";
    default: "#F0F2F5";
  };
  action: {
    disabledBackground: "rgba(0,0,0,0.12)";
    disabled: "rgba(0,0,0,0.26)";
    hover: "rgba(0, 0, 0, 0.04)";
  };
  text: {
    primary: "#707070";
    secondary: "#70707066";
    disabled: "rgba(0,0,0,0.38)";
    hint: "rgba(0,0,0,0.38)";
  };
  divider: "#D0D0D0";
}

const black = "rgba(0, 0, 0, 0.87)";
const white = "#fff";
const transparent = "rgba(0, 0, 0, 0)";

export default {
  // primary color for theme
  primary: {
    main: "#323232",
    light: "#909090",
    dark: "#030303",
    contrastText: white,
  },

  // secondary color for theme
  secondary: {
    main: "#4F89BC",
    light: "#87bded",
    dark: "#032340",
    contrastText: white,
  },

  // error color for theme
  error: {
    main: "#F44336",
    light: "#FF00001A",
    dark: "#820000",
    contrastText: white,
    extraLight: "#FDEDF7",
    extraDark: "#5F2120"
  },

  // warning color for theme
  warning: {
    main: "#FF9800",
    light: "#FFCF4D",
    dark: "#BF6400",
    contrastText: black,
    extraLight: "#FFF4E5",
    extraDark: "#663C00"
  },

  // success color for theme
  success: {
    main: "#4CAF50",
    light: "#85E783",
    dark: "#00791E",
    contrastText: black,
    extraLight: "#EDF7ED",
    extraDark: "#1E4620"
  },

  // info color for theme
  info: {
    main: "#2196F3",
    light: "#75CCFF",
    dark: "#0064BA",
    contrastText: white,
    extraLight: "#E5F6FD",
    extraDark: "#014361"
  },

  // common colors
  common: {
    grey: "#757575",
    test: "rgba(0, 0, 0, 0.08)",
    white,
    black: black,
    transparent: transparent,
  },

  // background colors
  background: {
    paper: white,
    default: "#F0F2F5",
  },

  // action colors
  action: {
    disabledBackground: "rgba(0,0,0,0.12)",
    disabled: "rgba(0,0,0,0.26)",
    hover: "rgba(0, 0, 0, 0.04)",
  },

  // text colors
  text: {
    // primary: black,
    primary: "#707070",
    secondary: "#70707066",
    disabled: "rgba(0,0,0,0.38)",
    hint: "rgba(0,0,0,0.38)",
  },

  // Divider color
  divider: "#D0D0D0",
};
