import {
  CLEAR_TOKEN,
  GET_TOKEN_ERROR,
  GET_TOKEN_PENDING,
  GET_TOKEN_SUCCESS,
  GET_USER_ERROR,
  GET_USER_PENDING,
  GET_USER_SUCCESS,
  SET_WORKSPACE
} from "./actionTypes";
import {UserActionTypes, UserState} from "./types";

const initialState: UserState = {
  workspace: undefined,
  loggedIn: false,
  token: {
    loading: false,
    data: {
      access: "",
      refresh: "",
    },
    error: {
      data: undefined,
      message: "",
    }
  },
  userData: {
    loading: false,
    data: undefined,
    error: {
      data: undefined,
      message: "",
    },
  }
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case GET_TOKEN_PENDING:
      return {
        ...state,
        token: {
          ...state.token,
          error: initialState.token.error,
          loading: true
        },
      };
    case GET_TOKEN_SUCCESS:
      localStorage.setItem('accessToken', action.token.access);
      localStorage.setItem('refreshToken', action.token.refresh);
      return {
        ...state,
        loggedIn: true,
        token: {
          ...state.token,
          data: action.token,
          loading: false
        },
      };
    case GET_TOKEN_ERROR:
      return {
        ...state,
        token: {
          loading: false,
          data: initialState.token.data,
          error: action.error
        },
      };
    case CLEAR_TOKEN:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return initialState;
    case GET_USER_PENDING:
      return {
        ...state,
        userData: {
          ...state.userData,
          error: initialState.userData.error,
          loading: true
        }
      };
    case GET_USER_SUCCESS:
      const accessToken = localStorage.getItem('accessToken') || "";
      const refreshToken = localStorage.getItem('refreshToken') || "";
      const defaultWorkspace = action.user?.organization_user_roles?.[0]?.organization?.id;
      if(!state.loggedIn && accessToken ) {
        return {
          ...state,
          workspace: defaultWorkspace,
          loggedIn: true,
          token: {
            ...state.token,
            data: {
              access: accessToken,
              refresh: refreshToken,
            }
          },
          userData: {
            ...state.userData,
            data: action.user,
            loading: false
          }
        };
      }
      return {
        ...state,
        workspace: defaultWorkspace,
        userData: {
          ...state.userData,
          data: action.user,
          loading: false
        }
      };
    case GET_USER_ERROR:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          error: {
            data: undefined,
            message: "error"
          }
        }
      };
    case SET_WORKSPACE:
      return {
        ...state,
        workspace: action.workspace
      };
    default:
      return state;
  }
}

export default userReducer;
