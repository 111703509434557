import { Grid, Modal } from "@mui/material";
import { display } from "@mui/system";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Enums } from "../../App";
import { Theme } from "../../styles/theme";
import { OrganizationCardObject } from "../../views/Home";
import Button from "../Buttons";
import ModalBody from "./ModalBody";
import ModalImg from "./ModalImg";
import ModalOrganization from "./ModalOrganization";
import ModalTags from "./ModalTags";
import ModalTitle from "./ModalTitle";

type RuleNames = string;

export interface CardModalProps {
    organizationCard: OrganizationCardObject;
    setModalState: Function,
    enums: Enums
}

export type OrganizationCardModalStyleProps = {};

const useStyles = createUseStyles<RuleNames, OrganizationCardModalStyleProps, Theme>(
    (theme) => {
        return {
            organizationCardModalRoot: {
                paddingTop: "32px",
                paddingBottom: "32px",
                overflow: "scroll",
                margin: "auto",
                display: "flex",
                position: "relative"
            },
            organizationCardModalContent: {
                backgroundColor: theme.palette.background.paper,
                width: "90%",
                maxWidth: "730px",
                display: "inline-block",
                margin: "auto",
                height: "fit-content",
                position: "relative"
            },
            organizationLogo: {
                backgroundColor: theme.palette.background.paper,
                boxShadow: "0px 3px 6px #00000029",
                height: 125,
                maxWidth: 250,
                position: 'absolute',
                top: "20px",
                left: "20px",
                zIndex: 5000
            },
        };
    }
);

function getEnumNames (enums: any[], keys: any[]) {
    return(enums.filter(i => keys.includes(i.key)))
}

const CardModal = (props: CardModalProps) => {
    const {
        organizationCard,
        setModalState,
        enums
    } = props

    const [cardTags, setCardTags] = useState(getEnumNames(enums.tags, organizationCard.tags))
    const [cardThemes, setCardThemes] = useState(getEnumNames(enums.themes, organizationCard.themes))
    const [cardTypes, setCardTypes] = useState(getEnumNames(enums.types, organizationCard.types))
    const [cardRegions, setCardRegions] = useState(getEnumNames(enums.regions, organizationCard.regions))

    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false)
        setModalState(null)
    };

    //These for example, will get tags, regions etc from backend
    const chipTexts = ["tulevat", "tähän"]

    return (
        <Modal
            className={classes.organizationCardModalRoot}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            disableAutoFocus={true}
        >
            <div className={classes.organizationCardModalContent}>
                {organizationCard.organization.logo && organizationCard.organization.name ? (
                    <img className={classes.organizationLogo} src={organizationCard.organization.logo} alt={`${organizationCard.organization.name}`} />
                ) : null}
                <ModalImg src={organizationCard.image || organizationCard.organization.image} alt={`${organizationCard.organization.name}`}></ModalImg>
                <ModalTitle>
                    {organizationCard.title}
                </ModalTitle>
                <ModalOrganization>
                    {organizationCard.organization.name}
                </ModalOrganization>
                <ModalTags title="Toimialueet" tags={cardRegions.map(i => i.value)}></ModalTags>
                <ModalTags title="Teemat" tags={cardThemes.map(i => i.value)}></ModalTags>
                <ModalTags title="Tyypit" tags={cardTypes.map(i => i.value)}></ModalTags>
                <ModalTags title="Tagit" tags={cardTags.map(i => i.value)}></ModalTags>
                <ModalBody>
                    {organizationCard.description}
                </ModalBody>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                >
                    <Grid item>
                        <Button color="orange" handleClick={handleClose}>Sulje ikkuna</Button>
                    </Grid>
                    <Grid item>
                        <Button color="blue" handleClick={() => navigate(`/service_card/${organizationCard.id}/info`, {replace: true})}>Ota yhteyttä</Button>
                    </Grid>
                </Grid>
            </div>
        </Modal >
    )
}

export default CardModal;