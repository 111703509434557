import { TheaterComedySharp, ThermostatSharp } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import React from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";

// <editor-fold desc="CardTitle type definitions ...">
type RuleNames = "modalTagsRoot" | "modalTagsHeader" | "modalTagsChips";

export interface ModalTagsProps {
    title: string,
    tags: Array<string> | undefined
}

export type ModalTagsStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardTitle style definition ...">
const useStyles = createUseStyles<RuleNames, ModalTagsStyleProps, Theme>(
    (theme) => {
        return {
            modalTagsRoot: {
                ...theme.typography.p,
                marginLeft: theme.spacing(4),
                marginTop: theme.spacing(1),
                display: "flex",
                alignItems: "center"
            },
            modalTagsHeader: {
                margin: 0,
                minWidth: "90px"
            },
            modalTagsChips: {

            }
        };
    }
);
// </editor-fold>

const ModalTags = (props: ModalTagsProps) => {
    const { modalTagsRoot, modalTagsHeader, modalTagsChips } = useStyles()
    return (
        <div className={modalTagsRoot}>
            <p className={modalTagsHeader}><strong>{props.title}</strong></p>
            <div className={modalTagsChips}>
                {props.tags?.map((tag, idx) => {
                    return (
                        <Chip
                            key={idx}
                            {...undefined}
                            label={tag}
                            size="small"
                            style={{ marginRight: "4px", marginTop: "4px" }}
                        >
                        </Chip>
                    )
                })
                }
            </div>
        </div>
    );
};

export default ModalTags;
