import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardImg type definitions ...">
type RuleNames = string;

export interface ModalImgProps {
  src: any;
  alt: any;
}

export type ModalImgStyleProps = {
  imageUrl: string
};
// </editor-fold>

// <editor-fold desc="CardBody style definition ...">
const useStyles = createUseStyles<RuleNames, ModalImgStyleProps, Theme>(
  (theme) => {
    return {
      modalImgRoot: {
        position: "relative",
        backgroundImage: ({imageUrl}) => `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: "410px"
      }
    };
  }
);
// </editor-fold>

const ModalImg = (props: ModalImgProps) => {
  const { src, alt } = props
  const { modalImgRoot } = useStyles({imageUrl: src})
  return (
    <div className={modalImgRoot} />
  )
};

export default ModalImg;
