import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../../styles/theme";

// <editor-fold desc="TextField type definitions ...">
type RuleNames = 'numberFieldRoot' | 'numberFieldLabel' | 'numberFieldInput'

export interface NumberFieldProps {
  id: string;
  name: string;
  label: string;
  value: string;
  placeholder?: string;
  autoComplete?: string;
  margin?: 'default' | 'dense' | 'none';
  type?: 'number';
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

export type NumberFieldStyleProps = {
  disabled: boolean;
  margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="TextField style definition ...">
const useStyles = createUseStyles<RuleNames, NumberFieldStyleProps, Theme>(
  (theme) => {
    return {
      numberFieldRoot: {
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 0
            }
            case 'dense': {
              return `${theme.spacing(2)}px 0px`
            }
            default: {
              return `${theme.spacing(4)}px 0px`
            }
          }
        }
      },
      numberFieldInput: {
        boxShadow: [ 0, 3, 2, "#E9ECEF0D" ],
        border: [ 1, "solid", "#DEE2E6" ],
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
      },
      numberFieldLabel: {
        color: "#596675"
      }
    }
  }
);
// </editor-fold>

const NumberField = (props: NumberFieldProps) => {
  const {
    id,
    name,
    label,
    value,
    placeholder,
    margin = 'default',
    autoComplete,
    type = 'number',
    disabled = false,
    handleChange = () => console.debug("field value changed"),
    required = false,
    min,
    max,
    step
  } = props
  const { numberFieldRoot, numberFieldLabel, numberFieldInput } = useStyles({disabled, margin})
  return (
    <div className={numberFieldRoot}>
      <label className={numberFieldLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
      <div style={{width: "100%", marginTop: 4}}>
        <input 
          className={numberFieldInput} 
          style={{width: "100%", boxSizing: "border-box"}} 
          type={type} 
          id={id} 
          name={name} 
          value={value} 
          placeholder={placeholder} 
          onChange={handleChange} 
          autoComplete={autoComplete} 
          min={min} max={max} 
          required={required}
          step={step}/>
      </div>
    </div>
  );
};

export default NumberField;

