import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardText type definitions ...">
type RuleNames = "cardTextRoot";

export interface CardTextProps {
  children: React.ReactNode
}

export type CardTextStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardText style definition ...">
const useStyles = createUseStyles<RuleNames, CardTextStyleProps, Theme>(
  (theme) => {
    return {
      cardTextRoot: {
        ...theme.typography.p
      },
    };
  }
);
// </editor-fold>

const CardText = (props: CardTextProps) => {
  const { children } = props
  const { cardTextRoot } = useStyles()
  return (
    <p className={cardTextRoot}>
      {children}
    </p>
  );
};

export default CardText;
