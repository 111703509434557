import { getTokenSuccess, handleLogout } from "../store/user/actions";

export const getApiBaseUrl = (): string => {
  switch (process.env?.REACT_APP_API || process.env?.REACT_APP_ENV) {
    case 'local': {
      return 'http://localhost:8000/api';
    }
    default:
      return 'https://app.wtc-turku.fi/api';
  }
};

export const getLimitedTextString = (
  textString: string,
  limit: number,
): string => {
  if (textString.length > limit) {
    return textString.substring(0, limit - 3) + '...';
  } else {
    return textString;
  }
};

export const login = (username: string, password: string) => {
  return fetch(`${getApiBaseUrl()}/token/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      {
        "username": username,
        "password": password,
      }
    )
  })
}

export const refreshTokenFetch = async (...args: any) => {
  const dispatch = args[2];
  let fetchResult = await fetch(args[0], args[1])
  const refreshToken = localStorage.getItem('refreshToken')
  if (!fetchResult.ok && fetchResult.status === 401 && fetchResult.statusText === "Unauthorized") {
    if (refreshToken) {
      const fetchRefreshTokenResult = await fetch(
        `${getApiBaseUrl()}/token/refresh/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ refresh: refreshToken })
        }
      );
      const refreshTokenResult = await fetchRefreshTokenResult.json()
      if (fetchRefreshTokenResult.ok) {
        if (dispatch) {
          dispatch(getTokenSuccess(refreshTokenResult))
          const init = args[1]
          init['headers']['Authorization'] = `jwt ${refreshTokenResult.access}`
          fetchResult = await fetch(args[0], init)
        }
      }
      else {
        if (dispatch) {
          dispatch(handleLogout())
        }
      }
    }
  }
  return fetchResult
}

export const datesAreOnSameDay = (dDate: string, date: Date) => {
  const cDate = new Date(dDate)
  if (cDate.getFullYear() === date.getFullYear() && cDate.getMonth() === date.getMonth() && cDate.getDate() === date.getDate()) {
    return true
  }
  else {
    return false
  }
}

export function getEnumNames(enums: any[], keys: any) {
  return (enums.filter(i => keys.includes(i.key)))
}


//function to format minutes hours, minutes etc. to contain 2 digits (9 -> 09 etc.)
export function addZeroFormatToString(i: number) {
  if (i < 10) {
    return ("0" + i.toString())
  }
  else {
    return i.toString()
  }
}

export function formatDateForApi(d: Date) {
  return (new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()).toISOString().split('.')[0] + "Z")
}