type Multiplier = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export type Spacing = (multiplier: Multiplier) => number;

const defaultSpacing = 4;

const spacing = (multiplier: Multiplier) => {
  return multiplier * defaultSpacing;
};

export default spacing;
