// <editor-fold desc="Token actionTypes ...">
export const GET_TOKEN_PENDING = "GET_TOKEN_PENDING";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";
export const CLEAR_TOKEN = "CLEAR_TOKEN";
// </editor-fold>
// <editor-fold desc="User actionTypes ...">
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
// </editor-fold>
// <editor-fold desc="Workspace actionTypes ...">
export const SET_WORKSPACE = "SET_WORKSPACE";
// </editor-fold>