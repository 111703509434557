import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardBody type definitions ...">
type RuleNames = "cardBodyRoot";

export interface CardBodyProps {
  children: React.ReactNode
}

export type CardBodyStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardBody style definition ...">
const useStyles = createUseStyles<RuleNames, CardBodyStyleProps, Theme>(
  (theme) => {
    return {
      cardBodyRoot: {
        padding: theme.spacing(4)
      },
    };
  }
);
// </editor-fold>

const CardBody = (props: CardBodyProps) => {
  const { children } = props
  const { cardBodyRoot } = useStyles()
  return (
    <div className={cardBodyRoot}>
      {children}
    </div>
  );
};

export default CardBody;
