import React, { Fragment, useEffect, useState } from "react";
import HeaderMenu from "./components/HeaderMenu";
import ViewsRouter from "./views/ViewsRouter";
import { handleGetMyUser } from "./store/user/actions";
import { useAppDispatch, useAppSelector } from "./hooks";
import PageTitle from "./components/PageTitle";
import { getApiBaseUrl } from "./utils/functions";
import FrontPageFooter from "./components/FrontPageFooter";
import { WelcomeModal } from "./components/WelcomeModal";

export interface Enums {
  tags: Array<Enum>;
  themes: Array<Enum>;
  types: Array<Enum>;
  regions: Array<Enum>;
}

export interface Enum {
  key: number;
  value: string;
}

export interface FilterStates {
  tags: string;
  themes: string;
  types: string;
  regions: string;
}

function App() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const dispatch = useAppDispatch();
  const loadingUserData = useAppSelector(
    (state) => state.user.userData.loading
  );
  const [welcomeModalState, setWelcomeModalState] = useState<boolean>(false);
  const [searchBarSearchActive, setSearchBarSearchActive] =
    useState<boolean>(false);
  const [searchBarState, setSearchbarState] = useState<string>("");
  const [filterStates, setFilterStates] = useState<FilterStates>({
    tags: "",
    themes: "",
    types: "",
    regions: "",
  });
  const [enums, setEnums] = useState<Enums>({
    tags: [],
    themes: [],
    types: [],
    regions: [],
  });

  useEffect(() => {
    console.debug("accessToken", accessToken);
    if (accessToken) {
      dispatch(handleGetMyUser(accessToken));
    }
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("isVisited") ||
      localStorage.getItem("isVisited") === "false"
    ) {
      setWelcomeModalState(true);
    } else setWelcomeModalState(false);
  });

  // Get tags, themes, regions and types from api
  useEffect(() => {
    fetch(`${getApiBaseUrl()}/enums/`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            setEnums({
              tags: data.organizations.organization_service_card_tag,
              types: data.organizations.organization_service_card_type,
              themes: data.organizations.organization_service_card_theme,
              regions: data.organizations.organization_service_card_region,
            });
          })
          .catch((error) => console.error("error"));
      })
      .catch((error) => console.error("error"));
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {loadingUserData ? (
        <div style={{ margin: "auto" }}>
          <PageTitle component="h2">
            {`Ladataan käyttäjän tietoja...`}
          </PageTitle>
        </div>
      ) : (
        <Fragment>
          <HeaderMenu
            searchBarState={searchBarState}
            setSearchBarState={setSearchbarState}
            filterStates={filterStates}
            setFilterStates={setFilterStates}
            searchBarSearchActive={searchBarSearchActive}
            setSearchBarSearchActive={setSearchBarSearchActive}
            enums={enums}
          />
          <ViewsRouter
            searchBarState={searchBarState}
            searchBarSearchActive={searchBarSearchActive}
            filterStates={filterStates}
            enums={enums}
          />
          <FrontPageFooter />
        </Fragment>
      )}
      {welcomeModalState ? <WelcomeModal></WelcomeModal> : null}
    </div>
  );
}

export default App;
