import React from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";

// <editor-fold desc="TextField type definitions ...">
type RuleNames = 'checkBoxRoot' | 'checkBoxLabel' | 'checkBoxInput' | 'externalLink'

export interface checkBoxProps {
    id: string;
    name: string;
    label?: string;
    value: boolean;
    margin?: 'default' | 'dense' | 'none';
    type?: 'number';
    disabled?: boolean;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    url?: string
    urlText?: string
}

export type checkBoxStyleProps = {
    disabled: boolean;
    margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="TextField style definition ...">
const useStyles = createUseStyles<RuleNames, checkBoxStyleProps, Theme>(
    (theme) => {
        return {
            checkBoxRoot: {
                margin: ({ margin }) => {
                    switch (margin) {
                        case 'none': {
                            return 0
                        }
                        case 'dense': {
                            return `${theme.spacing(2)}px 0px`
                        }
                        default: {
                            return `${theme.spacing(4)}px 0px`
                        }
                    }
                }
            },
            checkBoxInput: {
                boxShadow: [0, 3, 2, "#E9ECEF0D"],
                border: [1, "solid", "#DEE2E6"],
                borderRadius: theme.spacing(1),
                padding: theme.spacing(2),
            },
            checkBoxLabel: {
                ...theme.typography.p,
                color: "#596675"
            },
            externalLink: {
                ...theme.typography.p,
            }
        }
    }
);
// </editor-fold>

const CheckBox = (props: checkBoxProps) => {
    const {
        id,
        name,
        label,
        value,
        margin = 'default',
        disabled = false,
        handleChange = () => console.debug("field value changed"),
        required = false,
        url,
        urlText
    } = props
    const { checkBoxRoot, checkBoxLabel, checkBoxInput, externalLink } = useStyles({ disabled, margin })
    return (
        <div className={checkBoxRoot}>
            {label != undefined ? 
                <label className={checkBoxLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
            : null
            }
            {url != undefined ?
                <a className={externalLink} href={url}>{urlText}</a>
            :null
            }
            <div style={{ width: "100%", marginTop: 4 }}>
                <input
                    className={checkBoxInput}
                    style={{ boxSizing: "border-box" }}
                    type={"checkbox"}
                    id={id}
                    name={name}
                    checked={value}
                    onChange={handleChange}
                    required={required}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default CheckBox;

