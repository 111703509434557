import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="Card type definitions ...">
type RuleNames = "cardRoot" | "disabledCardOverlay";

export interface CardProps {
  children: React.ReactNode;
  active?: boolean;
  handleClick?: () => void;
}

export type CardStyleProps = {
  allowClick: boolean;
};
// </editor-fold>

// <editor-fold desc="Card style definition ...">
const useStyles = createUseStyles<RuleNames, CardStyleProps, Theme>(
  (theme) => {
    return {
      cardRoot: {
        boxShadow: "0px 3px 6px #00000029",
        zIndex: 1000,
        position: 'relative',
        minHeight: "inherit",
        cursor: ({allowClick}) => allowClick ? 'pointer' : 'default'
      },
      disabledCardOverlay: {
        zIndex: 2500, 
        backgroundColor: "grey", 
        position: "absolute",
        opacity: "0.5",
        height: "100%",
        width: "100%"
    }
  }}
);
// </editor-fold>

const Card = (props: CardProps) => {
  const { children, handleClick = undefined } = props
  const { cardRoot, disabledCardOverlay } = useStyles({allowClick: !!handleClick})
  return (
    <div className={cardRoot} onClick={handleClick}>
      {props.active ? null && false
                : <div className={disabledCardOverlay}></div>}
      {children}
    </div>
  );
};

export default Card;
