import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardTitle type definitions ...">
type RuleNames = "modalTitleRoot";

export interface ModalTitleProps {
  children: React.ReactNode
}

export type ModalTitleStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardTitle style definition ...">
const useStyles = createUseStyles<RuleNames, ModalTitleStyleProps, Theme>(
  (theme) => {
    return {
      modalTitleRoot: {
        ...theme.typography.h4,
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(4)
      },
    };
  }
);
// </editor-fold>

const ModalTitle = (props: ModalTitleProps) => {
  const { children } = props
  const { modalTitleRoot } = useStyles()
  return (
    <h4 className={modalTitleRoot}>
      {children}
    </h4>
  );
};

export default ModalTitle;