import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../styles/theme";

// <editor-fold desc="Paper type definitions ...">
type RuleNames = "dividerRoot";

export interface DividerProps {
}

export type DividerStyleProps = {
};
// </editor-fold>

// <editor-fold desc="Divider style definition ...">
const useStyles = createUseStyles<RuleNames, DividerStyleProps, Theme>(
  (theme) => {
    return {
      dividerRoot: {
        height: 1,
        margin: 0,
        border: 0,
        backgroundColor: "#0000000D"
      },
    };
  }
);
// </editor-fold>

const Divider = (props: DividerProps) => {
  const { dividerRoot } = useStyles();
  return(
    <hr className={dividerRoot} />
  );
};

export default Divider;
