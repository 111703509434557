import React, { useEffect, useState } from "react";
import { OrganizationCard } from "../../components/Cards"
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { getApiBaseUrl } from "../../utils/functions";
import PageTitle from "../../components/PageTitle";
import CardModal from "../../components/CardModal/CardModal";
import { Enums, FilterStates } from "../../App";
import { Search } from "@mui/icons-material";
import FrontPageFooter from "../../components/FrontPageFooter";

// <editor-fold desc="Card type definitions ...">
type RuleNames = "cardContainer" | "cardListContainer";

export interface HomeProps {
  searchBarState: string;
  searchBarSearchActive: boolean;
  filterStates: FilterStates;
  enums: Enums;
}

export interface ModalProps {
  organizationCard: OrganizationCardObject
}

export type HomeStyleProps = {};

export interface OrganizationCardObject {
  id: number;
  title: string;
  description: string;
  image: string;
  active: boolean;
  organization: {
    id: number;
    name?: string;
    logo?: string;
    image?: string;
  };
  tags: number[];
  themes: number[];
  types: number[];
  regions: number[];
  enums: Enums
}

// </editor-fold>

// <editor-fold desc="Card style definition ...">
const useStyles = createUseStyles<RuleNames, HomeStyleProps, Theme>(
  (theme) => {
    return {
      cardContainer: {
        width: "100%",
        minHeight: "475px",
        boxSizing: "border-box",
        padding: theme.spacing(2),
        position: "relative",
        [theme.breakpoints.up('tablet')]: {
          width: "45%",
          padding: theme.spacing(2),
          minHeight: "490px"
        },
        [theme.breakpoints.up('desktop')]: {
          width: "33.333%",
          padding: theme.spacing(2),
          minHeight: "450px"
        },
      },
      cardListContainer: {
        display: "flex", 
        flexWrap: "wrap",
        width: "100%"
      }
    };
  }
);
// </editor-fold>


const Home = (props: HomeProps) => {
  const {
    searchBarState,
    searchBarSearchActive,
    filterStates,
    enums
  } = props
  const [modalState, setModalState] = useState<OrganizationCardObject>()
  const [organizationCards, setOrganizationCards] = useState<OrganizationCardObject[]>([])
  const [loading, setLoading] = useState(true)
  const { cardContainer, cardListContainer } = useStyles()

  const openModal = (card: OrganizationCardObject) => {
    setModalState(card)
  }

  useEffect(() => {
    document.title = "WTC Palvelukortit";
    fetch(`${getApiBaseUrl()}/public_organization_service_cards/`)
      .then((response) => {
        response
          .json()
          .then((data) => setOrganizationCards(data))
          .catch((error) => console.error("error"));
      })
      .catch((error) => console.error("error"))
      .finally(() => setLoading(false));
  }, [])
  if (loading) return (
    <div style={{ margin: "auto" }}>
      <PageTitle component="h2">
        {`Ladataan palvelukortteja...`}
      </PageTitle>
    </div>
  )

  // Function for filtering cards by description or title
  function filterCardsByText(card: OrganizationCardObject) {
    if (props.searchBarSearchActive) {
      return card.description?.toLowerCase().includes(props.searchBarState.toLowerCase()) || card.title?.toLowerCase().includes(props.searchBarState.toLowerCase())
    }
  }
  // function for filtering cards by tags
  function filterCardsByTags(card: OrganizationCardObject) {
    if (props.filterStates.tags != "") {
      return card.tags.includes(parseInt(props.filterStates.tags))
    }
  }
  // function for filtering cards by themes
  function filterCardsByThemes(card: OrganizationCardObject) {
    if (props.filterStates.themes != "") {
      return card.themes.includes(parseInt(props.filterStates.themes))
    }
  }
  // function for filtering cards by types
  function filterCardsByTypes(card: OrganizationCardObject) {
    if (props.filterStates.types != "") {
      return card.types.includes(parseInt(props.filterStates.types))
    }
  }
  // function for filtering cards by regions
  function filterCardsByRegions(card: OrganizationCardObject) {
    if (props.filterStates.regions != "") {
      return card.regions.includes(parseInt(props.filterStates.regions))
    }
  }
  // function to filter cards based on activity value
  function filterByActivity(card: OrganizationCardObject) {
    if (card.active === false) {
      return false
    }
    else{
      return true
    }
  }

  // Function for rendering card list
  const renderOrganizationCardsList = () => {
    if (searchBarSearchActive) {
      const cardsToRender = organizationCards.filter(c => {
       return filterByActivity(c)
      }
      ).filter(c => {
        if (searchBarState.length > 3) {
          return filterCardsByText(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.tags != "") {
          return filterCardsByTags(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.themes != "") {
          return filterCardsByThemes(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.types != "") {
          return filterCardsByTypes(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.regions != "") {
          return filterCardsByRegions(c)
        }
        else {
          return c
        }
      }
      ).map(organizationCard => {
        const {
          id,
          title,
          description,
          active,
          image: cardImageUrl,
          organization: {
            name,
            logo: organizationLogoUrl,
            image: organizationImageUrl
          },
          tags,
          themes,
          types,
          regions,
          enums = props.enums
        } = organizationCard;
        return (
          <div className={cardContainer} key={id}>
            <OrganizationCard
              id={id}
              title={title || ""}
              description={description || ""}
              active={active}
              imageUrl={cardImageUrl || organizationImageUrl || ""}
              logoUrl={organizationLogoUrl || ""}
              organizationName={name || ""}
              handleClick={() => openModal(organizationCard)}
              tags={tags}
              themes={themes}
              types={types}
              regions={regions}
              enums={enums}
            />
          </div>
        )
      })

      return (
        <div style={{width: "inherit"}}>
          <div>
            <PageTitle component="h4">
              {cardsToRender.length === 1 ? "Löytyi 1 palvelukortti" : `Löytyi yhteensä ${cardsToRender?.length || 0} palvelukorttia`}
            </PageTitle>
          </div>
          <div className = {cardListContainer}>
            {cardsToRender}
          </div>
          {modalState
            ? <div><CardModal organizationCard={modalState} setModalState={setModalState} enums={props.enums}></CardModal></div>
            : null
          }
        </div>
      )
    }
    else {
      const cardsToRender = organizationCards.filter(c => {
        return filterByActivity(c)
       }
       ).filter(c => {
        if (filterStates.tags != "") {
          return filterCardsByTags(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.themes != "") {
          return filterCardsByThemes(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.types != "") {
          return filterCardsByTypes(c)
        }
        else {
          return c
        }
      }
      ).filter(c => {
        if (filterStates.regions != "") {
          return filterCardsByRegions(c)
        }
        else {
          return c
        }
      }
      ).map(organizationCard => {
        const {
          id,
          title,
          description,
          image: cardImageUrl,
          active,
          organization: {
            name,
            logo: organizationLogoUrl,
            image: organizationImageUrl
          },
          tags,
          themes,
          types,
          regions,
          enums = props.enums
        } = organizationCard
        return (
          <div className={cardContainer} key={id}>
            <OrganizationCard
              id={id}
              title={title || ""}
              description={description || ""}
              imageUrl={cardImageUrl || organizationImageUrl || ""}
              logoUrl={organizationLogoUrl || ""}
              organizationName={name || ""}
              handleClick={() => openModal(organizationCard)}
              tags={tags}
              themes={themes}
              types={types}
              regions={regions}
              enums={enums}
              active={active}
            />
          </div>
        )
      })

      return (
        <div style={{ flex: 1 }}>
          <div>
            <PageTitle component="h4">
              {cardsToRender.length === 1 ? "Löytyi 1 palvelukortti" : `Löytyi yhteensä ${cardsToRender?.length || 0} palvelukorttia`}
            </PageTitle>
          </div>
          <div className = {cardListContainer}>
            {cardsToRender}
          </div>
          {modalState
            ? <div><CardModal organizationCard={modalState} setModalState={setModalState} enums={props.enums}></CardModal></div>
            : null
          }
        </div>
      )
    }
  }

  return (
    renderOrganizationCardsList()
  );
};

export default Home;
