import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getApiBaseUrl, refreshTokenFetch } from "../../utils/functions";
import Alert from "../../components/Alert";
import { OrganizationCard } from "../../components/Cards";
import Button from "../../components/Buttons";
import TextField from "../../components/Inputs/TextField";
import TextAreaField from "../../components/Inputs/TextAreaField";
import DateField from "../../components/Inputs/DateField";
import SelectField, { Option } from "../../components/Inputs/SelectField";
import Paper from "../../components/Paper";
import ImageInput from "../../components/Inputs/ImageInput";
import Divider from "../../components/Divider";
import { diff } from "deep-diff";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { ConstructionOutlined } from "@mui/icons-material";
import { Enums } from "../../App";
import NumberField from "../../components/Inputs/NumberField";
import { CalendarModal } from "../../components/CalendarModal";
import { NewOrganizationServiceCard } from "../NewServiceCard";
import { NewContactTime } from "../../utils/interfaces";
import { useSnackbar } from "notistack";

// <editor-fold desc="EditServiceCard type definitions ...">
type RuleNames = "editServiceCardLoadingRoot"
  | "editServiceCardErrorRoot"
  | "editServiceCardRoot"
  | "headerContainer"
  | "dividerContainer"
  | "editServiceCardActionsContainer"
  | "basicInformationContainer"
  | "otherInformationContainer"
  | "previewContainer"
  | "organizationCardContainer";

export interface SelectedOrganizationServiceCard {
  id?: number;
  title?: string;
  contact_email?: string;
  contact_time?: string[];
  description?: string;
  active?: boolean | undefined;
  image?: string;
  tags?: string[];
  themes?: string[];
  types?: string[];
  regions?: string[];
  organization?: {
    name?: string;
    logo?: string;
    image?: string;
  }
}

export interface EditServiceCardProps {
  enums: Enums
}
export type EditServiceCardStyleProps = {
};
// </editor-fold>

// <editor-fold desc="EditServiceCard style definition ...">
const useStyles = createUseStyles<RuleNames, EditServiceCardStyleProps, Theme>(
  (theme) => {
    return {
      editServiceCardLoadingRoot: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      },
      editServiceCardErrorRoot: {
        margin: "auto",
      },
      editServiceCardRoot: {
        display: 'grid',
        width: '100%',
        gridTemplateRows: "auto",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(8),
        gridTemplateAreas: `
          "header header header"
          "divider divider divider"
          "basic basic basic"
          "other other other"
          "preview preview preview"
        `,
        [theme.breakpoints.up('tablet')]: {
          columnGap: theme.spacing(6),
          rowGap: theme.spacing(6),
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateAreas: `
            "header header header header"
            "divider divider divider divider"
            "basic basic other other"
            "preview preview preview preview"
        `,
        },
        [theme.breakpoints.up('desktop')]: {
          columnGap: theme.spacing(8),
          rowGap: theme.spacing(8),
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateAreas: `
          "header header header"
          "divider divider divider"
          "basic other preview"
        `,
        },
      },
      headerContainer: {
        gridArea: "header",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        padding: [theme.spacing(4), theme.spacing(4), 0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [theme.spacing(6), theme.spacing(6), 0, theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [theme.spacing(8), theme.spacing(8), 0, theme.spacing(8)],
        },
      },
      editServiceCardActionsContainer: {
        display: 'grid',
        gridTemplateRows: "auto",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `
          "checkbox cancel" 
          "save delete" 
          "back back"
        `,
        margin: "auto",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(4),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
          gridTemplateAreas: `
          "checkbox cancel save delete back"
        `,
          columnGap: theme.spacing(6),
        },
        [theme.breakpoints.up('desktop')]: {
          columnGap: theme.spacing(8),
        },
      },
      dividerContainer: {
        gridArea: "divider"
      },
      basicInformationContainer: {
        gridArea: "basic",
        padding: [0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [0, 0, 0, theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [0, 0, theme.spacing(8), theme.spacing(8)],
        },
      },
      otherInformationContainer: {
        gridArea: "other",
        padding: [0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [0, theme.spacing(6), 0, 0],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [0, 0, theme.spacing(8), 0],
        },
      },
      previewContainer: {
        gridArea: "preview",
        padding: [0, theme.spacing(4), 0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          maxWidth: "100%",
          margin: 0,
          padding: [0, theme.spacing(6), theme.spacing(6), theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          maxWidth: "100%",
          margin: 0,
          padding: [0, theme.spacing(8), theme.spacing(8), 0],
        },
      },
      organizationCardContainer: {
        width: "100%",
        minHeight: "450px",
        boxSizing: "border-box",
        position: "relative"
      }
    };
  }
);
// </editor-fold>

const EditServiceCard = (props: EditServiceCardProps) => {
  const {
    editServiceCardLoadingRoot,
    editServiceCardErrorRoot,
    editServiceCardRoot,
    headerContainer,
    dividerContainer,
    editServiceCardActionsContainer,
    basicInformationContainer,
    otherInformationContainer,
    previewContainer,
    organizationCardContainer
  } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [modalState, setModalState] = useState<SelectedOrganizationServiceCard>()
  const [dialogOpen, setDialogOpen] = useState(false);
  const { serviceCardId, calendarOpen } = useParams();
  const accessToken = useAppSelector((state) => state.user.token.data.access);
  const selectedWorkspace = useAppSelector((state) => state.user.workspace);
  const myRoles = useAppSelector((state) => state.user.userData.data.organization_user_roles);
  const myWorkspaceOrganization = myRoles.find((organizationUserRole: any) => organizationUserRole?.organization?.id === selectedWorkspace)?.organization
  const [updatingServiceCardData, setUpdatingServiceCardData] = useState(false)
  const [tags, setTags] = useState<string[] | undefined>(undefined)
  const [themes, setThemes] = useState<string[] | undefined>(undefined)
  const [types, setTypes] = useState<string[] | undefined>(undefined)
  const [regions, setRegions] = useState<string[] | undefined>(undefined)

  //Default values when loading page
  const [newContactTimes, setNewContactTimes] = useState<NewContactTime[]>([])
  const [defaultValues, setDefaultValues] = useState<SelectedOrganizationServiceCard | undefined>(undefined)
  const [defaultTags, setDefaultTags] = useState<string[] | undefined>(undefined)
  const [defaultThemes, setDefaultThemes] = useState<string[] | undefined>(undefined)
  const [defaultTypes, setDefaultTypes] = useState<string[] | undefined>(undefined)
  const [defaultRegions, setDefaultRegions] = useState<string[] | undefined>(undefined)

  const [selectedOrganizationServiceCard, setSelectedOrganizationServiceCard] = useState<{
    loading: boolean;
    data?: SelectedOrganizationServiceCard;
    error: string;
  }>({
    loading: true,
    data: undefined,
    error: ""
  })
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const element = "edit-service-card"
  const basicInformation = `${element}-basic-information`
  const otherInformation = `${element}-other-information`
  const preview = `${element}-preview`

  const changedValues = diff(selectedOrganizationServiceCard.data, defaultValues)
  const changedTagValues = diff([tags, themes, types, regions], [defaultTags, defaultThemes, defaultTypes, defaultRegions])
  const tagsMissing = !tags || tags.length === 0
  const themesMissing = !themes || themes.length === 0
  const typesMissing = !types || types.length === 0
  const regionsMissing = !regions || regions.length === 0
  const contactTimesMissing = !newContactTimes || newContactTimes.length === 0
  const tooManySelected = (!tags || tags.length > 4) || (!themes || themes.length > 4) || (!types || types.length > 4) || (!regions || regions.length > 4)
  const requiredFieldMissing = !selectedOrganizationServiceCard?.data?.title || !selectedOrganizationServiceCard?.data?.description || !selectedOrganizationServiceCard?.data?.contact_email || tagsMissing || themesMissing || typesMissing || regionsMissing
  const saveDisabled = (!changedValues && !selectedFile && !changedTagValues) || requiredFieldMissing || tooManySelected

  const openModal = (data: SelectedOrganizationServiceCard) => {
    setModalState(data)
  }

  const handleOpenConfirmDialog = () => {
    setDialogOpen(true);
  };
  const handleDeleteServiceCardCancel = () => {
    setDialogOpen(false);
  };

  const handleGetMyOrganizationServiceCard = async () => {

    setSelectedOrganizationServiceCard(s => ({ ...s, loading: true, error: "" }))
    const fetchMyOrganizationServiceCardResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization_service_cards/${serviceCardId}/?organization=${selectedWorkspace}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `jwt ${accessToken}`,
        },
      },
      dispatch
    )
    /* wait for the myOrganizationServiceCard response */
    const myOrganizationServiceCardResult = await fetchMyOrganizationServiceCardResult.json()
    /* Successful request on myOrganizationServiceCard data */
    if (fetchMyOrganizationServiceCardResult.ok) {

      // Get url parameters to check whether user just created the service card and wanted to add contact times
      const query = new URLSearchParams(window.location.search)
      const openCalendarOnLoad = Boolean(query.get('calendarOpen'))

      // Set default values for comparing changes
      setDefaultValues(myOrganizationServiceCardResult);
      setDefaultTags(myOrganizationServiceCardResult.tags.map(String))
      setDefaultThemes(myOrganizationServiceCardResult.themes.map(String))
      setDefaultTypes(myOrganizationServiceCardResult.types.map(String))
      setDefaultRegions(myOrganizationServiceCardResult.regions.map(String))

      setSelectedOrganizationServiceCard(s => ({ ...s, loading: false, data: myOrganizationServiceCardResult }));
      setTags(myOrganizationServiceCardResult.tags.map(String))
      setThemes(myOrganizationServiceCardResult.themes.map(String))
      setTypes(myOrganizationServiceCardResult.types.map(String))
      setRegions(myOrganizationServiceCardResult.regions.map(String))

      // If url parameter is true, open modal on page load (case when user wants to add contact times on card creation)
      if (openCalendarOnLoad) {
        setModalState(myOrganizationServiceCardResult)
      }
    }
    /* Requesting myOrganizationServiceCard data has failed */
    else {
      console.debug("test", myOrganizationServiceCardResult)
      let message = "Tuntematon virhe";
      /* Missing required organization parameter from request */
      if (Array.isArray(myOrganizationServiceCardResult) && myOrganizationServiceCardResult.includes("Missing required organization parameter")) {
        message = "Puuttuva parametri organization"
      }
      /* Invalid OrganizationUserRole for the requested organization */
      else if (Array.isArray(myOrganizationServiceCardResult) && myOrganizationServiceCardResult.includes("User role not exists in requested organization")) {
        message = "Organisaatiossa ei ole voimassaolevaa organisaatioroolia"
      }
      /* Object not found*/
      else if (myOrganizationServiceCardResult?.detail === "Not found.") {
        message = "Palvelukorttia ei löydy"
      }
      setSelectedOrganizationServiceCard(s => ({ ...s, loading: false, error: message }))
    }

  }

  const handleCreateNewContactTimes = async () => {
    const createNewContactTimesResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/events/`,
      {
        method: 'POST',
        headers: {
          'Authorization': `jwt ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newContactTimes)
      },
      dispatch
    )
    // Wait for response
    const newContactTimesResult = await createNewContactTimesResult.json()
    // If success
    if (createNewContactTimesResult.status === 201){
      enqueueSnackbar("Kontaktiajat lisätty onnistuneesti", {
        preventDuplicate: true,
        variant: "success"
      });
      console.debug("creation", newContactTimesResult)
      setNewContactTimes([])
    }
    // If failed
    else{
      enqueueSnackbar("Kontaktiaikojen lisäys epäonnistui", {
        preventDuplicate: true,
        variant: "error"
      });
      console.debug("creation", "error")
    }
  }

  const handleUpdateServiceCard = async () => {

    setUpdatingServiceCardData(true)
    let serviceCardDataToBeUpdated: any = {};
    if (changedValues) {
      changedValues.map(changedValue => {
        const { path: [propertyName], lhs: updatedValue }: any = changedValue
        serviceCardDataToBeUpdated[propertyName] = updatedValue
      })
    }

    const { image, ...rest } = serviceCardDataToBeUpdated

    const formData = new FormData();

    if (selectedFile) {
      formData.append(
        "image",
        selectedFile,
        selectedFile.name
      )
    }

    // update tags, themes etc. to formData object
    if (tags != undefined) {
      for (var i = 0; i < tags.length; i++) {
        formData.append('tags', tags[i])
      }
    }

    if (themes != undefined) {
      for (var i = 0; i < themes.length; i++) {
        formData.append('themes', themes[i])
      }
    }

    if (types != undefined) {
      for (var i = 0; i < types.length; i++) {
        formData.append('types', types[i])
      }
    }

    if (regions != undefined) {
      for (var i = 0; i < regions.length; i++) {
        formData.append('regions', regions[i])
      }
    }
    Object.keys(rest).forEach(key => formData.append(key, rest[key]))

    const updateServiceCardResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization_service_cards/${serviceCardId}/?organization=${selectedWorkspace}`,
      {
        method: 'PATCH',
        headers: {
          'Authorization': `jwt ${accessToken}`
        },
        body: formData
      },
      dispatch
    )
    /*wait for the updateServiceCard response*/
    const serviceCardResult = await updateServiceCardResult.json()
    /* Successful request on updateServiceCard data */
    if (updateServiceCardResult.ok) {
      setUpdatingServiceCardData(true)
      setDefaultValues(serviceCardResult)
      setDefaultTags(serviceCardResult.tags.map(String))
      setDefaultThemes(serviceCardResult.themes.map(String))
      setDefaultTypes(serviceCardResult.types.map(String))
      setDefaultRegions(serviceCardResult.regions.map(String))
      setSelectedOrganizationServiceCard(s => ({ ...s, loading: false, data: serviceCardResult }))
      console.debug("update", serviceCardResult)
      enqueueSnackbar("Palvelukortti päivitetty", {
        preventDuplicate: true,
        variant: "success"
      });
    }
    /* Requesting serviceCard data has failed */
    else {
      setUpdatingServiceCardData(true)
      console.debug("update", "error")
      enqueueSnackbar("Palvelukortin päivitys epäonnistui", {
        preventDuplicate: true,
        variant: "error"
      });
    }
  }

  const handleDeleteServiceCard = async () => {
    setDialogOpen(false)
    const deleteServiceCardResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization_service_cards/${serviceCardId}/?organization=${selectedWorkspace}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `jwt ${accessToken}`
        }
      },
      dispatch
    )

    if (deleteServiceCardResult.status === 204) {
      navigate("/my_organization/service_card_list", { replace: true })
      enqueueSnackbar("Palvelukortti poistettu onnistuneesti", {
        preventDuplicate: true,
        variant: "error"
      });
    }
    else {
      console.debug("delete", "error")
      enqueueSnackbar("Kortin poisto epäonnistui", {
        preventDuplicate: true,
        variant: "error"
      });
    }
  }

  useEffect(() => {
    if (selectedWorkspace && serviceCardId) {
      handleGetMyOrganizationServiceCard().catch(e => {
        setSelectedOrganizationServiceCard(s => ({ ...s, loading: false, error: "Palveluun ei juuri nyt saada yhteyttä, yritä myöhemmin uudelleen" }))
      })
    }
  }, [selectedWorkspace, serviceCardId])

  useEffect(() => {
    document.title = "WTC EditServiceCard";
  }, [])

  if (selectedOrganizationServiceCard.loading) {
    return (
      <div>
        <Paper>
          <div className={editServiceCardLoadingRoot}>
            <PageTitle component="h2">
              {`Ladataan ${myWorkspaceOrganization?.name || "organisaation"} palvelukortin tietoja...`}
            </PageTitle>
          </div>
        </Paper>
      </div>
    )
  }

  if (selectedOrganizationServiceCard?.error) {
    return (
      <div className={editServiceCardErrorRoot}>
        <Alert title="Virhe" message={selectedOrganizationServiceCard.error} />
      </div>
    )
  }

  return (

    <Paper>
      <div className={editServiceCardRoot}>
        <div id={`${basicInformation}-header`} className={headerContainer}>
          <div style={{ flexGrow: 1 }}>
            <PageTitle component="h4" gutterBottom>
              Palvelukortin muokkaus
            </PageTitle>
          </div>
          <div className={editServiceCardActionsContainer}>
            <div style={{ display: 'inline-block', gridArea: "checkbox" }}>
              <input
                type="checkbox"
                id="is-active"
                name="isActive"
                checked={selectedOrganizationServiceCard?.data?.active}
                onChange={(e) => setSelectedOrganizationServiceCard(s => ({ ...s, data: { ...s["data"], active: !s.data?.active } }))}
              />
              <label htmlFor="is-active">Aktiivinen</label>
            </div>
            <div style={{ display: 'inline-block', gridArea: "cancel" }}>
              <Button color="blue" handleClick={() => {
                defaultValues ? setSelectedOrganizationServiceCard({ loading: false, error: "", data: defaultValues }) : console.debug("no default values set")
                setTags(selectedOrganizationServiceCard.data?.tags?.map(String))
                setThemes(selectedOrganizationServiceCard.data?.tags?.map(String))
                setTypes(selectedOrganizationServiceCard.data?.tags?.map(String))
                setRegions(selectedOrganizationServiceCard.data?.tags?.map(String))
                setSelectedFile(undefined)
              }}>
                Peruuta
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "save" }}>
              <Button color="blue" disabled={saveDisabled} handleClick={handleUpdateServiceCard}>
                Tallenna
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "delete" }}>
              <Button color="blue" handleClick={handleOpenConfirmDialog}>
                Poista palvelukortti
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "back" }}>
              <Button color="orange" handleClick={() => { navigate("/my_organization/service_card_list", { replace: true }) }}>
                Takaisin
              </Button>
            </div>
          </div>
        </div>
        <div className={dividerContainer}>
          <Divider />
        </div>
        <div id={`${basicInformation}-container`} className={basicInformationContainer} >
          <PageTitle component="h4" gutterBottom>
            Perustiedot
          </PageTitle>
          <form>
            <TextField
              id={`${basicInformation}-title`}
              name="title"
              label="Kortin otsikko"
              value={selectedOrganizationServiceCard?.data?.title || ""}
              handleChange={(e) => setSelectedOrganizationServiceCard(s => ({ ...s, data: { ...s["data"], title: e.target.value } }))}
              required
              maxLength={90}
              showMaxLength={true}
            />
            <TextField
              id={`${basicInformation}-contact-email`}
              name="contactEmail"
              label="Vastuuhenkilön sähköposti"
              value={selectedOrganizationServiceCard?.data?.contact_email || ""}
              type={"email"}
              handleChange={(e) => setSelectedOrganizationServiceCard(s => ({ ...s, data: { ...s["data"], contact_email: e.target.value } }))}
              required
              alertText="Palvelukortille on määritettävä yhteyshenkilön sähköposti saadaksesi yhteydenottopyyntöjä."
              showAlertText={selectedOrganizationServiceCard?.data?.contact_email === ""}
            />
            <TextAreaField
              id={`${basicInformation}-description`}
              name="description"
              label="Palvelun kuvaus"
              value={selectedOrganizationServiceCard?.data?.description || ""}
              required
              rows={8}
              handleChange={(e) => setSelectedOrganizationServiceCard(s => ({ ...s, data: { ...s["data"], description: e.target.value } }))}
              maxLength={450}
            />
            <Button color="blue" handleClick={() => { setModalState(selectedOrganizationServiceCard.data) }}>Hallitse kontaktiaikoja</Button>
          </form>
        </div>

        <div id={`${otherInformation}-container`} className={otherInformationContainer} >
          <PageTitle component="h4" gutterBottom>
            Muut tiedot
          </PageTitle>
          <SelectField
            id={`${otherInformation}-tags`}
            name="tags"
            label="Tagit"
            value={tags}
            maxSelected={4}
            options={
              props.enums.tags.map((tag: { value: any; key: number; }) => {
                const option: Option = {
                  id: tag.key,
                  name: tag.value,
                  value: tag.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = tags ? [...tags] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setTags([...arrayToBeModified])
              }
            }}
            handleChange={(e) => {
              setTags(s => (s ? [...s, e.target.value] : [e.target.value]))
            }
            }
            notSelectedText="Ei yhtään valittua tagia"
            multiple
            required
          />
          <SelectField
            id={`${otherInformation}-themes`}
            name="themes"
            label="Teemat"
            value={themes}
            maxSelected={4}
            options={
              props.enums.themes.map((theme: { value: any; key: number; }) => {
                const option: Option = {
                  id: theme.key,
                  name: theme.value,
                  value: theme.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = themes ? [...themes] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setThemes([...arrayToBeModified])
              }
            }}
            handleChange={(e) => {
              setThemes(s => (s ? [...s, e.target.value] : [e.target.value]))
            }
            }
            notSelectedText="Ei yhtään valittua teemaa"
            multiple
            required
          />
          <SelectField
            id={`${otherInformation}-types`}
            name="types"
            label="Palvelutyypit"
            value={types}
            maxSelected={4}
            options={
              props.enums.types.map((type: { value: any; key: number; }) => {
                const option: Option = {
                  id: type.key,
                  name: type.value,
                  value: type.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = types ? [...types] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setTypes([...arrayToBeModified])
              }
            }}
            handleChange={(e) => {
              setTypes(s => (s ? [...s, e.target.value] : [e.target.value]))
            }
            }
            notSelectedText="Ei yhtään valittua palvelutyyppiä"
            multiple
            required
          />
          <SelectField
            id={`${otherInformation}-regions`}
            name="regions"
            label="Alue"
            value={regions}
            maxSelected={4}
            options={
              props.enums.regions.map((region: { value: any; key: number; }) => {
                const option: Option = {
                  id: region.key,
                  name: region.value,
                  value: region.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = regions ? [...regions] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setRegions([...arrayToBeModified])
              }
            }}
            handleChange={(e) => {
              setRegions(s => (s ? [...s, e.target.value] : [e.target.value]))
            }
            }
            notSelectedText="Ei yhtään valittua aluetta"
            multiple
            required
          />
          <ImageInput
            id={`${otherInformation}-cover-image`}
            label="Palvelun kuvabanneri"
            selectedFile={selectedFile}
            handleChange={(e) => setSelectedFile(e?.target?.files?.[0] || undefined)}
            resetFile={() => (setSelectedFile(undefined))}
            recommendedSizeText="Suositeltu koko 1920x1080"
            supportedImageFormatsText="Tuetut kuvaformaatit: jpg, jpeg, png"
            required
          />
        </div>

        <div id="edit-service-card-preview-container" className={previewContainer} >
          <PageTitle component="h4" gutterBottom>
            Esikatsele
          </PageTitle>
          <div className={organizationCardContainer}>
            <OrganizationCard
              id={selectedOrganizationServiceCard?.data?.id || 0}
              title={selectedOrganizationServiceCard?.data?.title || "Lorem Ipsum"}
              description={selectedOrganizationServiceCard?.data?.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis semper justo. Pellentesque commodo lorem sit amet nunc varius fermentum. Phasellus venenatis pulvinar vestibulum. Etiam vel urna eu arcu varius consectetur sed eget libero. In posuere justo purus, vitae congue urna convallis sed."}
              imageUrl={selectedFile ? URL.createObjectURL(selectedFile) : (selectedOrganizationServiceCard?.data?.image || selectedOrganizationServiceCard?.data?.organization?.image || "")}
              logoUrl={selectedOrganizationServiceCard?.data?.organization?.logo || ""}
              organizationName={selectedOrganizationServiceCard?.data?.organization?.name || ""}
              tags={selectedOrganizationServiceCard.data?.tags || []}
              themes={selectedOrganizationServiceCard.data?.themes || []}
              types={selectedOrganizationServiceCard.data?.types || []}
              regions={selectedOrganizationServiceCard.data?.regions || []}
              active={selectedOrganizationServiceCard.data?.active || undefined}
              enums={props.enums}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{"Haluatko varmasti poistaa palvelukortin?"}</DialogTitle>
        <DialogActions>
          <Button handleClick={handleDeleteServiceCardCancel} color="blue">
            Peruuta
          </Button>
          <Button handleClick={handleDeleteServiceCard} color="orange">
            Poista kortti
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {modalState
          ? <div>
              <CalendarModal data={modalState} setModalState={setModalState} newContactTimes={newContactTimes} setNewContactTimes={setNewContactTimes} handleCreateNewContactTimes={handleCreateNewContactTimes}></CalendarModal>
            </div>
          : null
        }
      </div>
    </Paper>
  );
};

export default EditServiceCard;