import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../../styles/theme";

// <editor-fold desc="Button type definitions ...">
type RuleNames = "buttonRoot";

export interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  customLayout?: boolean;
  handleClick?: (args: any) => void
  color?: "default" | "green" | "red" | "blue" | "orange"
  type?: "reset" | "submit" | "button"
}

export type ButtonStyleProps = {
  disabled: boolean;
  customLayout: boolean;
  color: "default" | "green" | "red" | "blue" | "orange"
};
// </editor-fold>

// <editor-fold desc="Button style definition ...">
const useStyles = createUseStyles<RuleNames, ButtonStyleProps, Theme>(
  (theme) => {
    return {
      buttonRoot: {
        ...theme.typography.h5,
        padding: ({customLayout}) => {
          return customLayout ? 0 : theme.spacing(2)
        },
        cursor: ({disabled}) => {
          return disabled ? "default" : "pointer"
        },
        width: "100%",
        backgroundColor: ({color, disabled}) => {
          if (disabled) return theme.palette.action.disabledBackground;
          switch (color) {
            case "red": {
              return "#FA0000"
            }
            case "blue": {
              return "#4F89BC"
            }
            case "green": {
              return "#45C915"
            }
            case "orange":{
              return "#F8A25F"
            }
            default: {
              return "##94999F"
            }
          }
        },
        color: ({color, disabled}) => {
          if (disabled) return theme.palette.action.disabled;
          switch (color) {
            case "red": {
              return theme.palette.common.white
            }
            case "blue": {
              return theme.palette.common.white
            }
            case "green": {
              return theme.palette.common.white
            }
            case "orange": {
              return theme.palette.common.white
            }
            default: {
              return theme.palette.common.black
            }
          }
        },
        borderRadius: 6,
        boxShadow: "0px 0px 32px #8898AA26",
        border: "1px solid #DEE2E6",
        '&:hover': {
          backgroundColor: ({color, disabled}) => {
            if (disabled) return theme.palette.action.disabledBackground;
            switch (color) {
              case "red": {
                return "#ae0000"
              }
              case "blue": {
                return "#2f5270"
              }
              case "green": {
                return "#2d840e"
              }
              default: {
                return "#c7c7c7"
              }
            }
          },
        },
      },
    };
  }
);
// </editor-fold>

const Button = (props: ButtonProps) => {
  const {
    children,
    disabled = false,
    customLayout = false,
    handleClick = () => console.debug("button clicked"),
    color = "default",
    type = "button"
  } = props
  const { buttonRoot } = useStyles({disabled, customLayout, color})
  return (
    <button className={buttonRoot} onClick={handleClick} disabled={disabled} type = {type}>
      {children}
    </button>
  );
};

export default Button;
