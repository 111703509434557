import { Dialog, DialogActions, DialogTitle, Grid, Modal } from "@mui/material";
import { ThemeProvider } from "@material-ui/core/styles";
import { display, minWidth } from "@mui/system";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import '../../styles/Calendar.css';
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Enums } from "../../App";
import { Theme } from "../../styles/theme";
import { SelectedOrganizationServiceCard } from "../../views/EditServiceCard";
import { OrganizationCardObject } from "../../views/Home";
import { NewOrganizationServiceCard } from "../../views/NewServiceCard";
import Button from "../Buttons";
import NumberField from "../Inputs/NumberField";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { NewContactTime } from "../../utils/interfaces";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@material-ui/core/styles';
import { addZeroFormatToString, datesAreOnSameDay, formatDateForApi, getApiBaseUrl, refreshTokenFetch } from "../../utils/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useSnackbar } from "notistack";
import { materialTheme } from "../../styles/muiOverrides/overrides";

type RuleNames =
    "calendarModalRoot" |
    "calendarModalContent" |
    "calendarModalGrid" |
    "closeButtonContainer" |
    "calendarModalHeaderContainer" |
    "contactTimeListContainer" |
    "contactTimeSetContainer" |
    "calendarContainer" |
    "calendarRoot" |
    "timePickerContainer" |
    "timePickerRoot" |
    "repeatWeeksContainer" |
    "contactTimeLengthContainer" |
    "timeFieldLabel" |
    "newTimeListItem" |
    "newContactTimeListContainer" |
    "timeIndicatorDot";

export interface CardModalProps {
    data: SelectedOrganizationServiceCard;
    newContactTimes: NewContactTime[];
    setNewContactTimes: Function;
    setModalState: Function;
    handleCreateNewContactTimes: Function;
}

export type CalendarModalStyleProps = {};

const useStyles = createUseStyles<RuleNames, CalendarModalStyleProps, Theme>(
    (theme) => {
        return {
            calendarModalRoot: {
                overflow: "scroll",
                margin: "auto",
                display: "flex",
                paddingTop: "32px",
                paddingBottom: "32px"
            },
            calendarModalContent: {
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.spacing(1),
                width: "95%",
                display: "inline-block",
                margin: "auto",
                padding: "16px",
                height: "fit-content",
                position: "relative",
                [theme.breakpoints.up('tablet')]: {
                    maxWidth: "600px",
                    width: "100%"
                },
                [theme.breakpoints.up('desktop')]: {
                    maxWidth: "900px",
                    width: "100%"
                }
            },
            calendarModalHeaderContainer: {
                display: "flex",
                justifyContent: "center",
                margin: "auto"
            },
            calendarModalGrid: {
                justifyContent: "space-evenly",
                rowGap: "16px"
            },
            calendarContainer: {
                marginBottom: "16px",
                display: "flex",
                justifyContent: "center"
            },
            calendarRoot: {
                border: "1px solid #DEE2E6",
                borderRadius: theme.spacing(1),
                boxShadow: "0px 0px 32px #8898AA26"
            },
            timePickerRoot: {
                border: "1px solid #DEE2E6",
                borderRadius: theme.spacing(1)
            },
            timePickerContainer: {
                marginTop: "16px",
            },
            contactTimeListContainer: {
                border: "1px solid #DEE2E6",
                borderRadius: theme.spacing(1),
                boxShadow: "0px 0px 32px #8898AA26",
                padding: "16px",
                height: "fit-content",
                minWidth: "350px"
            },
            contactTimeSetContainer: {
                border: "1px solid #DEE2E6",
                borderRadius: theme.spacing(1),
                boxShadow: "0px 0px 32px #8898AA26",
                padding: "16px",
                minWidth: "90%",
                height: "fit-content",
                [theme.breakpoints.up('tablet')]: {
                    minWidth: "450px"
                }

            },
            closeButtonContainer: {
                margin: "auto",
                marginTop: "16px",
                width: "200px"
            },
            repeatWeeksContainer: {

            },
            contactTimeLengthContainer: {

            },
            timeFieldLabel: {
                color: "#596675"
            },
            newContactTimeListContainer: {
                overflow: "scroll",
                maxHeight: "300px"
            },
            newTimeListItem: {
                border: "1px solid #DEE2E6",
                borderRadius: theme.spacing(1),
                boxShadow: "0px 0px 16px #8898AA26",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "4px",
                marginBottom: "4px",
                padding: "4px"
            },
            timeIndicatorDot: {
                position: "relative",
                class: "dot",
                height: "6px",
                width: "6px",
                backgroundColor: "#FA0000",
                borderRadius: "50%",
                display: "inline-block",
                bottom: "2.5px",
                left: "2px"
            }
        };
    }
);

const CalendarModal = (props: CardModalProps) => {
    const {
        data,
        newContactTimes,
        setNewContactTimes,
        setModalState,
        handleCreateNewContactTimes
    } = props

    const {
        calendarModalRoot,
        calendarModalContent,
        calendarModalHeaderContainer,
        calendarModalGrid,
        contactTimeListContainer,
        closeButtonContainer,
        timePickerContainer,
        timePickerRoot,
        contactTimeSetContainer,
        calendarContainer,
        calendarRoot,
        repeatWeeksContainer,
        contactTimeLengthContainer,
        timeFieldLabel,
        newTimeListItem,
        newContactTimeListContainer,
        timeIndicatorDot
    } = useStyles();

    const accessToken = useAppSelector((state) => state.user.token.data.access);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const [serviceCardContactTimes, setServiceCardContactTimes] = useState<any[] | undefined>(undefined)
    const [contactTimeDate, setContactTimeDate] = useState<Date>(new Date());
    const [contactTimeStart, setContactTimeStart] = useState<MaterialUiPickersDate>(new Date(0, 0, 0, 10, 0))
    const [contactTimeLength, setContactTimeLength] = useState<string>("1")
    const [repeat_weeks, setRepeatWeeks] = useState<string>("0");
    const [open, setOpen] = React.useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleClose = () => {
        if (newContactTimes.length > 0) {
            handleCreateNewContactTimes()
        }
        setOpen(false)
        setModalState(null)
    };

    const handleDeleteServiceCardCancel = () => {
        setDialogOpen(false);
    };

    const handleSetDialogOpen = () => {
        if (newContactTimes.length > 0) {
            setDialogOpen(true)
        }
        else (
            handleClose()
        )
    }

    const addContactTimeToList = () => {
        const newContactTime: NewContactTime = {
            service_card: data.id,
            start_time: formatDateForApi(
                new Date(contactTimeDate.getFullYear(), contactTimeDate.getMonth(), contactTimeDate.getDate(), contactTimeStart?.getHours(), contactTimeStart?.getMinutes())
            ),
            length: parseFloat(contactTimeLength),
            repeat_weeks: parseInt(repeat_weeks)
        }
        newContactTimes.push(newContactTime)
        //Set form to default values
        setContactTimeDate(new Date())
        setContactTimeStart(new Date(0, 0, 0, 10, 0))
        setContactTimeLength("1")
        setRepeatWeeks("0")
    }

    // Get future contact times
    const handleGetServiceCardContactTimes = async (id: number) => {
        const fetchServiceCardContactTimes = await refreshTokenFetch(
            `${getApiBaseUrl()}/events/?service_card=${id}&start_time__gte=${formatDateForApi(new Date())}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `jwt ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            },
            dispatch
        )
        const serviceCardContactTimesResult = await fetchServiceCardContactTimes.json()
        if (fetchServiceCardContactTimes.ok) {
            setServiceCardContactTimes(serviceCardContactTimesResult)
        }
        else {
            console.log("fetch", "error")
            enqueueSnackbar("Kontaktiaikojen haku epäonnistui", {
                preventDuplicate: true,
                variant: "error"
            });
        }
    }

    const handleDeleteOneContactTime = async (id: number) => {
        const deleteOneContactTimeResult = await refreshTokenFetch(
            `${getApiBaseUrl()}/events/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': `jwt ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            },
            dispatch
        )
        if (deleteOneContactTimeResult.status === 204) {
            enqueueSnackbar("Kontaktiaika poistettu", {
                preventDuplicate: true
            });
            if (data.id) {
                handleGetServiceCardContactTimes(data.id)
            }
        }
        else {
            console.debug("delete", "error")
            enqueueSnackbar("Kontaktiajan poistaminen epäonnistui", {
                preventDuplicate: true,
                variant: "error"
            });
        }
    }

    useEffect(() => {
        if (data.id) {
            handleGetServiceCardContactTimes(data.id)
        }
    }, [])

    return (
        <Modal
            className={calendarModalRoot}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleSetDialogOpen}
            disableAutoFocus={true}
        >
            <div className={calendarModalContent}>
                <div className={calendarModalHeaderContainer}>
                    <h2>Hallinnoi kontaktiaikoja</h2>
                </div>
                <Grid container className={calendarModalGrid}>
                    <Grid item>
                        <div className={contactTimeSetContainer}>
                            <form>
                                <div className={calendarContainer}>
                                    <div>
                                        <Calendar
                                            minDetail="year"
                                            className={calendarRoot}
                                            value={contactTimeDate}
                                            onChange={setContactTimeDate}
                                            minDate={new Date()}
                                            tileContent={({ date, view }) => {
                                                const timeList = serviceCardContactTimes?.map(t => (t.start_time))
                                                if (view === 'month' && timeList != undefined) {
                                                    if (timeList.find(dDate => datesAreOnSameDay(dDate, date))) {
                                                        return (<span className={timeIndicatorDot}></span>)
                                                    }
                                                    else {
                                                        return null
                                                    }
                                                }
                                                else {
                                                    return null
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={timePickerContainer}>
                                    <div>
                                        <label className={timeFieldLabel}>Alkamisaika</label>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                            <KeyboardTimePicker
                                                variant={"inline"}
                                                ampm={false}
                                                value={contactTimeStart}
                                                onChange={setContactTimeStart}
                                                minutesStep={15}
                                                invalidDateMessage={"Syötä aika 15min välein muodossa tt:mm"}
                                                fullWidth={true}
                                            />
                                        </ThemeProvider>
                                    </MuiPickersUtilsProvider>

                                </div>
                                <div className={contactTimeLengthContainer}>
                                    <NumberField
                                        id={"contactTimeLength"}
                                        name={"contact-time-length"}
                                        label={"Kesto (h)"}
                                        value={contactTimeLength}
                                        min={1}
                                        max={12}
                                        step={0.5}
                                        handleChange={(e) => setContactTimeLength(e.target.value)}
                                        placeholder={"kontaktiajan kesto"}
                                    />
                                </div>
                                <div className={repeatWeeksContainer}>
                                    <NumberField
                                        id={"contactTimeWeeks"}
                                        name={"contact-time-weeks-repeat"}
                                        label={"Toistoviikot"}
                                        value={repeat_weeks}
                                        min={0}
                                        max={26}
                                        handleChange={(e) => setRepeatWeeks(e.target.value)}
                                        placeholder={"toistoviikot"}
                                    />
                                </div>
                                <div>
                                    <Button color="blue" handleClick={addContactTimeToList}>
                                        <div style={{ display: "flex", alignItems: "space-evenly" }}>
                                            <div style={{ flex: 1 }}>
                                                Lisää ajat
                                            </div>
                                            <AddIcon fontSize="small"></AddIcon>
                                        </div>
                                    </Button>
                                </div>
                            </form>
                            <div>
                                {newContactTimes.length === 0 ? <p style={{ color: "red" }}>Et ole valinnut lisättäväksi kontaktiaikoja.</p>
                                    : <p>Olet lisäämässä seuraavia kontaktiaikoja:</p>}
                                <div className={newContactTimeListContainer}>
                                    <ul style={{ listStyleType: "none", padding: "0px" }}>
                                        {newContactTimes.map(function (t, idx) {
                                            const time = new Date(t.start_time)
                                            var date = addZeroFormatToString(time.getDate())
                                            var month = addZeroFormatToString(time.getMonth() + 1)
                                            var hours = addZeroFormatToString(time.getHours())
                                            var mins = addZeroFormatToString(time.getMinutes())
                                            return (
                                                <li key={idx}>
                                                    <div className={newTimeListItem}>
                                                        {
                                                            `${date}.${month}.${time.getFullYear()}, ${hours}:${mins}. Kontaktiajan kesto: ${t.length}h, toistoviikot: ${t.repeat_weeks}`
                                                        }
                                                        <DeleteIcon cursor="pointer" fontSize="small" onClick={() => {
                                                            const arrayToBeModified = newContactTimes
                                                            const index = newContactTimes.indexOf(t)
                                                            if (index > -1) {
                                                                arrayToBeModified.splice(index, 1)
                                                                setNewContactTimes([...arrayToBeModified])
                                                            }
                                                            enqueueSnackbar("Lisättävä aika poistettu listasta", {
                                                                preventDuplicate: true
                                                            });
                                                        }}
                                                        >
                                                        </DeleteIcon>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={contactTimeListContainer}>
                            <p><strong>Valitun päivän kontaktiajat</strong></p>
                            {!(serviceCardContactTimes === undefined) ?
                                <ul style={{ listStyleType: "none", padding: "0px" }}>
                                    {
                                        serviceCardContactTimes.filter(
                                            time => {
                                                const t = new Date(time.start_time)
                                                if (t.getFullYear() === contactTimeDate.getFullYear() && t.getMonth() === contactTimeDate.getMonth() && t.getDate() === contactTimeDate.getDate()) {
                                                    return true
                                                }
                                            }).map(function (t: { start_time: string | number | Date; end_time: string | number | Date; id: number }, idx: React.Key | null | undefined) {
                                                const startTime = new Date(t.start_time)
                                                const endTime = new Date(t.end_time)
                                                var startHours = addZeroFormatToString(startTime.getHours())
                                                var startMins = addZeroFormatToString(startTime.getMinutes())
                                                var endHours = addZeroFormatToString(endTime.getHours())
                                                var endMins = addZeroFormatToString(endTime.getMinutes())

                                                return (
                                                    <li key={idx} className={newTimeListItem}>
                                                        {
                                                            `${startHours}:${startMins} - ${endHours}:${endMins}`
                                                        }
                                                        <DeleteIcon cursor="pointer" fontSize="small" onClick={() => handleDeleteOneContactTime(t.id)}>
                                                        </DeleteIcon>
                                                    </li>
                                                )
                                            })}
                                </ul>
                                : null
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className={closeButtonContainer}>
                    <Button color="orange" handleClick={handleSetDialogOpen}>Sulje ikkuna</Button>
                </div>
                <Dialog
                    open={dialogOpen}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">{"Haluatko sulkea ikkunan ja lisätä valitut kontaktiajat?"}</DialogTitle>
                    <DialogActions>
                        <Button handleClick={handleDeleteServiceCardCancel} color="blue">
                            Peruuta
                        </Button>
                        <Button handleClick={handleClose} color="orange">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Modal >

    )
}

export default CalendarModal;