import React, {Fragment, useEffect, useRef} from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../../styles/theme";
import Button from "../Buttons";

// <editor-fold desc="ImageInput type definitions ...">
type RuleNames = "imageInputRoot" | "imageInputLabel" | "ImageInputHelpText";

export interface ImageInputProps {
  id: string;
  label: string;
  margin?: 'default' | 'dense' | 'none';
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  resetFile?: () => void;
  required?: boolean;
  selectedFile?: File;
  recommendedSizeText?: string;
  supportedImageFormatsText?: string
}

export type ImageInputStyleProps = {
  margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="ImageInput style definition ...">
const useStyles = createUseStyles<RuleNames, ImageInputStyleProps, Theme>(
  (theme) => {
    return {
      imageInputRoot: {
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 0
            }
            case 'dense': {
              return `${theme.spacing(2)}px 0px`
            }
            default: {
              return `${theme.spacing(4)}px 0px`
            }
          }
        }
      },
      imageInputLabel: {
        ...theme.typography.p,
        color: "#596675"
      },
      ImageInputHelpText: {
        ...theme.typography.p,
        color: theme.palette.error.main,
        fontSize: "0.9rem",
        textAlign: "right",
        display: "block",
        paddingTop: theme.spacing(1)
      }
    };
  }
);
// </editor-fold>

const ImageInput = (props: ImageInputProps) => {
  const {
    id,
    label,
    margin = 'default',
    handleChange = () => console.debug("button clicked"),
    resetFile = () => console.debug("reset file"),
    required,
    selectedFile,
    recommendedSizeText,
    supportedImageFormatsText
  } = props
  const fileInput = useRef<HTMLInputElement>(null)
  const { imageInputRoot, imageInputLabel, ImageInputHelpText } = useStyles({margin})

  return (
    <div className={imageInputRoot}>
      <label className={imageInputLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
      <div style={{width: "100%", marginTop: 4}}>
        <input
          style={{display: "none"}}
          ref={fileInput}
          type="file"
          id="cardImage"
          onChange={handleChange}
          accept="image/png, image/jpeg, image/jpg"
        />
        <Button color="blue" handleClick={() => fileInput?.current?.click()}>
          {selectedFile?.name || "Lisää tietokoneelta"}
        </Button>
        {supportedImageFormatsText ? <span className={ImageInputHelpText}>{supportedImageFormatsText}</span> : null}
        {recommendedSizeText ? <span className={ImageInputHelpText}>{recommendedSizeText}</span> : null}
        {selectedFile ? (
          <div style={{marginTop: 4}}>
            <Button color="blue" handleClick={resetFile}>
              Palauta alkuperäinen kuva
            </Button>
          </div>
        ): null}
      </div>
    </div>
  );
};

export default ImageInput;