import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import Chip from "../Chip";

// <editor-fold desc="SelectField type definitions ...">
type RuleNames = 'selectFieldRoot'
  | 'selectFieldLabel'
  | 'selectFieldInput'
  | 'selectFieldInputOption'
  | 'selectedChipsContainer'
  | 'selectedChipContainer'
  | 'errorLabel'
  | 'errorLabelContainer'

export interface Option {
  id: number;
  name: string;
  value: string | number
}

export interface SelectFieldProps {
  id: string;
  name: string;
  label: string;
  value?: string[] | string;
  autoComplete?: string;
  margin?: 'default' | 'dense' | 'none';
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleRemoveSelection?: (id: number) => void;
  required?: boolean;
  options: Option[];
  multiple?: boolean;
  notSelectedText?: string;
  showSelected?: boolean;
  maxSelected?: number
}

export type SelectFieldStyleProps = {
  disabled: boolean;
  margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="TextField style definition ...">
const useStyles = createUseStyles<RuleNames, SelectFieldStyleProps, Theme>(
  (theme) => {
    return {
      selectFieldRoot: {
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 0
            }
            case 'dense': {
              return `${theme.spacing(2)}px 0px`
            }
            default: {
              return `${theme.spacing(4)}px 0px`
            }
          }
        }
      },
      selectFieldInput: {
        boxShadow: [0, 3, 2, "#E9ECEF0D"],
        border: [1, "solid", "#DEE2E6"],
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
      },
      selectFieldLabel: {
        color: "#596675",
        ...theme.typography.p
      },
      errorLabel: {
        color: theme.palette.error.main,
        ...theme.typography.p,
      },
      selectFieldInputOption: {
        '&:hover': {
          backgroundColor: theme.palette.action.hover
        }
      },
      errorLabelContainer: {
        ...theme.typography.p,
        color: theme.palette.error.main,
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 1
            }
            case 'dense': {
              return `${theme.spacing(1)}px 0px`
            }
            default: {
              return `${theme.spacing(2)}px 0px`
            }
          }
        },
      },
      selectedChipsContainer: {
        ...theme.typography.p,
        color: theme.palette.error.main,
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 1
            }
            case 'dense': {
              return `${theme.spacing(1)}px 0px`
            }
            default: {
              return `${theme.spacing(2)}px 0px`
            }
          }
        },
      },
      selectedChipContainer: {
        display: "inline-block",
        margin: theme.spacing(1)
      }
    }
  }
);
// </editor-fold>

const SelectFieldSelected = (props: any) => {
  const { options, selected, notSelectedText, handleRemoveSelection, margin, disabled } = props;
  const {
    selectedChipsContainer,
    selectedChipContainer,
  } = useStyles({ margin, disabled })
  if (!selected || selected?.length < 1) {
    return notSelectedText ? (
      <div className={selectedChipsContainer}>
        {notSelectedText}
      </div>
    ) : null
  }
  return (
    <div className={selectedChipsContainer}>
      {
        options.map((option: any) => {
          const { id, name } = option;
          if (selected?.includes(id.toString())) return <div className={selectedChipContainer}><Chip name={name} handleClose={() => handleRemoveSelection(id)} /></div>
          return null
        })
      }
    </div>
  )
}

const SelectField = (props: SelectFieldProps) => {
  const {
    id,
    name,
    label,
    value,
    margin = 'default',
    autoComplete,
    disabled = false,
    handleChange = () => console.debug("field value changed"),
    handleRemoveSelection = (id) => console.debug(`remove ${id} selection`),
    required = false,
    options,
    multiple = false,
    notSelectedText,
    showSelected = true,
    maxSelected
  } = props
  const {
    selectFieldRoot,
    selectFieldLabel,
    selectFieldInput,
    selectFieldInputOption,
    errorLabelContainer,
    errorLabel
  } = useStyles({ disabled, margin })

  const renderAlert = () => {
    if ((value != undefined && maxSelected != undefined) && (value.length > maxSelected)) {
      return (
        <div className={errorLabelContainer}>
          <label className={errorLabel}>{`Voit valita maksimissaan ${maxSelected} vaihtoehtoa`}</label>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className={selectFieldRoot}>
      <label className={selectFieldLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
      <div style={{ width: "100%", marginTop: 4 }}>
        <select className={selectFieldInput} style={{ width: "100%", boxSizing: "border-box" }} id={id} name={name} value={value} onChange={handleChange} autoComplete={autoComplete} multiple={multiple}>
          {options.map(option => {
            const { name, id } = option;
            if (value?.includes(id.toString())) return null
            return (<option className={selectFieldInputOption} key={id} value={id}>{name}</option>)
          })}
        </select>
      </div>
      {maxSelected != undefined ?
        renderAlert()
        : null
      }
      {showSelected ? (<SelectFieldSelected options={options} selected={value} notSelectedText={notSelectedText} handleRemoveSelection={handleRemoveSelection} margin={margin} disabled={disabled} />)
        : null
      }
    </div>
  );
};

export default SelectField;
