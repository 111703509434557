import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getApiBaseUrl, refreshTokenFetch } from "../../utils/functions";
import Alert from "../../components/Alert";
import { Card, OrganizationCard } from "../../components/Cards";
import Button from "../../components/Buttons";
import TextField from "../../components/Inputs/TextField";
import TextAreaField from "../../components/Inputs/TextAreaField";
import DateField from "../../components/Inputs/DateField";
import SelectField, { Option } from "../../components/Inputs/SelectField";
import Paper from "../../components/Paper";
import ImageInput from "../../components/Inputs/ImageInput";
import Divider from "../../components/Divider";
import { diff } from "deep-diff";
import { Enums } from "../../App";
import { SelectedOrganizationServiceCard } from "../EditServiceCard";
import { CalendarModal } from "../../components/CalendarModal";
import { NewContactTime } from "../../utils/interfaces";

// <editor-fold desc="NewServiceCard type definitions ...">
type RuleNames = "newServiceCardRoot"
  | "headerContainer"
  | "dividerContainer"
  | "newServiceCardActionsContainer"
  | "basicInformationContainer"
  | "otherInformationContainer"
  | "previewContainer"
  | "organizationCardContainer";

export interface NewOrganizationServiceCard {
  title?: string;
  contact_email?: string;
  contact_times?: NewContactTime[];
  description?: string;
  active?: boolean;
  image?: File;
  tags?: string[];
  themes?: string[];
  types?: string[];
  regions?: string[];
}

export interface NewServiceCardProps {
  enums: Enums;
}

export type NewServiceCardStyleProps = {
};
// </editor-fold>

// <editor-fold desc="NewServiceCard style definition ...">
const useStyles = createUseStyles<RuleNames, NewServiceCardStyleProps, Theme>(
  (theme) => {
    return {
      newServiceCardRoot: {
        display: 'grid',
        width: '100%',
        gridTemplateRows: "auto",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(8),
        gridTemplateAreas: `
          "header header header"
          "divider divider divider"
          "basic basic basic"
          "other other other"
          "preview preview preview"
        `,
        [theme.breakpoints.up('tablet')]: {
          columnGap: theme.spacing(6),
          rowGap: theme.spacing(6),
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateAreas: `
            "header header header header"
            "divider divider divider divider"
            "basic basic other other"
            "preview preview preview preview"
        `,
        },
        [theme.breakpoints.up('desktop')]: {
          columnGap: theme.spacing(8),
          rowGap: theme.spacing(8),
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateAreas: `
          "header header header"
          "divider divider divider"
          "basic other preview"
        `,
        },
      },
      headerContainer: {
        gridArea: "header",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        padding: [theme.spacing(4), theme.spacing(4), 0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [theme.spacing(6), theme.spacing(6), 0, theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [theme.spacing(8), theme.spacing(8), 0, theme.spacing(8)],
        },
      },
      newServiceCardActionsContainer: {
        display: 'grid',
        gridTemplateRows: "auto",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `
          "checkbox cancel" 
          "save back"
        `,
        margin: "auto",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(4),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateAreas: `
            "checkbox cancel save back"
          `,
          columnGap: theme.spacing(6),
        },
        [theme.breakpoints.up('desktop')]: {
          columnGap: theme.spacing(8),
        },
      },
      dividerContainer: {
        gridArea: "divider"
      },
      basicInformationContainer: {
        gridArea: "basic",
        padding: [0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [0, 0, 0, theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [0, 0, theme.spacing(8), theme.spacing(8)],
        },
      },
      otherInformationContainer: {
        gridArea: "other",
        padding: [0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [0, theme.spacing(6), 0, 0],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [0, 0, theme.spacing(8), 0],
        },
      },
      previewContainer: {
        gridArea: "preview",
        padding: [0, theme.spacing(4), 0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          maxWidth: "50%",
          margin: "auto",
          padding: [0, theme.spacing(6), theme.spacing(6), theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          maxWidth: "100%",
          margin: 0,
          padding: [0, theme.spacing(8), theme.spacing(8), 0],
        },
      },
      organizationCardContainer: {
        width: "100%",
        minHeight: "450px",
        boxSizing: "border-box",
        position: "relative",
      }
    };
  }
);
// </editor-fold>

const NewServiceCard = (props: NewServiceCardProps) => {
  const {
    newServiceCardRoot,
    headerContainer,
    dividerContainer,
    newServiceCardActionsContainer,
    basicInformationContainer,
    otherInformationContainer,
    previewContainer,
    organizationCardContainer
  } = useStyles();

  const {
    enums
  } = props

  const [contactTimes, setContactTimes] = useState<NewContactTime[]>([])
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.user.token.data.access);
  const selectedWorkspace = useAppSelector((state) => state.user.workspace);
  const myRoles = useAppSelector((state) => state.user.userData.data.organization_user_roles);
  const myWorkspaceOrganization = myRoles.find((organizationUserRole: any) => organizationUserRole?.organization?.id === selectedWorkspace)?.organization
  const [newOrganizationServiceCard, setNewOrganizationServiceCard] = useState<NewOrganizationServiceCard>({
    title: undefined,
    contact_email: undefined,
    contact_times: undefined,
    description: undefined,
    active: true,
    image: undefined,
    tags: undefined,
    themes: undefined,
    types: undefined,
    regions: undefined,
  })
  const [creatingNewOrganizationServiceCard, SetCreatingNewOrganizationServiceCard] = useState(false)

  // should this be in state?
  let resultedCardId = 0

  const element = "new-service-card"
  const basicInformation = `${element}-basic-information`
  const otherInformation = `${element}-other-information`
  const preview = `${element}-preview`

  const changedValues = diff(newOrganizationServiceCard, {
    title: undefined,
    contact_email: undefined,
    contact_time: undefined,
    description: undefined,
    active: false,
    image: undefined,
    tags: undefined,
    themes: undefined,
    types: undefined,
    regions: undefined
  })

  const tagsMissing = !newOrganizationServiceCard?.tags || newOrganizationServiceCard?.tags.length === 0
  const themesMissing = !newOrganizationServiceCard?.themes || newOrganizationServiceCard?.themes.length === 0
  const typesMissing = !newOrganizationServiceCard?.types || newOrganizationServiceCard?.types.length === 0
  const regionsMissing = !newOrganizationServiceCard?.regions || newOrganizationServiceCard?.regions.length === 0
  const requiredFieldMissing =
    !newOrganizationServiceCard?.title ||
    !newOrganizationServiceCard?.description ||
    !newOrganizationServiceCard?.image ||
    !newOrganizationServiceCard?.contact_email ||
    tagsMissing ||
    themesMissing ||
    typesMissing ||
    regionsMissing;
  const tooManySelected = 
    (!newOrganizationServiceCard.tags || newOrganizationServiceCard.tags.length > 4) ||
    (!newOrganizationServiceCard.themes || newOrganizationServiceCard.themes.length > 4) ||
    (!newOrganizationServiceCard.types || newOrganizationServiceCard.types.length > 4) ||
    (!newOrganizationServiceCard.regions || newOrganizationServiceCard.regions.length > 4)
  const saveDisabled = requiredFieldMissing || tooManySelected

  const handleCreateNewOrganizationServiceCard = async () => {
    SetCreatingNewOrganizationServiceCard(true)
    let organizationServiceCardData: any = {};
    if (changedValues) {
      changedValues.map(changedValue => {
        const { path: [propertyName], lhs: updatedValue }: any = changedValue
        organizationServiceCardData[propertyName] = updatedValue
      })
    }

    const { image, tags, themes, types, regions, ...rest } = organizationServiceCardData

    const formData = new FormData();

    if (image) {
      // Update the formData object
      formData.append(
        "image",
        image,
        image.name
      );
    }

    // add tags, themes etc. to formData object
    if (tags != undefined) {
      for (var i = 0; i < tags.length; i++) {
        formData.append('tags', tags[i])
      }
    }
    if (themes != undefined) {
      for (var i = 0; i < themes.length; i++) {
        formData.append('themes', themes[i])
      }
    }
    if (types != undefined) {
      for (var i = 0; i < types.length; i++) {
        formData.append('types', types[i])
      }
    }
    if (regions != undefined) {
      for (var i = 0; i < regions.length; i++) {
        formData.append('regions', regions[i])
      }
    }
    Object.keys(rest).forEach(key => formData.append(key, rest[key]));

    const createNewOrganizationServiceCardResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization_service_cards/?organization=${selectedWorkspace}`,
      {
        method: 'POST',
        headers: {
          'Authorization': `jwt ${accessToken}`,
        },
        body: formData
      },
      dispatch
    )
    /* wait for the newOrganizationServiceCardResult creation response */
    const newOrganizationServiceCardResult = await createNewOrganizationServiceCardResult.json()
    /* Successful creation newOrganizationServiceCardResult */
    if (createNewOrganizationServiceCardResult.status === 201) {
      SetCreatingNewOrganizationServiceCard(false)
      console.debug("creation", newOrganizationServiceCardResult)
      resultedCardId = newOrganizationServiceCardResult.id

    }
    /* Creating newOrganizationServiceCard has failed */
    else {
      SetCreatingNewOrganizationServiceCard(false)
      console.debug("update", "error")
    }
  }

  useEffect(() => {
    document.title = "WTC NewServiceCard";
  }, [])

  return (
    <Paper>
      <div className={newServiceCardRoot}>
        <div id={`${basicInformation}-header`} className={headerContainer}>
          <div style={{ flexGrow: 1 }}>
            <PageTitle component="h4" gutterBottom>
              Luo uusi palvelukortti
            </PageTitle>
          </div>
          <div className={newServiceCardActionsContainer}>
            <div style={{ display: 'inline-block', gridArea: "checkbox" }}>
              <input
                type="checkbox"
                id="is-active"
                name="isActive"
                checked={newOrganizationServiceCard?.active}
                onChange={(e) => setNewOrganizationServiceCard(s => ({ ...s, active: !s.active }))}
              />
              <label htmlFor="is-active">Aktiivinen</label>
            </div>
            <div style={{ display: 'inline-block', gridArea: "back" }}>
              <Button color="orange" handleClick={() => { navigate("/my_organization/service_card_list", { replace: true }) }}>
                Takaisin
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "cancel" }}>
              <Button color="blue" handleClick={() => {
                setNewOrganizationServiceCard({
                  title: undefined,
                  contact_email: undefined,
                  contact_times: undefined,
                  description: undefined,
                  active: true,
                  image: undefined,
                  tags: undefined,
                  themes: undefined,
                  types: undefined,
                  regions: undefined,
                })
              }}>
                Tyhjennä
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "save" }}>
              <Button color="blue" disabled={saveDisabled} handleClick={async () => {
                await handleCreateNewOrganizationServiceCard();
                navigate("/my_organization/service_card_list", { replace: true })
              }}>
                Luo kortti
              </Button>
            </div>
          </div>
        </div>
        <div className={dividerContainer}>
          <Divider />
        </div>
        <div id={`${basicInformation}-container`} className={basicInformationContainer} >
          <PageTitle component="h4" gutterBottom>
            Perustiedot
          </PageTitle>
          <form>
            <TextField
              id={`${basicInformation}-title`}
              name="title"
              label="Kortin otsikko"
              value={newOrganizationServiceCard?.title || ""}
              handleChange={(e) => setNewOrganizationServiceCard(s => ({ ...s, title: e.target.value }))}
              required
              maxLength={90}
              showMaxLength={true}
            />
            <TextField
              id={`${basicInformation}-contact-email`}
              name="contactEmail"
              label="Vastuuhenkilön sähköposti"
              value={newOrganizationServiceCard?.contact_email || ""}
              type={"email"}
              handleChange={(e) => setNewOrganizationServiceCard(s => ({ ...s, contact_email: e.target.value }))}
              required
              alertText="Palvelukortille on määritettävä yhteyshenkilön sähköposti saadaksesi yhteydenottopyyntöjä."
              showAlertText={newOrganizationServiceCard?.contact_email === ""}
            />
            <TextAreaField
              id={`${basicInformation}-description`}
              name="description"
              label="Palvelun kuvaus"
              value={newOrganizationServiceCard?.description || ""}
              required
              rows={8}
              handleChange={(e) => setNewOrganizationServiceCard(s => ({ ...s, description: e.target.value }))}
              maxLength={450}
            />
            <Button color="blue" disabled={saveDisabled} handleClick={async () => {
              await handleCreateNewOrganizationServiceCard();
              navigate(`/my_organization/service_card/${resultedCardId}?calendarOpen=true`, { replace: true })
            }}>
              Hallitse kontaktiaikoja
            </Button>
          </form>
        </div>

        <div id={`${otherInformation}-container`} className={otherInformationContainer} >
          <PageTitle component="h4" gutterBottom>
            Muut tiedot
          </PageTitle>
          <SelectField
            id={`${otherInformation}-tags`}
            name="tags"
            label="Tagit"
            value={newOrganizationServiceCard?.tags}
            maxSelected={4}
            options={
              enums.tags.map((tag: { value: any; key: number; }) => {
                const option: Option = {
                  id: tag.key,
                  name: tag.value,
                  value: tag.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = newOrganizationServiceCard?.tags ? [...newOrganizationServiceCard.tags] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setNewOrganizationServiceCard(s => ({ ...s, tags: [...arrayToBeModified] }))
              }
            }}
            handleChange={(e) => {
              setNewOrganizationServiceCard(s => ({ ...s, tags: s?.tags ? [...s.tags, e.target.value] : [e.target.value] }))
            }}
            notSelectedText="Ei yhtään valittua tagia"
            multiple
            required
          />
          <SelectField
            id={`${otherInformation}-themes`}
            name="themes"
            label="Teemat"
            value={newOrganizationServiceCard?.themes}
            maxSelected={4}
            options={
              enums.themes.map((theme: { value: any; key: number; }) => {
                const option: Option = {
                  id: theme.key,
                  name: theme.value,
                  value: theme.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = newOrganizationServiceCard?.themes ? [...newOrganizationServiceCard.themes] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setNewOrganizationServiceCard(s => ({ ...s, themes: [...arrayToBeModified] }))
              }
            }}
            handleChange={(e) => {
              setNewOrganizationServiceCard(s => ({ ...s, themes: s?.themes ? [...s.themes, e.target.value] : [e.target.value] }))
            }}
            notSelectedText="Ei yhtään valittua teemaa"
            multiple
            required
          />
          <SelectField
            id={`${otherInformation}-types`}
            name="types"
            label="Palvelutyypit"
            value={newOrganizationServiceCard?.types}
            maxSelected={4}
            options={
              enums.types.map((type: { value: any; key: number; }) => {
                const option: Option = {
                  id: type.key,
                  name: type.value,
                  value: type.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = newOrganizationServiceCard?.types ? [...newOrganizationServiceCard.types] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setNewOrganizationServiceCard(s => ({ ...s, types: [...arrayToBeModified] }))
              }
            }}
            handleChange={(e) => {
              setNewOrganizationServiceCard(s => ({ ...s, types: s?.types ? [...s.types, e.target.value] : [e.target.value] }))
            }}
            notSelectedText="Ei yhtään valittua palvelutyyppiä"
            multiple
            required
          />
          <SelectField
            id={`${otherInformation}-regions`}
            name="regions"
            label="Alue"
            value={newOrganizationServiceCard?.regions}
            maxSelected={4}
            options={
              enums.regions.map((region: { value: any; key: number; }) => {
                const option: Option = {
                  id: region.key,
                  name: region.value,
                  value: region.value
                }
                return (
                  option
                )
              })
            }
            handleRemoveSelection={(id) => {
              const arrayToBeModified = newOrganizationServiceCard?.regions ? [...newOrganizationServiceCard.regions] : [];
              const index = arrayToBeModified.indexOf(id.toString());
              if (index > -1) {
                arrayToBeModified.splice(index, 1); // 2nd parameter means remove one item only
                setNewOrganizationServiceCard(s => ({ ...s, regions: [...arrayToBeModified] }))
              }
            }}
            handleChange={(e) => {
              setNewOrganizationServiceCard(s => ({ ...s, regions: s?.regions ? [...s.regions, e.target.value] : [e.target.value] }))
            }}
            notSelectedText="Ei yhtään valittua aluetta"
            multiple
            required
          />
          <ImageInput
            id={`${otherInformation}-cover-image`}
            label="Palvelun kuvabanneri"
            selectedFile={newOrganizationServiceCard.image}
            handleChange={(e) => setNewOrganizationServiceCard(s => ({ ...s, image: e?.target?.files?.[0] || undefined }))}
            resetFile={() => setNewOrganizationServiceCard(s => ({ ...s, image: undefined }))}
            recommendedSizeText="Suositeltu koko 1920x1080"
            supportedImageFormatsText="Tuetut kuvaformaatit: jpg, jpeg, png"
            required
          />
        </div>

        <div id="edit-service-card-preview-container" className={previewContainer} >
          <PageTitle component="h4" gutterBottom>
            Esikatsele
          </PageTitle>
          <div className={organizationCardContainer}>
            <OrganizationCard
              title={newOrganizationServiceCard?.title || "Lorem Ipsum"}
              description={newOrganizationServiceCard?.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis semper justo. Pellentesque commodo lorem sit amet nunc varius fermentum. Phasellus venenatis pulvinar vestibulum. Etiam vel urna eu arcu varius consectetur sed eget libero. In posuere justo purus, vitae congue urna convallis sed."}
              imageUrl={newOrganizationServiceCard?.image ? URL.createObjectURL(newOrganizationServiceCard.image) : (myWorkspaceOrganization?.image || "")}
              logoUrl={myWorkspaceOrganization?.logo || ""}
              organizationName={myWorkspaceOrganization?.name || ""}
              tags={newOrganizationServiceCard?.tags || [1]}
              themes={newOrganizationServiceCard?.themes || [1]}
              types={newOrganizationServiceCard?.types || [1]}
              regions={newOrganizationServiceCard?.regions || [1]}
              active={newOrganizationServiceCard?.active}
              enums={props.enums}
            />
          </div>
        </div>
      </div>
    </Paper>

  );
};

export default NewServiceCard;

