import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import {useAppSelector} from "../../hooks";
import Button from "../../components/Buttons";

// <editor-fold desc="MyUser type definitions ...">
type RuleNames = "myUserRoot";

export interface MyUserProps {
}

export type MyUserStyleProps = {
};
// </editor-fold>

// <editor-fold desc="MyUser style definition ...">
const useStyles = createUseStyles<RuleNames, MyUserStyleProps, Theme>(
  (theme) => {
    return {
      myUserRoot: {
        maxWidth: theme.breakpoints.sizes.sm,
        margin: "auto",
        flex: 1,
      }
    };
  }
);
// </editor-fold>

const MyUser = (props: MyUserProps) => {
  const {myUserRoot} = useStyles();
  const myUser = useAppSelector((state) => state.user.userData);
  const {data: myUserData} = myUser
  const [me, setMe] = useState<{
    first_name: string;
    last_name: string;
    username: string;
    email: string;
  }>({...myUserData})

  useEffect(() => {
    document.title = "WTC Omat tiedot";
  }, [])

  return (
    <div className={myUserRoot}>
      <PageTitle component="h2">
        Omat tiedot
      </PageTitle>
      <div style={{margin: "16px 0"}}>
        <Button>
          Tallenna
        </Button>
      </div>
      <div style={{margin: "16px 0"}}>
        <label htmlFor="firstName">Etunimi:</label>
        <div style={{width: "100%", marginTop: 4}}>
          <input style={{width: "100%", boxSizing: "border-box"}} type="text" id="firstName" name="firstName" value={me?.first_name || ""} onChange={(e) => setMe(s => ({...s, first_name: e.target.value}))} />
        </div>
      </div>
      <div style={{margin: "16px 0"}}>
        <label htmlFor="lastName">Sukunimi:</label>
        <div style={{width: "100%", marginTop: 4}}>
          <input style={{width: "100%", boxSizing: "border-box"}} type="text" id="lastName" name="lastName" value={me?.last_name || ""} onChange={(e) => setMe(s => ({...s, last_name: e.target.value}))} />
        </div>
      </div>
      <div style={{margin: "16px 0"}}>
        <label htmlFor="username">Käyttäjätunnus:</label>
        <div style={{width: "100%", marginTop: 4}}>
          <input style={{width: "100%", boxSizing: "border-box"}} type="text" id="username" name="username" value={me?.username || ""} onChange={(e) => setMe(s => ({...s, username: e.target.value}))} />
        </div>
      </div>
      <div style={{margin: "16px 0"}}>
        <label htmlFor="email">Sähköposti:</label>
        <div style={{width: "100%", marginTop: 4}}>
          <input style={{width: "100%", boxSizing: "border-box"}} type="text" id="email" name="email" value={me?.email || ""} onChange={(e) => setMe(s => ({...s, email: e.target.value}))} />
        </div>
      </div>
    </div>
  );
};

export default MyUser;