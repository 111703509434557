import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { useAppSelector } from "../../hooks";
import { OrganizationCard } from "../../components/Cards";
import Alert from "../../components/Alert";
import { getApiBaseUrl } from "../../utils/functions";
import Button from "../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { Enums } from "../../App";
import { TagSharp } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

// <editor-fold desc="MyOrganizationServiceCardList type definitions ...">
type RuleNames = "cardContainer" | "myOrganizationServiceCardsRoot" | "disabledCardOverlay";

interface SelectedOrganizationServiceCard {
  id: number;
  title: string;
  description: string;
  image: string;
  active: boolean;
  organization: {
    name: string;
    logo: string;
    image: string;
  }
  tags: any[],
  themes: any[],
  types: any[],
  regions: any[],
  enums: Enums
}

export interface MyOrganizationServiceCardListProps {
  enums: Enums
}

export type MyOrganizationServiceCardListStyleProps = {
};
// </editor-fold>

// <editor-fold desc="MyOrganizationServiceCardList style definition ...">
const useStyles = createUseStyles<RuleNames, MyOrganizationServiceCardListStyleProps, Theme>(
  (theme) => {
    return {
      cardContainer: {
        width: "100%",
        minHeight: "450px",
        boxSizing: "border-box",
        padding: theme.spacing(4),
        position: "relative",
        [theme.breakpoints.up('tablet')]: {
          width: "50%",
          padding: theme.spacing(1),
        },
        [theme.breakpoints.up('desktop')]: {
          width: "33.333%",
          padding: theme.spacing(2),
        },
      },
      myOrganizationServiceCardsRoot: {
        maxWidth: theme.breakpoints.sizes.sm,
        margin: "auto",
        flex: 1,
      },
      disabledCardOverlay: {
        zIndex: 2500, 
        backgroundColor: "grey", 
        position: "absolute", 
        height: "96,5%", 
        width:"96,5%", 
        opacity: "0.5",
        cursor: "pointer"
      }
    };
  }
);
// </editor-fold>

const MyOrganizationServiceCardList = (props: MyOrganizationServiceCardListProps) => {
  const { cardContainer, myOrganizationServiceCardsRoot, disabledCardOverlay } = useStyles();
  const navigate = useNavigate();
  const accessToken = useAppSelector((state) => state.user.token.data.access);
  const selectedWorkspace = useAppSelector((state) => state.user.workspace);
  const myRoles = useAppSelector((state) => state.user.userData.data.organization_user_roles);
  const myWorkspaceOrganization = myRoles.find((organizationUserRole: any) => organizationUserRole?.organization?.id === selectedWorkspace)?.organization
  const [selectedOrganizationServiceCards, setSelectedOrganizationServiceCards] = useState<{
    loading: boolean;
    data?: SelectedOrganizationServiceCard[];
    error: string;
  }>({
    loading: true,
    data: undefined,
    error: ""
  }) 

  const navigateToEditCard = (id: number) => {
    navigate(`/my_organization/service_card/${id}`, { replace: true })
  }

  const handleGetMyOrganizationServiceCards = async () => {
    setSelectedOrganizationServiceCards(s => ({ ...s, loading: true, error: "" }))
    const fetchMyOrganizationServiceCardsResult = await fetch(
      `${getApiBaseUrl()}/my_organization_service_cards/?organization=${selectedWorkspace}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `jwt ${accessToken}`,
        },
      }
    )
    /* wait for the myOrganizationServiceCards response */
    const myOrganizationServiceCardsResult = await fetchMyOrganizationServiceCardsResult.json()
    /* Successful request on myOrganizationServiceCards data */
    if (fetchMyOrganizationServiceCardsResult.ok) {
      setSelectedOrganizationServiceCards(s => ({ ...s, loading: false, data: myOrganizationServiceCardsResult }))
    }
    /* Requesting myOrganizationServiceCards data has failed */
    else {
      let message = "Tuntematon virhe";
      /* Missing required organization parameter from request */
      if (Array.isArray(myOrganizationServiceCardsResult) && myOrganizationServiceCardsResult.includes("Missing required organization parameter")) {
        message = "Puuttuva parametri organization"
      }
      /* Invalid OrganizationUserRole for the requested organization */
      else if (Array.isArray(myOrganizationServiceCardsResult) && myOrganizationServiceCardsResult.includes("User role not exists in requested organization")) {
        message = "Organisaatiossa ei ole voimassaolevaa organisaatioroolia"
      }
      setSelectedOrganizationServiceCards(s => ({ ...s, loading: false, error: message }))
    }
  }

  useEffect(() => {
    if (selectedWorkspace) {
      handleGetMyOrganizationServiceCards().catch(e => setSelectedOrganizationServiceCards(s => ({ ...s, loading: false, error: "Palveluun ei saatu yhteyttä" })))
    }
  }, [selectedWorkspace])

  useEffect(() => {
    document.title = `${myWorkspaceOrganization?.name || "Organisaation"} palvelukortit`;
  }, [myWorkspaceOrganization])

  if (selectedOrganizationServiceCards.loading) {
    return (
      <div style={{ margin: "auto" }}>
        <PageTitle component="h2">
          {`Ladataan ${myWorkspaceOrganization?.name || "organisaation"} palvelukortteja...`}
        </PageTitle>
      </div>
    )
  }

  if (selectedOrganizationServiceCards?.error) {
    return (
      <div className={myOrganizationServiceCardsRoot}>
        <Alert title="Virhe" message={selectedOrganizationServiceCards.error} />
      </div>
    )
  }

  return (
    <div style={{ flex: 1 }}>
      <PageTitle component="h2">
        {`${myWorkspaceOrganization?.name || "Organisaation"} palvelukortit`}
      </PageTitle>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{ margin: '18px', maxWidth: 250, display: "inline-block"}}>
          <Button color="blue" handleClick={() => { navigate("/my_organization/service_card/new") }}>
            Luo uusi palvelukortti
          </Button>
        </div>
        <div style={{ margin: '18px', maxWidth: 250, display: "inline-block" }}>
          <Button color="orange" handleClick={() => { navigate("/admin/", { replace: true }) }}>
            Takaisin
          </Button>
        </div>
      </div>
      <PageTitle component="h4">
        {selectedOrganizationServiceCards?.data?.length === 1 ? "Löytyi 1 palvelukortti" : `Löytyi ${selectedOrganizationServiceCards?.data?.length || 0} palvelukorttia`}
      </PageTitle>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {(selectedOrganizationServiceCards?.data?.length || 0) > 0 ? (
          selectedOrganizationServiceCards?.data?.map(organizationCard => {
            const {
              id,
              title,
              description,
              image: cardImageUrl,
              organization: {
                name,
                logo: organizationLogoUrl,
                image: organizationImageUrl
              },
              tags,
              themes,
              types,
              regions,
              active,
              enums = props.enums
            } = organizationCard
            return (
              <div className={cardContainer} key={id}>
                <OrganizationCard
                  editable
                  id={id}
                  title={title || ""}
                  description={description || ""}
                  imageUrl={cardImageUrl || organizationImageUrl || ""}
                  logoUrl={organizationLogoUrl || ""}
                  organizationName={name || ""}
                  handleClick = {() => navigateToEditCard(id)}
                  active = {active}
                  tags = {tags}
                  themes = {themes}
                  types = {types}
                  regions = {regions}
                  enums = {enums}
                />
              </div>
            )
          })
        ) : null}
      </div>
    </div>
  );
};

export default MyOrganizationServiceCardList;
