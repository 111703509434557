import React, { Fragment, useEffect, useRef, useState } from "react";
import { diff } from 'deep-diff';
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getApiBaseUrl, refreshTokenFetch } from "../../utils/functions";
import Alert from "../../components/Alert";
import { OrganizationCard } from "../../components/Cards";
import Button from "../../components/Buttons";
import TextField from "../../components/Inputs/TextField";
import TextAreaField from "../../components/Inputs/TextAreaField";
import DateField from "../../components/Inputs/DateField";
import SelectField from "../../components/Inputs/SelectField";
import Paper from "../../components/Paper";
import ImageInput from "../../components/Inputs/ImageInput";
import Divider from "../../components/Divider";
import SnackbarError from "../SnackbarError";
import { useSnackbar } from "notistack";
import { Enums } from "../../App";
import { handleLogout } from "../../store/user/actions";

// <editor-fold desc="EditMyOrganization type definitions ...">
type RuleNames = "editMyOrganizationLoadingRoot"
  | "editMyOrganizationErrorRoot"
  | "editMyOrganizationRoot"
  | "headerContainer"
  | "dividerContainer"
  | "editMyOrganizationActionsContainer"
  | "basicInformationContainer"
  | "otherInformationContainer"
  | "previewContainer"
  | "organizationCardContainer";

interface SelectedOrganization {
  id?: number;
  name?: string;
  short_name?: string;
  is_active?: boolean;
  url?: string;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  organization_email?: string;
  image?: string;
  logo?: string;
}


export interface EditMyOrganizationProps {
  enums: Enums
}

export type EditMyOrganizationStyleProps = {
};
// </editor-fold>

// <editor-fold desc="EditMyOrganization style definition ...">
const useStyles = createUseStyles<RuleNames, EditMyOrganizationStyleProps, Theme>(
  (theme) => {
    return {
      editMyOrganizationLoadingRoot: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      },
      editMyOrganizationErrorRoot: {
        margin: "auto",
      },
      editMyOrganizationRoot: {
        display: 'grid',
        width: '100%',
        gridTemplateRows: "auto",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(8),
        gridTemplateAreas: `
          "header header header"
          "divider divider divider"
          "basic basic basic"
          "other other other"
          "preview preview preview"
        `,
        [theme.breakpoints.up('tablet')]: {
          columnGap: theme.spacing(6),
          rowGap: theme.spacing(6),
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateAreas: `
            "header header header header"
            "divider divider divider divider"
            "basic basic other other"
            "preview preview preview preview"
        `,
        },
        [theme.breakpoints.up('desktop')]: {
          columnGap: theme.spacing(8),
          rowGap: theme.spacing(8),
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateAreas: `
          "header header header"
          "divider divider divider"
          "basic other preview"
        `,
        },
      },
      headerContainer: {
        gridArea: "header",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        padding: [theme.spacing(4), theme.spacing(4), 0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [theme.spacing(6), theme.spacing(6), 0, theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [theme.spacing(8), theme.spacing(8), 0, theme.spacing(8)],
        },
      },
      editMyOrganizationActionsContainer: {
        display: 'grid',
        gridTemplateRows: "auto",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `
          "checkbox cancel"
          "save back"
        `,
        margin: "auto",
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(4),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateAreas: `
          "checkbox cancel save back"
        `,
          columnGap: theme.spacing(6),
        },
        [theme.breakpoints.up('desktop')]: {
          columnGap: theme.spacing(8),
        },
      },
      dividerContainer: {
        gridArea: "divider"
      },
      basicInformationContainer: {
        gridArea: "basic",
        padding: [0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [0, 0, 0, theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [0, 0, theme.spacing(8), theme.spacing(8)],
        },
      },
      otherInformationContainer: {
        gridArea: "other",
        padding: [0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          padding: [0, theme.spacing(6), 0, 0],
        },
        [theme.breakpoints.up('desktop')]: {
          padding: [0, 0, theme.spacing(8), 0],
        },
      },
      previewContainer: {
        gridArea: "preview",
        padding: [0, theme.spacing(4), 0, theme.spacing(4)],
        [theme.breakpoints.up('tablet')]: {
          maxWidth: "50%",
          margin: "auto",
          padding: [0, theme.spacing(6), theme.spacing(6), theme.spacing(6)],
        },
        [theme.breakpoints.up('desktop')]: {
          maxWidth: "100%",
          margin: 0,
          padding: [0, theme.spacing(8), theme.spacing(8), 0],
        },
      },
      organizationCardContainer: {
        width: "100%",
        minHeight: "450px",
        boxSizing: "border-box",
        position: "relative",
      }
    };
  }
);
// </editor-fold>

const EditServiceCard = (props: EditMyOrganizationProps) => {
  const {
    editMyOrganizationLoadingRoot,
    editMyOrganizationErrorRoot,
    editMyOrganizationRoot,
    headerContainer,
    dividerContainer,
    editMyOrganizationActionsContainer,
    basicInformationContainer,
    otherInformationContainer,
    previewContainer,
    organizationCardContainer
  } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.user.token.data.access);
  const selectedWorkspace = useAppSelector((state) => state.user.workspace);
  const myRoles = useAppSelector((state) => state.user.userData.data.organization_user_roles);
  const myWorkspaceOrganization = myRoles.find((organizationUserRole: any) => organizationUserRole?.organization?.id === selectedWorkspace)?.organization
  const [updatingOrganizationData, setUpdatingOrganizationData] = useState(false)
  const [defaultValues, setDefaultValues] = useState<SelectedOrganization | undefined>(undefined)
  const [selectedOrganization, setSelectedOrganization] = useState<{
    loading: boolean;
    data?: SelectedOrganization;
    error: string;
  }>({
    loading: true,
    data: undefined,
    error: ""
  })
  const [selectedLogo, setSelectedLogo] = useState<File | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);

  const element = "edit-organization"
  const basicInformation = `${element}-basic-information`
  const otherInformation = `${element}-other-information`
  const preview = `${element}-preview`

  const changedValues = diff(selectedOrganization.data, defaultValues)
  const requiredFieldMissing = !selectedOrganization?.data?.name || !selectedOrganization?.data?.organization_email
  const [phoneValidated, setPhoneValidated] = useState(true)
  const saveDisabled = (!changedValues && !selectedLogo && !selectedImage) || requiredFieldMissing || !phoneValidated

  const handleGetMyOrganization = async () => {
    setSelectedOrganization(s => ({ ...s, loading: true, error: "" }))
    const fetchMyOrganizationResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization/${selectedWorkspace}/`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `jwt ${accessToken}`,
        },
      },
      dispatch
    )
    /* wait for the myOrganization response */
    const myOrganizationResult = await fetchMyOrganizationResult.json()
    /* Successful request on myOrganization data */
    if (fetchMyOrganizationResult.ok) {
      setDefaultValues(myOrganizationResult)
      setSelectedOrganization(s => ({ ...s, loading: false, data: myOrganizationResult }))
    }
    /* Requesting myOrganization data has failed */
    else {
      console.debug("test exipred", myOrganizationResult)
      if (myOrganizationResult?.code === "token_not_valid") {
        console.debug("test exipred")
        enqueueSnackbar('Istunto on vanhentunut', {
          preventDuplicate: true,
          variant: "error"
        });
        navigate("/login", { state: { navigatedFrom: location.pathname } })
      }
      setSelectedOrganization(s => ({ ...s, loading: false, error: "Tuntematon virhe" }))
    }
  }

  const handleUpdateMyOrganization = async () => {

    setUpdatingOrganizationData(true)
    let organizationDataToBeUpdated: any = {};
    if (changedValues) {
      changedValues.map(changedValue => {
        const { path: [propertyName], lhs: updatedValue }: any = changedValue
        organizationDataToBeUpdated[propertyName] = updatedValue
      })
    }

    const formData = new FormData();

    if (selectedLogo) {
      // Update the formData object
      formData.append(
        "logo",
        selectedLogo,
        selectedLogo.name
      );
    }

    if (selectedImage) {
      // Update the formData object
      formData.append(
        "image",
        selectedImage,
        selectedImage.name
      );
    }

    Object.keys(organizationDataToBeUpdated).forEach(key => formData.append(key, organizationDataToBeUpdated[key]));

    const updateMyOrganizationResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization/${selectedWorkspace}/`,
      {
        method: 'PATCH',
        headers: {
          'Authorization': `jwt ${accessToken}`,
        },
        body: formData
      },
      dispatch
    )
    /* wait for the updateMyOrganization response */
    const myOrganizationResult = await updateMyOrganizationResult.json()
    /* Successful request on updateMyOrganization data */
    if (updateMyOrganizationResult.ok) {
      setUpdatingOrganizationData(true)
      setDefaultValues(myOrganizationResult)
      setSelectedOrganization(s => ({ ...s, loading: false, data: myOrganizationResult }))
      console.debug("update", myOrganizationResult)
      enqueueSnackbar("Organisaation tietoja muutettu", {
        preventDuplicate: true,
        variant: "success"
      });
    }
    /* Requesting myOrganization data has failed */
    else {
      setUpdatingOrganizationData(true)
      console.debug("update", "error")
      enqueueSnackbar("Tietojen muutos epäonnistui", {
        preventDuplicate: true,
        variant: "error"
      });
    }
  }

  useEffect(() => {
    document.title = `${myWorkspaceOrganization?.name || "Organisaation"} tiedot`;
  }, [myWorkspaceOrganization])

  useEffect(() => {
    if (selectedWorkspace) {
      handleGetMyOrganization().catch(e => setSelectedOrganization(s => ({ ...s, loading: false, error: "Palveluun ei saatu yhteyttä" })))
    }
  }, [selectedWorkspace])

  if (selectedOrganization.loading) {
    return (
      <Paper>
        <div className={editMyOrganizationLoadingRoot}>
          <PageTitle component="h2">
            {`Ladataan ${myWorkspaceOrganization?.name || "organisaation"} tietoja...`}
          </PageTitle>
        </div>
      </Paper>
    )
  }

  if (selectedOrganization?.error) {
    return (
      <div className={editMyOrganizationErrorRoot}>
        <Alert title="Virhe" message={selectedOrganization.error} />
      </div>
    )
  }

  const phoneValidator = (phone: string | undefined) => {
    const regex = new RegExp(/^\+.\d{8,15}$/);
    if (phone) {
      if (regex.test(phone)) {
        setPhoneValidated(true)
      } else {
        setPhoneValidated(false)
      }
    }
  }

  return (
    <Paper>
      <div className={editMyOrganizationRoot}>
        <div id={`${basicInformation}-header`} className={headerContainer}>
          <div style={{ flexGrow: 1 }}>
            <PageTitle component="h4" gutterBottom>
              Organisaation muokkaus
            </PageTitle>
          </div>
          <div className={editMyOrganizationActionsContainer}>
            <div style={{ display: 'inline-block', gridArea: "checkbox" }}>
              <input
                type="checkbox"
                id="is-active"
                name="isActive"
                checked={selectedOrganization?.data?.is_active}
                onChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], is_active: !s?.data?.is_active } }))}
              />
              <label htmlFor="is-active">Aktiivinen</label>
            </div>
            <div style={{ display: 'inline-block', gridArea: "back" }}>
              <Button color="orange" handleClick={() => { navigate("/admin/", { replace: true }) }}>
                Takaisin
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "cancel" }}>
              <Button color="blue" handleClick={() => {
                defaultValues ? setSelectedOrganization({ loading: false, error: "", data: defaultValues }) : console.debug("no default values set")
              }}>
                Peruuta
              </Button>
            </div>
            <div style={{ display: 'inline-block', gridArea: "save" }}>
              <Button disabled={saveDisabled} color="blue" handleClick={handleUpdateMyOrganization}>
                Tallenna
              </Button>
            </div>
          </div>
        </div>
        <div className={dividerContainer}>
          <Divider />
        </div>
        <div id={`${basicInformation}-container`} className={basicInformationContainer} >
          <PageTitle component="h4" gutterBottom>
            Perustiedot
          </PageTitle>
          <form>
            <TextField
              id={`${basicInformation}-name`}
              name="name"
              label="Organisaation nimi"
              value={selectedOrganization?.data?.name || ""}
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], name: e.target.value } }))}
              maxLength={90}
              showMaxLength={true}
              required
            />
            {/*<TextField
              id={`${basicInformation}-short-name`}
              name="shortName"
              label="Nimen lyhenne"
              value={selectedOrganization?.data?.short_name || ""}
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], short_name: e.target.value } }))}
            />*/}
            <TextField
              id={`${basicInformation}url`}
              name="url"
              label="Kotisivut"
              value={selectedOrganization?.data?.url || ""}
              placeholder="https://esimerkki.fi"
              pattern="https://.*"
              type="url"
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], url: e.target.value } }))}
            />
            <TextField
              id={`${basicInformation}-contact-name`}
              name="contactName"
              label="Yhteyshenkilön nimi"
              value={selectedOrganization?.data?.contact_name || ""}
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], contact_name: e.target.value } }))}
            />
            <TextField
              id={`${basicInformation}-contact-email`}
              name="contactEmail"
              label="Yhteyshenkilön sähköposti"
              value={selectedOrganization?.data?.contact_email || ""}
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], contact_email: e.target.value } }))}
            />
            <TextField
              id={`${basicInformation}-contact-phone`}
              name="contactPhone"
              label="Yhteyshenkilön puhelin"
              type={"tel"}
              value={selectedOrganization?.data?.contact_phone || ""}
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], contact_phone: e.target.value } }))}
              onBlur={(e) => phoneValidator(selectedOrganization?.data?.contact_phone)}
              maxLength={16}
              placeholder={"+358"}
              alertText={"Syötä puhelinnumero kansainvälisessä muodossa!"}
              showAlertText={!phoneValidated}
            />
            <TextField
              id={`${basicInformation}-organization-email`}
              name="email"
              label="Yleinen sähköposti"
              value={selectedOrganization?.data?.organization_email || ""}
              required
              handleChange={(e) => setSelectedOrganization(s => ({ ...s, data: { ...s["data"], organization_email: e.target.value } }))}
            />
          </form>
        </div>

        <div id={`${otherInformation}-container`} className={otherInformationContainer} >
          <PageTitle component="h4" gutterBottom>
            Muut tiedot
          </PageTitle>
          <ImageInput
            id={`${otherInformation}-logo`}
            label="Logo"
            selectedFile={selectedLogo}
            handleChange={(e) => setSelectedLogo(e?.target?.files?.[0] || undefined)}
            resetFile={() => (setSelectedLogo(undefined))}
            recommendedSizeText="Suositeltu koko 500x500"
            supportedImageFormatsText="Tuetut kuvaformaatit: jpg, jpeg, png"
            required
          />
          <ImageInput
            id={`${otherInformation}-image`}
            label="Kuva"
            selectedFile={selectedImage}
            handleChange={(e) => setSelectedImage(e?.target?.files?.[0] || undefined)}
            resetFile={() => (setSelectedImage(undefined))}
            recommendedSizeText="Suositeltu koko 1920x1080"
            supportedImageFormatsText="Tuetut kuvaformaatit: jpg, jpeg, png"
            required
          />
        </div>

        <div id="edit-service-card-preview-container" className={previewContainer} >
          <PageTitle component="h4" gutterBottom>
            Esikatsele
          </PageTitle>
          <div className={organizationCardContainer}>
            <OrganizationCard
              title={"Lorem Ipsum"}
              description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis semper justo. Pellentesque commodo lorem sit amet nunc varius fermentum. Phasellus venenatis pulvinar vestibulum. Etiam vel urna eu arcu varius consectetur sed eget libero. In posuere justo purus, vitae congue urna convallis sed."}
              imageUrl={selectedImage ? URL.createObjectURL(selectedImage) : (selectedOrganization?.data?.image || "")}
              logoUrl={selectedLogo ? URL.createObjectURL(selectedLogo) : (selectedOrganization?.data?.logo || "")}
              organizationName={selectedOrganization?.data?.name || ""}
              tags={[1]}
              themes={[1]}
              types={[1]}
              regions={[1]}
              enums={props.enums}
              active={true}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default EditServiceCard;
