import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { ThemeProvider } from "react-jss";
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import theme from "./styles/theme";
import App from "./App";
import 'normalize.css'; // Note this
import "./styles/styles.css";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<h1>Loading...</h1>}>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </Suspense>
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

/*
if (module["hot"]) {
  console.debug("HRM enabled");
  module["hot"].accept();
} else {
  console.debug("HRM not available");
}
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
