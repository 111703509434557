const values = {
  mobile: 0,
  tablet: 640,
  laptop: 1024,
  desktop: 1200,
}

const sizes = {
  xs: 0,
  sm: 480,
  md: 860,
  ls: 1024,
  xl: 1200,
}

type breakpointOptions = keyof typeof values

export interface Breakpoints {
  sizes: typeof sizes,
  values: typeof values,
  up: (breakpoint: breakpointOptions) => string;
  down: (breakpoint: breakpointOptions) => string;
}

export default {
  sizes,
  values,
  up: (breakpoint: breakpointOptions) => {
    return `@media (min-width: ${values[breakpoint]}px)`
  },
  down: (breakpoint: breakpointOptions) => {
    return `@media (max-width: ${values[breakpoint]}px)`
  },
}

