import { kMaxLength } from "buffer";
import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../../styles/theme";

// <editor-fold desc="TextAreaField type definitions ...">
type RuleNames = 'textAreaFieldRoot' | 'textAreaFieldLabel' | 'textAreaFieldInput' | 'textAreaFieldChars'

export interface TextAreaFieldProps {
  id: string;
  name: string;
  label: string;
  value: string;
  autoComplete?: string;
  margin?: 'default' | 'dense' | 'none';
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  rows?: number;
  maxLength?: number;
}

export type TextAreaFieldStyleProps = {
  disabled: boolean;
  margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="TextAreaField style definition ...">
const useStyles = createUseStyles<RuleNames, TextAreaFieldStyleProps, Theme>(
  (theme) => {
    return {
      textAreaFieldRoot: {
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 0
            }
            case 'dense': {
              return `${theme.spacing(2)}px 0px`
            }
            default: {
              return `${theme.spacing(4)}px 0px`
            }
          }
        }
      },
      textAreaFieldInput: {
        boxShadow: [ 0, 3, 2, "#E9ECEF0D" ],
        border: [ 1, "solid", "#DEE2E6" ],
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        resize: "none",
        overflowX: 'hidden',
        ...theme.typography.input
      },
      textAreaFieldLabel: {
        color: "#596675",
        ...theme.typography.input
      },
      textAreaFieldChars: {
        color: "#596675",
        ...theme.typography.input,
      }
    }
  }
);
// </editor-fold>

const TextAreaField = (props: TextAreaFieldProps) => {
  const {
    id,
    name,
    label,
    value,
    margin = 'default',
    autoComplete,
    disabled = false,
    handleChange = () => console.debug("field value changed"),
    required = false,
    rows = 3,
    maxLength
  } = props
  const { textAreaFieldRoot, textAreaFieldLabel, textAreaFieldInput, textAreaFieldChars } = useStyles({disabled, margin})
  return (
    <div className={textAreaFieldRoot}>
      <label className={textAreaFieldLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
      <div style={{width: "100%", marginTop: 4}}>
        <textarea className={textAreaFieldInput} style={{width: "100%", boxSizing: "border-box"}} rows={rows} id={id} name={name} value={value} onChange={handleChange} autoComplete={autoComplete} maxLength={maxLength}/>
      </div>
      <div style={{width: "100%", display: "flex", justifyContent:"flex-end"}}>
        <label className={textAreaFieldChars}>{value.length}/{maxLength} merkkiä</label>
      </div>
    </div>
  );
};

export default TextAreaField;