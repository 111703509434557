import palette, { Palette } from "./palette";
import typography, { Typography } from "./typography";
import spacing, { Spacing } from "./spacing";
import breakpoints, { Breakpoints } from "./breakpoints";
import mixins, { Mixins } from "./mixins";

export interface Theme {
  palette: Palette;
  typography: Typography;
  spacing: Spacing;
  breakpoints: Breakpoints;
  mixins: Mixins;
}

export default {
  palette,
  typography,
  spacing,
  breakpoints,
  mixins
};
