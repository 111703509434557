import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import Button from "../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { handleChangeWorkspace, handleLogout } from "../../store/user/actions"

// <editor-fold desc="Admin type definitions ...">
type RuleNames = "adminRoot";

export interface AdminProps {
}

export type AdminStyleProps = {
};
// </editor-fold>

// <editor-fold desc="Admin style definition ...">
const useStyles = createUseStyles<RuleNames, AdminStyleProps, Theme>(
  (theme) => {
    return {
      adminRoot: {
        maxWidth: theme.breakpoints.sizes.sm,
        marginRight: "auto",
        marginLeft: "auto",
        flex: 1,
      }
    };
  }
);
// </editor-fold>

const Admin = (props: AdminProps) => {
  const { adminRoot } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedWorkspace = useAppSelector((state) => state.user.workspace);
  const myRoles = useAppSelector((state) => state.user.userData.data.organization_user_roles);

  useEffect(() => {
    document.title = "WTC Admin";
  }, [])

  return (
    <div className={adminRoot}>
      <PageTitle component="h2">
        Omat tiedot
      </PageTitle>
      {/**<div>
        <label htmlFor="userWorkspaces">Workspace:</label>
        <select
          name="userWorkspaces"
          id="userWorkspaces"
          value={selectedWorkspace}
          onChange={
            (e) =>
              dispatch(handleChangeWorkspace(parseInt(e.target.value)))
          }
        >
          {myRoles.map((organizationUserRole: any) => {
            const { organization: { name: organizationName, id: organizationId } } = organizationUserRole
            return (
              <option key={organizationId} value={organizationId}>{organizationName}</option>
            )
          })}
        </select>
        </div>*/}
      {/*
      <div style={{margin: '18px auto', maxWidth: 250}}>
      <Button color="blue" handleClick={() => {navigate("/my_user")}}>
        Omat tiedot
      </Button>
      </div>
      */}
      <div style={{ margin: '18px auto', maxWidth: 250 }}>
        <Button color="blue" handleClick={() => { navigate("/my_organization/details") }}>
          Organisaation tiedot
        </Button>
      </div>
      <div style={{ margin: '18px auto', maxWidth: 250 }}>
        <Button color="blue" handleClick={() => { navigate("/my_organization/service_card_list") }}>
          Palvelukortit
        </Button>
      </div>
      {/*
      <div style={{margin: '18px auto', maxWidth: 250}}>
      <Button color="blue" handleClick={() => {navigate("/my_organization/users")}}>
        Käyttäjät
      </Button>
      </div>
    */}
      <div style={{ margin: '18px auto', maxWidth: 250 }}>
        <Button
          color="orange"
          handleClick={() => {
            dispatch(handleLogout());
          }}
        >
          Kirjaudu ulos
        </Button>
      </div>
    </div>
  );
};

export default Admin;

