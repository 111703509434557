import React, { useEffect, useState, KeyboardEvent } from "react";
import { useSnackbar } from "notistack";
import { useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../hooks";
import { handleLogin } from "../../store/user/actions";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Buttons";
import Alert from "../../components/Alert";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import TextField from "../../components/Inputs/TextField";

// <editor-fold desc="Login type definitions ...">
type RuleNames = "loginRoot";

export interface LoginProps {
}

export type LoginStyleProps = {
};
// </editor-fold>

// <editor-fold desc="Login style definition ...">
const useStyles = createUseStyles<RuleNames, LoginStyleProps, Theme>(
  (theme) => {
    return {
      loginRoot: {
        maxWidth: theme.breakpoints.sizes.sm,
        marginRight: "auto",
        marginLeft: "auto",
        flex: 1,
      }
    };
  }
);
// </editor-fold>

const Login = (props: LoginProps) => {
  const location = useLocation();
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {loginRoot} = useStyles();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.token);

  useEffect(() => {
    document.title = "WTC Kirjautuminen";
  }, [])

  useEffect(() => {
    if(token?.error?.message) {
      enqueueSnackbar(token.error.message, {
        preventDuplicate: true,
        variant: "error"
      });
    }
  }, [token, enqueueSnackbar])

  const enterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter"){
      dispatch(handleLogin({username, password}))
    }
  }

  return (
    <div className={loginRoot}>
      <PageTitle component="h2">
        Kirjaudu
      </PageTitle>
      <form>
        <TextField
          id="login-username"
          name="username"
          label="Käyttäjätunnus"
          autoComplete="username"
          value={username}
          handleChange={e => setUsername(e.target.value)}
          onKeyDown={enterPress}
        />
        <TextField
          id="login-password"
          name="password"
          label="Salasana"
          autoComplete="current-password"
          type="password"
          value={password}
          handleChange={e => setPassword(e.target.value)}
          onKeyDown={enterPress}
        />
      </form>
      <div>
        <Button color="blue" handleClick={() => dispatch(handleLogin({username, password}))}>
          Kirjaudu
        </Button>
      </div>
      {token?.error.message ? (
        <Alert title="Virhe" message={token.error.message} />
      ) : null}
    </div>
  );
};

export default Login;
