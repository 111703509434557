import { Input } from "@mui/material";

type FontWeights =
  | "fontWeightLight"
  | "fontWeightRegular"
  | "fontWeightMedium"
  | "fontWeightBold";
type LineHeights = "normal";

interface BaseTypography {
  fontSize: string;
  fontWeight: FontWeights;
  lineHeight: LineHeights;
}

export interface Typography {
  htmlFontSize: 16;
  fontSize: 16;
  fontWeightLight: 300;
  fontWeightRegular: 400;
  fontWeightMedium: 500;
  fontWeightBold: 700;
  pxToRem: (px: number) => string;
  h1: BaseTypography;
  h2: BaseTypography;
  h3: BaseTypography;
  h4: BaseTypography;
  h5: BaseTypography;
  h6: BaseTypography;
  p: BaseTypography;
  label: BaseTypography;
  div: BaseTypography;
  a: BaseTypography;
  input: BaseTypography;
}

const htmlFontSize = 16;
const fontSize = 16;
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 600;

export const pxToRem = (px: number) => `${px / htmlFontSize}rem`;

export default{
  htmlFontSize,
  pxToRem,
  fontFamily: "'Open Sans', sans-serif",
  fontSize,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  // Main header
  h1: {
    fontSize: pxToRem(fontSize * 3 * 0.8),
    fontWeight: fontWeightLight,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  // Subheader
  h2: {
    fontSize: pxToRem(fontSize * 2.5 * 0.8),
    fontWeight: fontWeightLight,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  // small header
  h3: {
    fontSize: pxToRem(fontSize * 2 * 0.8),
    fontWeight: fontWeightBold,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  // table header
  h4: {
    fontSize: pxToRem(fontSize * 1.6 * 0.8),
    fontWeight: fontWeightBold,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  h5: {
    fontSize: pxToRem(fontSize * 1.2 * 0.8),
    fontWeight: fontWeightRegular,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  // text
  p: {
    fontSize: pxToRem(fontSize * 0.8),
    fontWeight: fontWeightRegular,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  //label
  label: {
    fontSize: pxToRem(fontSize * 0.8),
    fontWeight: fontWeightRegular,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  //div
  div: {
    fontSize: pxToRem(fontSize * 0.8),
    fontWeight: fontWeightRegular,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  //a
  a:{
    fontSize: pxToRem(fontSize * 0.8),
    fontWeight: fontWeightBold,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  },
  //input
  input: {
    fontSize: pxToRem(fontSize * 0.8),
    fontWeight: fontWeightRegular,
    lineHeight: "normal",
    margin: 0,
    fontFamily: "'Open Sans', sans-serif",
  }
};
