import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useResolvedPath, useMatch, useLocation } from "react-router-dom";
import theme, { Theme } from "../../styles/theme";
import { useAppDispatch, useAppSelector } from "../../hooks";
import wtcLogoBw from "../../assets/wtc_logo_bw.png"
import { handleLogout } from "../../store/user/actions";

// <editor-fold desc="ViewsRouter type definitions ...">
type RuleNames = "footerRoot" | "footerContent" | "footerImageContainer" | "footerLinkContainer" | "footerLink";

export interface FrontPageFooterProps {
}

export type FrontPageFooterStyleProps = {};

// <editor-fold desc="HeaderMenu style definition ...">
const useStyles = createUseStyles<RuleNames, FrontPageFooterStyleProps, Theme>(
    (theme) => {
        return {
            footerRoot: {
                paddingTop: "48px",
                width: "100%",
                height: "120px",
                backgroundColor: "#F8A25F",
                zIndex: 100
            },
            footerContent: {
                maxWidth: "1200px",
                margin: "auto",
                paddingRight: theme.spacing(4),
                display: "flex",
                justifyContent: "space-between",
                [theme.breakpoints.up('mobile')]: {
                    flexDirection: 'column'
                },
                [theme.breakpoints.up('tablet')]: {
                    paddingRight: theme.spacing(6),
                    paddingLeft: theme.spacing(2),
                    flexDirection: 'row'
                },
                [theme.breakpoints.up('desktop')]: {
                    paddingRight: theme.spacing(8),
                    paddingLeft: theme.spacing(4)
                },
            },
            footerImageContainer: {
                alignSelf: "center"
            },
            footerLinkContainer: {
                display: "flex",
                justifyContent: "end",
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                [theme.breakpoints.up('mobile')]: {
                    justifyContent: "center"
                },
            },
            footerLink: {
                ...theme.typography.a,
                color: "black"
            }
        };
    }
);

// </editor-fold>

const FrontPageFooter = (props: FrontPageFooterProps) => {
    const {
        footerRoot,
        footerContent,
        footerImageContainer,
        footerLinkContainer,
        footerLink
    } = useStyles()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedIn = useAppSelector((state) => state.user.loggedIn);
    return (
        <div className={footerRoot}>
            <div className={footerContent}>
                <div className={footerImageContainer}>
                    <img src={wtcLogoBw} alt="wtc logo bw" />
                </div>
                <div>
                    {loggedIn ?
                        (<div className={footerLinkContainer}>
                            <a className={footerLink} href="#" onClick={() => {
                                dispatch(handleLogout());
                            }}>Kirjaudu ulos</a>
                        </div>)
                        : (<div className={footerLinkContainer}>
                            <a className={footerLink} href="#" onClick={() => { navigate("/login", { state: { navigatedFrom: location.pathname } }) }}>Kirjaudu organisaatiokäyttäjänä</a>
                        </div>)}
                    <div className={footerLinkContainer}>
                        <a className={footerLink} href="#" onClick={() => window.open("https://wtc-turku.fi/yhteystiedot/", "_blank")}>Ota yhteys palveluntarjoajaan</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontPageFooter