import { height } from "@mui/system";
import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardBody type definitions ...">
type RuleNames = "modalBodyRoot";

export interface ModalBodyProps {
  children: React.ReactNode
}

export type ModalBodyStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardBody style definition ...">
const useStyles = createUseStyles<RuleNames, ModalBodyStyleProps, Theme>(
  (theme) => {
    return {
      modalBodyRoot: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(2),
        ...theme.typography.p
      },
    };
  }
);
// </editor-fold>

const ModalBody = (props: ModalBodyProps) => {
  const { children } = props
  const { modalBodyRoot } = useStyles()
  return (
    <div className={modalBodyRoot}>
      {children}
    </div>
  );
};

export default ModalBody;
