import React from "react";
import {createUseStyles} from "react-jss";
import {Theme} from "../../styles/theme";

// <editor-fold desc="CardTitle type definitions ...">
type RuleNames = "cardTitleRoot";

export interface CardTitleProps {
  children: React.ReactNode
}

export type CardTitleStyleProps = {};
// </editor-fold>

// <editor-fold desc="CardTitle style definition ...">
const useStyles = createUseStyles<RuleNames, CardTitleStyleProps, Theme>(
  (theme) => {
    return {
      cardTitleRoot: {
        ...theme.typography.h4,
        marginBottom: theme.spacing(4)
      },
    };
  }
);
// </editor-fold>

const CardTitle = (props: CardTitleProps) => {
  const { children } = props
  const { cardTitleRoot } = useStyles()
  return (
    <h4 className={cardTitleRoot}>
      {children}
    </h4>
  );
};

export default CardTitle;
