import {UserActionTypes} from "./types";
import {
  CLEAR_TOKEN,
  GET_TOKEN_ERROR,
  GET_TOKEN_PENDING,
  GET_TOKEN_SUCCESS,
  GET_USER_ERROR,
  GET_USER_PENDING,
  GET_USER_SUCCESS,
  SET_WORKSPACE,
} from "./actionTypes";
import {getApiBaseUrl, refreshTokenFetch} from "../../utils/functions";
import { useNavigate } from "react-router-dom";

type DispatchType = (args: UserActionTypes) => UserActionTypes;

interface LoginData {
  username: string;
  password: string;
}

// <editor-fold desc="User actions ...">
export const getUserPending = (): UserActionTypes => {
  return {
    type: GET_USER_PENDING,
  };
};
export const getUserSuccess = (user: any): UserActionTypes => {
  return {
    type: GET_USER_SUCCESS,
    user
  };
};
export const getUserError = (): UserActionTypes => {
  return {
    type: GET_USER_ERROR,
  };
};

export const handleGetMyUser = (accessToken: string): ((dispatch: DispatchType) => void) => {
  return async function (dispatch: DispatchType) {
    dispatch(getUserPending())
    const fetchUserResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/users/me/`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `jwt ${accessToken}`,
        },
      },
      dispatch
    )
    /* wait for the myUser response */
    const userResult = await fetchUserResult.json()
    /* Successful request on user data */
    if(fetchUserResult.ok) {
      dispatch(getUserSuccess(userResult))
    }
    /* Requesting user data has failed */
    else {
      dispatch(getUserError())
    }
  }
};
// </editor-fold>

// <editor-fold desc="Token actions ...">
export const getTokenPending = (): UserActionTypes => {
  return {
    type: GET_TOKEN_PENDING,
  };
};

export const getTokenSuccess = (token: {access: string; refresh: string}): UserActionTypes => {
  return {
    type: GET_TOKEN_SUCCESS,
    token
  };
};

export const getTokenError = (error: {data: any; message: string}): UserActionTypes => {
  return {
    type: GET_TOKEN_ERROR,
    error,
  };
};

export const handleLogin = (loginData: LoginData): ((dispatch: DispatchType) => void) => {
  return async function (dispatch: DispatchType) {
    dispatch(getTokenPending());
    /* Fetch token with the login credentials */
    const fetchLoginResult = await fetch(
      `${getApiBaseUrl()}/token/`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      }
    );
    /* wait for the login response */
    const loginResult = await fetchLoginResult.json()
    /* Login has been successful and token has been retrieved */
    if(fetchLoginResult.ok) {
      dispatch(getTokenSuccess(loginResult))
      /* If login has been successful and token has been retrieved, get the user */
      dispatch(getUserPending())
      const fetchUserResult = await fetch(
        `${getApiBaseUrl()}/users/me/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `jwt ${loginResult.access}`,
          },
        }
      )
      /* wait for the myUser response */
      const userResult = await fetchUserResult.json()
      /* Successful request on user data */
      if(fetchUserResult.ok) {
        dispatch(getUserSuccess(userResult))
      }
      /* Requesting user data has failed */
      else {
        dispatch(getUserError())
      }
    }
    /* Login has failed */
    else {
      /* Initialize error message with undefined error */
      let message = "Tuntematon virhe"
      /* Request is missing required properties */
      if(Object.keys(loginResult).some(resultKey => Object.keys(loginData).includes(resultKey))) {
        message = "Lomakkeella on virheitä"
      }
      /* No match with the login credentials */
      else if(loginResult.detail === "No active account found with the given credentials") {
        message = "Käyttäjätunnus tai salasana on väärin"
      }
      /* User account has not been activated yet */
      else if(loginResult.errorCode.includes("USER_NOT_ACTIVATED")) {
        message = "Käyttäjätunnusta ei ole vielä aktivoitu"
      }
      dispatch(getTokenError({data: loginResult, message: message}))
    }
  };
};

export const clearToken = (): UserActionTypes => {
  return {
    type: CLEAR_TOKEN,
  };
};

export const handleLogout = (): ((dispatch: DispatchType) => void) => {
  return function (dispatch: DispatchType) {
    dispatch(clearToken());
    window.location.href =  "/";
  };
};
// </editor-fold>

// <editor-fold desc="Workspace actions ...">
export const setWorkspace = (organizaitonId: number): UserActionTypes => {
  return {
    type: SET_WORKSPACE,
    workspace: organizaitonId,
  };
};

export const handleChangeWorkspace = (organizationId: number): ((dispatch: DispatchType) => void) => {
  return function (dispatch: DispatchType) {
    dispatch(setWorkspace(organizationId));
  };
};
// </editor-fold>


