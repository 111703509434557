import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useResolvedPath, useMatch, useLocation } from "react-router-dom";
import theme, { Theme } from "../../styles/theme";
import Button from "../Buttons";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useAppSelector } from "../../hooks";
import wtcLogo from "../../assets/wtc_logo.png"
import TextField from "../Inputs/TextField";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import MultipleSelectFilter, { Item } from "../Inputs/FilterSelect";
import { Tag } from "@mui/icons-material";
import { Enums, FilterStates } from "../../App";
import SelectField, { Option } from "../Inputs/SelectField";
import FilterSelect from "../Inputs/FilterSelect";
import { flexbox } from "@mui/system";

// <editor-fold desc="ViewsRouter type definitions ...">
type RuleNames = "headerMenuRoot" | "headerMenuContent" | "headerMenuTop" | "headerMenuBottom" | "filterMenuRoot" | "externalButtonRoot" | "externalLink" | "externalChoiceItem";

export interface HeaderMenuProps {
  searchBarState: string,
  setSearchBarState: Function,
  filterStates: FilterStates,
  setFilterStates: Function,
  searchBarSearchActive: boolean,
  setSearchBarSearchActive: Function,
  enums: Enums
}

export type HeaderMenuStyleProps = {};
// </editor-fold>

// <editor-fold desc="HeaderMenu style definition ...">
const useStyles = createUseStyles<RuleNames, HeaderMenuStyleProps, Theme>(
  (theme) => {
    return {
      headerMenuRoot: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 6px #00000029",
        zIndex: 100,
        paddingBottom: theme.spacing(4),
      },
      headerMenuContent: {
        maxWidth: theme.mixins.maxSize,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      },
      headerMenuTop: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.up('mobile')]: {
          flexDirection: 'column'
        },
        [theme.breakpoints.up('tablet')]: {
          paddingRight: theme.spacing(6),
          paddingLeft: theme.spacing(2),
          flexDirection: 'row'
        },
        [theme.breakpoints.up('desktop')]: {
          paddingRight: theme.spacing(8),
          paddingLeft: theme.spacing(4)
        }
      },
      headerMenuBottom: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.up('mobile')]: {
          flexDirection: 'column'
        },
        [theme.breakpoints.up('tablet')]: {
          paddingRight: theme.spacing(6),
          paddingLeft: theme.spacing(6),
          flexDirection: "row"
        },
        [theme.breakpoints.up('desktop')]: {
          paddingRight: theme.spacing(8),
          paddingLeft: theme.spacing(8)
        }
      },
      filterMenuRoot: {
        display: "flex",
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        justifyContent: "flex-start",
        [theme.breakpoints.up('mobile')]: {

        },
        [theme.breakpoints.up('tablet')]: {
          paddingRight: theme.spacing(6),
          paddingLeft: theme.spacing(6),
        },
        [theme.breakpoints.up('desktop')]: {
          paddingRight: theme.spacing(8),
          paddingLeft: theme.spacing(8),
        }
      },
      externalButtonRoot: {
        display: "flex",
        width: "fit-content",
        [theme.breakpoints.up('mobile')]: {
          display: "flex",
          width: '300px',
        },
        [theme.breakpoints.up('tablet')]: {
          display: "flex",
          width: "fit-content",
        }
      },
      externalChoiceItem: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(2),

      },
      externalLink: {
        ...theme.typography.a,
        color: "#4F89BC"
      }
    };
  }
);
// </editor-fold>

const HeaderMenu = (props: HeaderMenuProps) => {
  const {
    headerMenuRoot,
    headerMenuContent,
    headerMenuTop,
    headerMenuBottom,
    filterMenuRoot,
    externalButtonRoot,
    externalLink,
    externalChoiceItem
  } = useStyles()

  const {
    enums,
    searchBarState,
    setSearchBarState,
    filterStates,
    setFilterStates,
    searchBarSearchActive,
    setSearchBarSearchActive
  } = props

  const navigate = useNavigate();
  const location = useLocation();
  const resolved = useResolvedPath("/");
  const match = useMatch({ path: resolved.pathname, end: true });
  const loggedIn = useAppSelector((state) => state.user.loggedIn);

  const navigateToExternalPage = () => {
    window.open("https://google.com", "_blank")
  }

  const nullFilters = () => {
    setFilterStates({
      tags: "",
      themes: "",
      types: "",
      regions: ""
    })
  }

  return (
    <div className={headerMenuRoot}>
      <div className={headerMenuContent}>
        <div className={headerMenuTop}>
          <div style={{ alignSelf: "center", cursor: "pointer" }}>
            <img src={wtcLogo} alt="wtc logo" onClick={() => navigate("")} />
          </div>

          {match ? (
            <div style={{ width: "100%", maxWidth: 180, maxHeight: "100%", alignSelf: "center" }}>
              {loggedIn ? (
                <div>
                  <Button color="blue" handleClick={() => { navigate("/admin") }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1 }}>
                        Omat tiedot
                      </div>
                      <AccountBoxIcon></AccountBoxIcon>
                    </div>
                  </Button>
                </div>
              ) : (
                null
              )}

            </div>
          ) : (
            <div style={{ width: "100%", maxWidth: 180, maxHeight: "100%", alignSelf: "center" }}>
              {loggedIn ? (
                <div>
                  <Button color="blue" handleClick={() => { navigate("/admin") }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1 }}>
                        Omat tiedot
                      </div>
                      <AccountBoxIcon></AccountBoxIcon>
                    </div>
                  </Button>
                </div>
              ) : (
                null
              )}

            </div>
          )}
        </div>
        {match ? (
          <div className={headerMenuBottom}>
            <Stack direction="row" spacing={2}>
              <div style={{ width: "400px", minWidth: "200px" }}>
                <TextField
                  id="search-field"
                  name="search"
                  label=""
                  value={searchBarState || ""}
                  placeholder="Hae käyttäen hakusanaa"
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchBarState(event.target.value);
                    setSearchBarSearchActive(false);
                  }}
                  disabled
                />
              </div>
              <div style={{ alignSelf: "center" }}>
                {searchBarSearchActive ?
                  <Button color="orange" handleClick={() => {
                    setSearchBarSearchActive(false);
                    setSearchBarState("")
                  }}>
                    <div style={{ width: "fit-content" }}>Tyhjennä haku</div>
                  </Button>
                  :
                  <Button color="blue"
                    handleClick={() => setSearchBarSearchActive(true)}
                    disabled={
                      searchBarState.length <= 3
                    }>
                    <div style={{ width: "fit-content" }}>Hae tekstillä</div>
                  </Button>
                }
              </div>
            </Stack>
          </div>
        ) : null}
        {match ? (
          <div className={filterMenuRoot}>
            <div>
              <Grid container spacing={2} alignItems="center">
                <Grid item paddingTop={0}>
                  <FilterSelect
                    id="theme-filter"
                    value={filterStates.themes}
                    name="themes"
                    label="Teema"
                    defaultValueText="Kaikki"
                    options={
                      enums.themes.map((theme: { value: any; key: number; }) => {
                        const option: Option = {
                          id: theme.key,
                          name: theme.value,
                          value: theme.value
                        }
                        return (
                          option
                        )
                      })}
                    handleChange={(e) => {
                      if (e.target.value === "") {
                        setFilterStates((s: any) => ({ ...s, themes: "" }))
                      }
                      else {
                        setFilterStates((s: any) => ({ ...s, themes: e.target.value }))
                      }
                    }}
                  />
                </Grid>
                <Grid item paddingTop={0}>
                  <FilterSelect
                    id="type-filter"
                    value={filterStates.types}
                    name="types"
                    label="Tyyppi"
                    defaultValueText="Kaikki"
                    options={
                      enums.types.map((type: { value: any; key: number; }) => {
                        const option: Option = {
                          id: type.key,
                          name: type.value,
                          value: type.value
                        }
                        return (
                          option
                        )
                      })}
                    handleChange={(e) => {
                      if (e.target.value === "") {
                        setFilterStates((s: any) => ({ ...s, types: "" }))
                      }
                      else {
                        setFilterStates((s: any) => ({ ...s, types: e.target.value }))
                      }
                    }}
                  />
                </Grid>
                <Grid item paddingTop={0}>
                  <FilterSelect
                    id="region-filter"
                    value={filterStates.regions}
                    name="regions"
                    label="Alue"
                    defaultValueText="Kaikki"
                    options={
                      enums.regions.map((region: { value: any; key: number; }) => {
                        const option: Option = {
                          id: region.key,
                          name: region.value,
                          value: region.value
                        }
                        return (
                          option
                        )
                      })}
                    handleChange={(e) => {
                      if (e.target.value === "") {
                        setFilterStates((s: any) => ({ ...s, regions: "" }))
                      }
                      else {
                        setFilterStates((s: any) => ({ ...s, regions: e.target.value }))
                      }
                    }}
                  />
                </Grid>
                <Grid item paddingTop={0}>
                  <FilterSelect
                    id="tag-filter"
                    value={filterStates.tags}
                    name="tags"
                    label="Tagi"
                    defaultValueText="Kaikki"
                    options={
                      enums.tags.map((tag: { value: any; key: number; }) => {
                        const option: Option = {
                          id: tag.key,
                          name: tag.value,
                          value: tag.value
                        }
                        return (
                          option
                        )
                      })}
                    handleChange={(e) => {
                      if (e.target.value === "") {
                        setFilterStates((s: any) => ({ ...s, tags: "" }))
                      }
                      else {
                        setFilterStates((s: any) => ({ ...s, tags: e.target.value }))
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <div className={externalButtonRoot}>
                <div className={externalChoiceItem}>
                  <Button color="blue" handleClick={() => nullFilters()}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1 }}>
                        Tyhjennä suodattimet
                      </div>
                    </div>
                  </Button>
                </div>
                {/*<div className={externalChoiceItem}>
                    <a className={externalLink} href="#" onClick={navigateToExternalPage}>
                      TESTIIN
                    </a>
                  </div>*/}
              </div>
            </div>
          </div>
        ) : null}
      </div>

    </div>
  );

};

export default HeaderMenu;
