import React, { useEffect, useState } from "react";
import { createUseStyles, ThemeProvider } from "react-jss";
import { Theme } from "../../styles/theme";
import Card from "./Card";
import CardBody from "./CardBody";
import CardTitle from "./CardTitle";
import CardText from "./CardText";
import CardImg from "./CardImg";
import { getLimitedTextString } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { Enums } from "../../App";

// <editor-fold desc="ViewsRouter type definitions ...">
type RuleNames = "organizationCardRoot" | "organizationLogo" | "organizationTags";

export interface OrganizationCardProps {
  id?: number;
  title: string;
  description: string;
  imageUrl: string;
  logoUrl: string;
  organizationName: string;
  editable?: boolean;
  imageFile?: File;
  tags: any[];
  themes: any[];
  types: any[];
  regions: any[];
  enums: Enums;
  active: boolean | undefined;
  handleClick?: any;
}

export type OrganizationCardStyleProps = {};
// </editor-fold>

// <editor-fold desc="OrganizationCard style definition ...">
const useStyles = createUseStyles<RuleNames, OrganizationCardStyleProps, Theme>(
  (theme) => {
    return {
      organizationCardRoot: {
        minHeight: '450px',
        margin: 16,
        position: "relative",
      },
      organizationLogo: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 3px 6px #00000029",
        height: 100,
        maxWidth: 200,
        position: 'absolute'
      },
      organizationTags: {
        bottom: "16px",
        position: "absolute",
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(4),
      }
    };
  }
);
// </editor-fold>

function getEnumNames (enums: any[], keys: any[]) {
  return(enums.filter(i => keys.includes(i.key)))
}

const OrganizationCard = (props: OrganizationCardProps) => {
  const {
    id,
    title,
    description,
    imageUrl,
    logoUrl,
    organizationName,
    editable = true,
    tags,
    themes,
    types,
    regions,
    enums,
    handleClick,
    active
  } = props
  const { organizationCardRoot, organizationLogo, organizationTags } = useStyles()
  const [cardTags, setCardTags] = useState<any[]>([])
  const [cardThemes, setCardThemes] = useState<any[]>([])
  const [cardTypes, setCardTypes] = useState<any[]>([])
  const [cardRegions, setCardRegions] = useState<any[]>([])
  const navigate = useNavigate();

  useEffect(() => {
    setCardTags(getEnumNames(enums.tags, tags))
    setCardThemes(getEnumNames(enums.themes, themes))
    setCardTypes(getEnumNames(enums.types, types))
    setCardRegions(getEnumNames(enums.regions, regions))
    },
  [enums])

  return (
    <Card handleClick={handleClick} active={active}>
      {logoUrl && organizationName ? (
        <img className={organizationLogo} src={logoUrl} alt={`${organizationName}`} />
      ) : null}
      <CardImg src={imageUrl} alt={`${organizationName}`} />
      <CardBody>
        <CardTitle>
          {getLimitedTextString(title, 70)}
        </CardTitle>
        <CardText>
          {getLimitedTextString(description, 300)}
        </CardText>
      </CardBody>
      <div className={organizationTags}>
        <Chip
          {...undefined}
          label = {cardRegions[0]?.value}
          size = "small"
          style={{marginRight: "4px", marginTop: "4px"}}
        >
        </Chip>
        <Chip
          {...undefined}
          label = {cardThemes[0]?.value}
          size = "small"
          style={{marginRight: "4px", marginTop: "4px"}}
        >
        </Chip>
        <Chip
          {...undefined}
          label = {cardTypes[0]?.value}
          size = "small"
          style={{marginRight: "4px", marginTop: "4px"}}
        >
        </Chip>
      </div>
    </Card>
  );
};

export default OrganizationCard;
