import { Grid, Modal } from "@mui/material";

import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import Button from "../Buttons";
import ModalBody from "./ModalBody";
import ModalImg from "./ModalImg";
import ModalTitle from "./ModalTitle";
import wtcLogo from "../../assets/wtc_logo.png"

type RuleNames = string;

export interface WelcomeModalProps {
    setModalState?: Function
}

export type WelcomeModalStyleProps = {};

const useStyles = createUseStyles<RuleNames, WelcomeModalStyleProps, Theme>(
    (theme) => {
        return {
            welcomeModalRoot: {
                paddingTop: "32px",
                paddingBottom: "32px",
                overflow: "scroll",
                margin: "auto",
                display: "flex",
                position: "relative"
            },
            welcomeModalContent: {
                padding: "32px",
                backgroundColor: theme.palette.background.paper,
                width: "90%",
                maxWidth: "730px",
                display: "inline-block",
                margin: "auto",
                height: "fit-content",
                position: "relative"
            },
            confirmButtonContainer: {
                display: "flex",
                alignItems: "center",
                paddingTop: "16px",
                margin: "auto",
                width: "300px"
            }
        };
    }
);

const WelcomeModal = (props: WelcomeModalProps) => {
    const {
        setModalState,
    } = props

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false)
        localStorage.setItem('isVisited', 'true')
        if (setModalState) {
            setModalState(null)
        }
    };

    return (
        <Modal
            className={classes.welcomeModalRoot}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            disableAutoFocus={true}
        >
            <div className={classes.welcomeModalContent}>

                <ModalImg src={wtcLogo} alt={`WTC logo`}></ModalImg>
                <ModalTitle>
                    {'Tervetuloa WTC Turun palveluhakuun!'}
                </ModalTitle>
                <ModalBody>
                    {'Voit nyt luoda palvelukortteja yrityksesi tarjoamista palveluista. Luo yksi kortti aina yhtä palvelua kohti. Palvelut ovat haettavissa hakusanalla tai vaihtoehtoisesti suodattimia käyttämällä. Täytä siis tiedot tarkasti. Kerrothan meille palautetta ja kehitysehdotuksia, ja huomaathan, että palvelua käyttämällä suostut antamiesi tietojen tallennukseen.'}
                </ModalBody>
                <div className={classes.confirmButtonContainer}>
                    <Button color="blue" handleClick={handleClose}>Aloita palveluihin tutustuminen</Button>
                </div>
            </div>
        </Modal >
    )
}

export default WelcomeModal;