import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../styles/theme";

// <editor-fold desc="Paper type definitions ...">
type RuleNames = "paperRoot";

export interface PaperProps {
  children: React.ReactNode;
}

export type PaperStyleProps = {
};
// </editor-fold>

// <editor-fold desc="Paper style definition ...">
const useStyles = createUseStyles<RuleNames, PaperStyleProps, Theme>(
  (theme) => {
    return {
      paperRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 32px #8898AA26",
      },
    };
  }
);
// </editor-fold>

const Paper = (props: PaperProps) => {
  const {
    children,
  } = props
  const { paperRoot } = useStyles();
  return(
    <div className={paperRoot}>{children}</div>
  );
};

export default Paper;