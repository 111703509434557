import React from "react";
import {createUseStyles} from "react-jss";
import { Theme } from "../styles/theme";

// <editor-fold desc="PageTitle type definitions ...">
type RuleNames = "pageTitleRoot";
type AllowedComponents = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

export interface PageTitleProps {
  children: React.ReactNode;
  component: AllowedComponents;
  gutterBottom?: boolean;
}

export type PageTitleStyleProps = {
  component: AllowedComponents
  gutterBottom: boolean
};
// </editor-fold>

// <editor-fold desc="PageTitle style definition ...">
const useStyles = createUseStyles<RuleNames, PageTitleStyleProps, Theme>(
  (theme) => {
    return {
      pageTitleRoot: ({component, gutterBottom}) => {
        return {
          ...theme.typography[component],
          fontWeight: theme.typography.fontWeightRegular,
          paddingBottom: gutterBottom ? theme.spacing(2) : 0,
          textAlign: "center",
          [theme.breakpoints.up('tablet')]: {
            textAlign: "center",
            paddingBottom: gutterBottom ? theme.spacing(2) : 0
          },
          [theme.breakpoints.up('desktop')]: {
            textAlign: "center",
            paddingBottom: gutterBottom ? theme.spacing(2) : 0
          },
        }},
    };
  }
);
// </editor-fold>

const PageTitle = (props: PageTitleProps) => {
  const { children, component, gutterBottom = false} = props
  const { pageTitleRoot } = useStyles({component, gutterBottom})
  return React.createElement(
    component,
    {
      className: pageTitleRoot,
    },
    [children]
  );
};

export default PageTitle;
