import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addZeroFormatToString, datesAreOnSameDay, formatDateForApi, getApiBaseUrl, getEnumNames, refreshTokenFetch } from "../../utils/functions";
import Alert from "../../components/Alert";
import Button from "../../components/Buttons";
import TextField from "../../components/Inputs/TextField";
import TextAreaField from "../../components/Inputs/TextAreaField";
import Paper from "../../components/Paper";
import ModalTitle from "../../components/CardModal/ModalTitle";
import ModalOrganization from "../../components/CardModal/ModalOrganization";
import ModalTags from "../../components/CardModal/ModalTags";
import ModalBody from "../../components/CardModal/ModalBody";
import ModalImg from "../../components/CardModal/ModalImg";
import CardBody from "../../components/Cards/CardBody";
import ServiceCardImg from "../../components/ServiceCardInfo/ServiceCardInfoImg";
import { Enums } from "../../App";
import SelectField, { Option } from "../../components/Inputs/SelectField";
import Calendar from "react-calendar";
import '../../styles/Calendar.css';
import FilterSelect from "../../components/Inputs/FilterSelect";
import { useSnackbar } from "notistack";

// <editor-fold desc="ServiceCardInfo type definitions ...">
type RuleNames = "serviceCardInfoLoadingRoot"
    | "serviceCardInfoErrorRoot"
    | "serviceCardRoot"
    | "serviceCardInfoContainer"
    | "contactFormContainer"
    | "serviceCardOrganizationLogo"
    | "backButtonRoot"
    | "calendarRoot"
    | "timeIndicatorDot"
    | "contactFormHeader"
    | "alertTextLabel"

interface SelectedServiceCard {
    id?: number;
    title?: string;
    contact_email?: string;
    contact_time?: string[];
    description?: string;
    is_active?: boolean;
    image?: string;
    organization?: {
        name?: string;
        logo?: string;
        image?: string;
        id?: number;
        url?: string;
    };
    enums?: Enums
    tags?: string[];
    themes?: string[];
    types?: string[];
    regions?: string[];
}

export interface ServiceCardInfoProps {
    enums: Enums;
}

export type ServiceCardInfoStyleProps = {
};
// </editor-fold>

// <editor-fold desc="EditServiceCard style definition ...">
const useStyles = createUseStyles<RuleNames, ServiceCardInfoStyleProps, Theme>(
    (theme) => {
        return {
            serviceCardInfoLoadingRoot: {
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
            },
            serviceCardInfoErrorRoot: {
                margin: "auto",
            },
            serviceCardRoot: {
                position: "relative",
                marginTop: theme.spacing(8),
                display: 'grid',
                width: '100%',
                gridTemplateRows: "auto",
                gridTemplateColumns: "1fr 1fr ",
                columnGap: theme.spacing(4),
                rowGap: theme.spacing(8),
                gridTemplateAreas: `"info form"`,
                [theme.breakpoints.up('mobile')]: {
                    columnGap: theme.spacing(4),
                    rowGap: theme.spacing(4),
                    gridTemplateColumns: "1fr",
                    gridTemplateAreas:
                        `"info" 
                    "form "`,
                },
                [theme.breakpoints.up('tablet')]: {
                    columnGap: theme.spacing(1),
                    rowGap: theme.spacing(1),
                    gridTemplateColumns: "1fr",
                    gridTemplateAreas:
                        `"info" 
                    "form "`,
                },
                [theme.breakpoints.up('desktop')]: {
                    columnGap: theme.spacing(8),
                    rowGap: theme.spacing(8),
                    gridTemplateColumns: "1fr 1fr ",
                    gridTemplateAreas: `"info form"`,
                },
            },

            serviceCardInfoContainer: {
                position: "relative",
                gridArea: "info",
                padding: [0, theme.spacing(4)],
                [theme.breakpoints.up('tablet')]: {
                    padding: [0, 0, 0, theme.spacing(6)],
                },
                [theme.breakpoints.up('desktop')]: {
                    padding: [0, 0, theme.spacing(8), theme.spacing(8)],
                },
            },

            contactFormContainer: {
                position: "relative",
                gridArea: "form",
                padding: [0, theme.spacing(4), 0, theme.spacing(4)],
                [theme.breakpoints.up('mobile')]: {
                    maxWidth: "85%"
                },
                [theme.breakpoints.up('tablet')]: {
                    maxWidth: "100%",
                    margin: 0,
                    padding: [0, theme.spacing(6), theme.spacing(6), theme.spacing(6)],
                },
                [theme.breakpoints.up('desktop')]: {
                    maxWidth: "100%",
                    margin: 0,
                    padding: [0, theme.spacing(8), theme.spacing(8), 0],
                },
            },
            contactFormHeader: {
                ...theme.typography.h4
            },
            serviceCardOrganizationLogo: {
                backgroundColor: theme.palette.background.paper,
                boxShadow: "0px 3px 6px #00000029",
                height: 100,
                maxWidth: 200,
                position: 'absolute',
                top: "20px",
                left: "50px",
                zIndex: 5000
            },
            backButtonRoot: {
                maxWidth: "120px",
                position: "absolute",
                right: "32px"
            },
            calendarRoot: {
                border: "1px solid #DEE2E6",
                borderRadius: theme.spacing(1),
                boxShadow: "0px 0px 32px #8898AA26"
            },
            timeIndicatorDot: {
                position: "relative",
                class: "dot",
                height: "6px",
                width: "6px",
                backgroundColor: "#FA0000",
                borderRadius: "50%",
                display: "inline-block",
                bottom: "2.5px",
                left: "2px"
            },
            alertTextLabel: {
                color: theme.palette.error.main,
                ...theme.typography.input
            }
        };
    }
);
// </editor-fold>

const ServiceCardInfo = (props: ServiceCardInfoProps) => {
    const {
        enums
    } = props
    const {
        serviceCardInfoLoadingRoot,
        serviceCardInfoErrorRoot,
        serviceCardRoot,
        serviceCardInfoContainer,
        contactFormContainer,
        serviceCardOrganizationLogo,
        backButtonRoot,
        calendarRoot,
        timeIndicatorDot,
        contactFormHeader,
        alertTextLabel
    } = useStyles();

    const { serviceCardId } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [serviceCardContactTimes, setServiceCardContactTimes] = useState<any[] | undefined>(undefined)
    const [newContactTime, setNewContactTime] = useState({
        name: "",
        email: "",
        phone: "",
        corporation: "",
        additional_info: "",
        chosen_call_time_id: ""
    })
    const [selectedServiceCard, setSelectedServiceCard] = useState<{
        loading: boolean;
        data?: SelectedServiceCard;
        error: string;
    }>({
        loading: true,
        data: undefined,
        error: ""
    })
    const [selectedContactTimeDate, setSelectedContactTimeDate] = useState<Date>(new Date());
    const element = "service-card-info"
    const contactForm = `${element}-contact-form`

    const contactTimeInformationMissing =
        newContactTime.name === "" ||
        newContactTime.email === "" ||
        newContactTime.phone === "" ||
        newContactTime.corporation === "" ||
        newContactTime.chosen_call_time_id === ""

    const [phoneValidated, setPhoneValidated] = useState(true)

    const handleGetServiceCard = async () => {
        setSelectedServiceCard(s => ({ ...s, loading: true, error: "" }))
        const fetchServiceCardResult = await refreshTokenFetch(
            `${getApiBaseUrl()}/public_organization_service_cards/${serviceCardId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            dispatch
        )
        /* wait for the myOrganizationServiceCard response */
        const ServiceCardResult = await fetchServiceCardResult.json()
        /* Successful request on myOrganizationServiceCard data */
        if (fetchServiceCardResult.ok) {
            setSelectedServiceCard(s => ({ ...s, loading: false, data: ServiceCardResult }));
        }
        /* Requesting myOrganizationServiceCard data has failed */
        else {
            console.debug("test", ServiceCardResult)
            let message = "Tuntematon virhe";
            /* Missing required organization parameter from request */
            if (Array.isArray(ServiceCardResult) && ServiceCardResult.includes("Missing required organization parameter")) {
                message = "Puuttuva parametri organization"
            }
            /* Invalid OrganizationUserRole for the requested organization */
            else if (Array.isArray(ServiceCardResult) && ServiceCardResult.includes("User role not exists in requested organization")) {
                message = "Organisaatiossa ei ole voimassaolevaa organisaatioroolia"
            }
            /* Object not found*/
            else if (ServiceCardResult?.detail === "Not found.") {
                message = "Palvelukorttia ei löydy"
            }
            setSelectedServiceCard(s => ({ ...s, loading: false, error: message }))
        }
    }

    // Get future contact times
    const handleGetServiceCardContactTimes = async (id: number | string) => {
        const fetchServiceCardContactTimes = await refreshTokenFetch(
            `${getApiBaseUrl()}/events/?service_card=${id}&start_time__gte=${formatDateForApi(new Date())}&reserved=False`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            dispatch
        )
        const serviceCardContactTimesResult = await fetchServiceCardContactTimes.json()
        if (fetchServiceCardContactTimes.ok) {
            setServiceCardContactTimes(serviceCardContactTimesResult)
        }
        else {
            console.log("fetch", "error")
        }
    }

    const handleSendContactRequest = async () => {
        if (selectedServiceCard.data?.organization?.id != undefined) {
            const formData = new FormData();
            const organizationId = selectedServiceCard.data?.organization?.id.toString()

            for (const [key, value] of Object.entries(newContactTime)) {
                formData.append(key, value)

            }
            const sendContactRequestResult = await fetch(
                `${getApiBaseUrl()}/my_organization_service_cards/${serviceCardId}/send_contact_request_email/?organization=${organizationId}`,
                {
                    method: 'POST',
                    body: formData
                }
            ).then(response => {
                const data = new FormData()
                data.append('reserved', 'True')
                fetch(
                    `${getApiBaseUrl()}/events/${newContactTime.chosen_call_time_id}/`,
                    {
                        method: 'PATCH',
                        body: data
                    }
                )
                return response
            })
            if (sendContactRequestResult.status === 200) {
                enqueueSnackbar("Yhteydenottopyyntö lähetetty, saat pian sähköpostiisi vahvistuksen.", {
                    preventDuplicate: true,
                    variant: "success"
                });
                setNewContactTime({
                    name: "",
                    email: "",
                    phone: "",
                    corporation: "",
                    additional_info: "",
                    chosen_call_time_id: ""
                })
                if (serviceCardId) {
                    handleGetServiceCardContactTimes(serviceCardId)
                }
            }
            else {
                console.debug("post", "error")
                enqueueSnackbar("Yhteydenottopyynnön lähetys epäonnistui, tarkista täyttämäsi tiedot ja yritä uudelleen", {
                    preventDuplicate: true,
                    variant: "error"
                });
            }
        }

    }


    useEffect(() => {
        if (serviceCardId) {
            handleGetServiceCardContactTimes(serviceCardId).catch(e => {
                enqueueSnackbar("Kontaktiaikojen haku epäonnistui", {
                    preventDuplicate: true,
                    variant: "error"
                });
            })
            handleGetServiceCard().catch(e => {
                setSelectedServiceCard(s => ({ ...s, loading: false, error: "Palveluun ei juuri nyt saada yhteyttä, yritä myöhemmin uudelleen" }))
            })

        }
    }, [serviceCardId])

    if (selectedServiceCard.loading) {
        return (
            <Paper>
                <div className={serviceCardInfoLoadingRoot}>
                    <PageTitle component="h2">
                        {`Ladataan palvelukortin tietoja...`}
                    </PageTitle>
                </div>
            </Paper>
        )
    }

    if (selectedServiceCard?.error) {
        return (
            <div className={serviceCardInfoErrorRoot}>
                <Alert title="Virhe" message={selectedServiceCard.error} />
            </div>
        )
    }

    const phoneValidator = (phone: string | undefined) => {
        const regex = new RegExp(/^\+.\d{8,15}$/);
        if (phone) {
            if (regex.test(phone)) {
                setPhoneValidated(true)
            } else {
                setPhoneValidated(false)
            }
        }
    }

    return (
        <Paper>
            <div className={serviceCardRoot}>
                <div id={`${element}-container`} className={serviceCardInfoContainer}>
                    {selectedServiceCard.data?.organization?.logo && selectedServiceCard.data?.organization.name ? (
                        <img className={serviceCardOrganizationLogo} src={selectedServiceCard.data?.organization.logo} alt={`${selectedServiceCard.data?.organization.name}`} />
                    ) : null}
                    <div>
                        <ServiceCardImg src={selectedServiceCard.data?.image} alt={`${selectedServiceCard.data?.organization?.name}`}></ServiceCardImg>
                    </div>
                    <ModalTitle>{selectedServiceCard.data?.title}</ModalTitle>
                    <ModalOrganization>
                        {<a href={selectedServiceCard.data?.organization?.url} target={"_blank"} rel="noopener noreferrer">{selectedServiceCard.data?.organization?.name}</a>}
                    </ModalOrganization>
                    <ModalTags title="Toimialueet" tags={getEnumNames(enums.regions, selectedServiceCard.data?.regions).map(t => t.value)}></ModalTags>
                    <ModalTags title="Teemat" tags={getEnumNames(enums.themes, selectedServiceCard.data?.themes).map(t => t.value)}></ModalTags>
                    <ModalTags title="Tyypit" tags={getEnumNames(enums.types, selectedServiceCard.data?.types).map(t => t.value)}></ModalTags>
                    <ModalTags title="Tagit" tags={getEnumNames(enums.tags, selectedServiceCard.data?.tags).map(t => t.value)}></ModalTags>
                    <CardBody>
                        {selectedServiceCard.data?.description}
                    </CardBody>
                </div>
                <div id={`${contactForm}-container`} className={contactFormContainer}>
                    <h2 className={contactFormHeader}>Ota yhteyttä</h2>
                    <form>
                        <TextField
                            id={`${contactForm}-name`}
                            name="name"
                            label="Nimi"
                            value={newContactTime?.name || ""}
                            handleChange={(e) => setNewContactTime(s => ({ ...s, name: e.target.value }))}
                            required={true}
                        />
                        <TextField
                            id={`${contactForm}-email`}
                            name="email"
                            type="email"
                            label="Sähköposti"
                            value={newContactTime?.email || ""}
                            handleChange={(e) => setNewContactTime(s => ({ ...s, email: e.target.value }))}
                            required={true}
                        />
                        <TextField
                            id={`${contactForm}-phone`}
                            name="phone"
                            label="Puhelinnumero"
                            type="tel"
                            value={newContactTime?.phone || ""}
                            handleChange={(e) => setNewContactTime(s => ({ ...s, phone: e.target.value }))}
                            required={true}
                            placeholder={"+358"}
                            onBlur={(e) => phoneValidator(newContactTime?.phone)}
                            maxLength={16}
                            alertText={"Syötä puhelinnumero kansainvälisessä muodossa!"}
                            showAlertText={!phoneValidated}
                        />
                        <TextField
                            id={`${contactForm}-corporation`}
                            name="corporation"
                            label="Yritys"
                            value={newContactTime?.corporation || ""}
                            handleChange={(e) => setNewContactTime(s => ({ ...s, corporation: e.target.value }))}
                            required={true}
                        />
                        <TextAreaField
                            id={`${contactForm}-description`}
                            name="additional-info"
                            label="Lisätiedot"
                            value={newContactTime?.additional_info || ""}
                            required={false}
                            rows={8}
                            handleChange={(e) => setNewContactTime(s => ({ ...s, additional_info: e.target.value }))}
                            maxLength={300}
                        />
                        <Calendar
                            minDetail="year"
                            className={calendarRoot}
                            value={selectedContactTimeDate}
                            onChange={setSelectedContactTimeDate}
                            minDate={new Date()}
                            tileContent={({ date, view }) => {
                                const timeList = serviceCardContactTimes?.map(t => (t.start_time))
                                if (view === 'month' && timeList != undefined) {
                                    if (timeList.find(dDate => datesAreOnSameDay(dDate, date))) {
                                        return (<span className={timeIndicatorDot}></span>)
                                    }
                                    else {
                                        return null
                                    }
                                }
                                else {
                                    return null
                                }
                            }}
                        />
                        {serviceCardContactTimes?.length === 0 ?
                            <label className={alertTextLabel}>Vapaita aikoja ei ole tällä hetkellä saatavilla!</label> 
                        : null}
                        <FilterSelect
                            id={`${contactForm}-contact-time`}
                            name="contact-time"
                            label="Yhteydenottoaika (valitse päivämäärä kalenterista)"
                            value={newContactTime?.chosen_call_time_id}
                            handleChange={(e) => setNewContactTime(s => ({ ...s, chosen_call_time_id: e.target.value }))}
                            required
                            defaultValueText="Valitse yhteydenottoaika"
                            options={serviceCardContactTimes?.filter(
                                time => {
                                    const t = new Date(time.start_time)
                                    if (t.getFullYear() === selectedContactTimeDate.getFullYear() && t.getMonth() === selectedContactTimeDate.getMonth() && t.getDate() === selectedContactTimeDate.getDate()) {
                                        return true
                                    }
                                }).map((time: { start_time: string; end_time: string, id: number }) => {
                                    const startTime = new Date(time.start_time)
                                    const endTime = new Date(time.end_time)
                                    var startHours = addZeroFormatToString(startTime.getHours())
                                    var startMins = addZeroFormatToString(startTime.getMinutes())
                                    var endHours = addZeroFormatToString(endTime.getHours())
                                    var endMins = addZeroFormatToString(endTime.getMinutes())

                                    const option: Option = {
                                        id: time.id,
                                        name: `${startHours}:${startMins} - ${endHours}:${endMins}`,
                                        value: time.id
                                    }
                                    return (option)
                                }) || []}
                        />

                        <Button color="blue" disabled={contactTimeInformationMissing || !phoneValidated} handleClick={() => handleSendContactRequest()}>
                            Lähetä yhteydenottopyyntö
                        </Button>
                    </form>
                </div>
                <div className={backButtonRoot}>
                    <Button color="orange" handleClick={() => navigate("/", { replace: true })}>Etusivulle</Button>
                </div>
            </div>
        </Paper>
    );
};

export default ServiceCardInfo;