import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route, Outlet, useResolvedPath, useMatch, useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { Theme } from "../styles/theme";
import {
  Home,
  Login,
  Admin,
  MyUser,
  MyOrganization,
  MyOrganizationServiceCardList,
  MyOrganizationUserList,
  NewServiceCard,
  EditServiceCard,
  Register
} from "../views";
import { useAppSelector } from "../hooks";
import ServiceCardInfo from "./ServiceCardInfo";
import { getApiBaseUrl } from "../utils/functions";
import { Enums, FilterStates } from "../App";
import FrontPageFooter from "../components/FrontPageFooter";

// <editor-fold desc="ViewsRouter type definitions ...">
type RuleNames = "layoutContainer";

export interface ViewsRouterProps {
  searchBarState: string;
  searchBarSearchActive: boolean;
  filterStates: FilterStates;
  enums: Enums;
};

export type ViewsRouterStyleProps = {

};

// </editor-fold>

// <editor-fold desc="SideNavItem style definition ...">
const useStyles = createUseStyles<RuleNames, ViewsRouterStyleProps, Theme>(
  (theme) => {
    return {
      layoutContainer: {
        boxSizing: "border-box",
        maxWidth: theme.mixins.maxSize,
        minWidth: "350px",
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        flex: 1,
        [theme.breakpoints.up('mobile')]: {
          width: "fit-content"
        },
        [theme.breakpoints.up('tablet')]: {
          padding: theme.spacing(6),
          width: "fit-content"
        },
        [theme.breakpoints.up('desktop')]: {
          padding: theme.spacing(8),
          width: "1300px"
        },
      },
    };
  }
);
// </editor-fold>

const Layout = () => {
  const { layoutContainer } = useStyles();

  return (
    <div className={layoutContainer}>
      <Outlet />
    </div>
  );
};

// <editor-fold desc="ViewsRouter component definition ...">
const ViewsRouter = (props: ViewsRouterProps): JSX.Element => {
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const navigate = useNavigate();
  const loginRoute = useResolvedPath("/login");
  const matchLoginRoute = useMatch({ path: loginRoute.pathname, end: true });
  const registerRoute = useResolvedPath("/register");
  const matchRegisterRoute = useMatch({ path: registerRoute.pathname, end: true });
  const {
    enums
  } = props

  useEffect(() => {
    if (loggedIn && (matchLoginRoute || matchRegisterRoute)) {
      navigate(("/"))
    }
  }, [loggedIn, matchLoginRoute, matchRegisterRoute, navigate])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <Home
              searchBarState={props.searchBarState}
              searchBarSearchActive={props.searchBarSearchActive}
              filterStates={props.filterStates}
              enums={enums}
            />
          }
        />
        <Route path={"service_card/:serviceCardId/info"} element={<ServiceCardInfo enums={enums} />} />
        {loggedIn
          ? (
            <Fragment>
              <Route path={"admin"} element={<Admin />} />
              <Route path={"my_user"} element={<MyUser />} />
              <Route path={"my_organization"}>
                <Route
                  path={"details"}
                  element={<MyOrganization enums={enums} />}
                />
                <Route
                  path={"service_card_list"}
                  element={<MyOrganizationServiceCardList enums={enums} />}
                />
                <Route path={"users"} element={<MyOrganizationUserList />} />
                <Route path={"service_card"}>
                  <Route
                    path={"new"}
                    element={<NewServiceCard enums={enums} />}
                  />
                  <Route
                    path={":serviceCardId"}
                    element={<EditServiceCard enums={enums} />}
                  />
                </Route>
              </Route>
            </Fragment>
          ) : (
            <Fragment>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Fragment>
          )
        }
        <Route path="*" element={<div>404 Not Found</div>} />
      </Route>
    </Routes>
  );
};
// </editor-fold>

export default ViewsRouter;