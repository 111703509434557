import React from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";

// <editor-fold desc="TextField type definitions ...">
type RuleNames = 'textFieldRoot' | 'textFieldLabel' | 'textFieldInput' | 'alertTextLabel' | 'textAreaChars'

export interface TextFieldProps {
  id: string;
  name: string;
  label: string;
  value: string;
  placeholder?: string;
  autoComplete?: string;
  margin?: 'default' | 'dense' | 'none';
  type?: 'text' | 'password' | 'email' | 'tel' | 'url';
  pattern?: string;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  alertText?: string
  showAlertText?: boolean;
  required?: boolean;
  maxLength?: number;
  showMaxLength?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void | Function
}

export type TextFieldStyleProps = {
  disabled: boolean;
  margin: 'default' | 'dense' | 'none';
};
// </editor-fold>

// <editor-fold desc="TextField style definition ...">
const useStyles = createUseStyles<RuleNames, TextFieldStyleProps, Theme>(
  (theme) => {
    return {
      textFieldRoot: {
        margin: ({ margin }) => {
          switch (margin) {
            case 'none': {
              return 0
            }
            case 'dense': {
              return `${theme.spacing(2)}px 0px`
            }
            default: {
              return `${theme.spacing(4)}px 0px`
            }
          }
        }
      },
      textFieldInput: {
        boxShadow: [0, 3, 2, "#E9ECEF0D"],
        border: [1, "solid", "#DEE2E6"],
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        ...theme.typography.input,
      },
      textFieldLabel: {
        color: "#596675",
        ...theme.typography.input
      },
      alertTextLabel: {
        color: theme.palette.error.main,
        ...theme.typography.input
      },
      textAreaChars: {
        color: "#596675",
        ...theme.typography.input,
      }
    }
  }
);
// </editor-fold>

const TextField = (props: TextFieldProps) => {
  const {
    id,
    name,
    label,
    value,
    placeholder,
    margin = 'default',
    autoComplete,
    type = 'text',
    disabled = false,
    handleChange = () => console.debug("field value changed"),
    onKeyDown = () => console.debug("Key pressed"),
    onBlur = () => console.debug("on blur"),
    required = false,
    pattern,
    maxLength,
    showAlertText = false,
    alertText,
    showMaxLength = false
  } = props
  const { textFieldRoot, textFieldLabel, textFieldInput, alertTextLabel, textAreaChars } = useStyles({ disabled, margin })
  return (
    <div className={textFieldRoot}>
      <label className={textFieldLabel} htmlFor={id}>{`${label}${required ? '*' : ''}`}</label>
      <div style={{ width: "100%", marginTop: 4 }}>
        <input className={textFieldInput} style={{ width: "100%", boxSizing: "border-box" }} type={type} id={id} name={name} value={value} placeholder={placeholder} onBlur={onBlur} onChange={handleChange} autoComplete={autoComplete} pattern={pattern} maxLength={maxLength} onKeyDown={onKeyDown} />
      </div>
      {showMaxLength ?
        <div style={{width: "100%", display: "flex", justifyContent:"flex-end"}}>
          <label className={textAreaChars}>{value.length}/{maxLength} merkkiä</label>
        </div>
        : null}
      {showAlertText ?
        <label className={alertTextLabel} htmlFor={id}>{alertText}</label>
        : null}
    </div>
  );
};

export default TextField;

