import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {getApiBaseUrl, refreshTokenFetch} from "../../utils/functions";
import Alert from "../../components/Alert";

// <editor-fold desc="MyOrganizationUserList type definitions ...">
type RuleNames = "myOrganizationUserListRoot";

interface SelectedOrganizationUser {
  id: number,
  user: {
    first_name: string,
    last_name: string,
    email: string,
  }
}

export interface MyOrganizationUserListProps {
}

export type MyOrganizationUserListStyleProps = {
};
// </editor-fold>

// <editor-fold desc="MyOrganizationUserList style definition ...">
const useStyles = createUseStyles<RuleNames, MyOrganizationUserListStyleProps, Theme>(
  (theme) => {
    return {
      myOrganizationUserListRoot: {
        maxWidth: theme.breakpoints.sizes.sm,
        margin: "auto",
        flex: 1,
      }
    };
  }
);
// </editor-fold>

const MyOrganizationUserList = (props: MyOrganizationUserListProps) => {
  const {myOrganizationUserListRoot} = useStyles();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.user.token.data.access);
  const selectedWorkspace = useAppSelector((state) => state.user.workspace);
  const myRoles = useAppSelector((state) => state.user.userData.data.organization_user_roles);
  const myWorkspaceOrganization = myRoles.find((organizationUserRole: any) => organizationUserRole?.organization?.id === selectedWorkspace)?.organization
  const [selectedOrganizationUsers, setSelectedOrganizationUsers] = useState<{
    loading: boolean;
    data?: SelectedOrganizationUser[];
    error: string;
  }>({
    loading: false,
    data: undefined,
    error: ""
  })

  const handleGetMyOrganizationUsers = async () => {
    setSelectedOrganizationUsers(s => ({...s, loading: true, error: ""}))
    const fetchMyOrganizationUsersResult = await refreshTokenFetch(
      `${getApiBaseUrl()}/my_organization_users/?organization=${selectedWorkspace}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `jwt ${accessToken}`,
        },
      },
      dispatch
    )
    /* wait for the myOrganizationUsers response */
    const myOrganizationUsersResult = await fetchMyOrganizationUsersResult.json()
    /* Successful request on myOrganizationUsers data */
    if (fetchMyOrganizationUsersResult.ok) {
      setSelectedOrganizationUsers(s => ({...s, loading: false, data: myOrganizationUsersResult}))
    }
    /* Requesting myOrganizationUsers data has failed */
    else {
      let message = "Tuntematon virhe";
      /* Missing required organization parameter from request */
      if(Array.isArray(myOrganizationUsersResult) && myOrganizationUsersResult.includes("Missing required organization parameter")) {
        message = "Puuttuva parametri organization"
      }
      /* Invalid OrganizationUserRole for the requested organization */
      else if(Array.isArray(myOrganizationUsersResult) && myOrganizationUsersResult.includes("User role not exists in requested organization")) {
        message = "Organisaatiossa ei ole voimassaolevaa organisaatioroolia"
      }
      setSelectedOrganizationUsers(s => ({...s, loading: false, error: message}))
    }
  }

  useEffect(() => {
    document.title = `${myWorkspaceOrganization?.name || "Organisaation"} käyttäjät`;
  }, [myWorkspaceOrganization])

  useEffect(() => {
    if(selectedWorkspace) {
      handleGetMyOrganizationUsers()
    }
  }, [selectedWorkspace])

  if(selectedOrganizationUsers.loading) {
    return(
      <div className={myOrganizationUserListRoot}>
        <PageTitle component="h2">
          {`Ladataan ${myWorkspaceOrganization?.name || "organisaation"} käyttäjiä...`}
        </PageTitle>
      </div>
    )
  }

  if(selectedOrganizationUsers?.error) {
    return(
      <div className={myOrganizationUserListRoot}>
        <Alert title="Virhe" message={selectedOrganizationUsers.error} />
      </div>
    )
  }

  return (
    <div className={myOrganizationUserListRoot}>
      <PageTitle component="h2">
        {`${myWorkspaceOrganization?.name || "Organisaation"} käyttäjät`}
      </PageTitle>
      {selectedOrganizationUsers?.data?.length === 1 ? (
        <p>{`Organisaatiolla on 1 käyttäjä`}</p>
      ) : (
        <p>{`Organisaatiolla on ${selectedOrganizationUsers?.data?.length} käyttäjää`}</p>
      )}
      {selectedOrganizationUsers?.data?.map((organizationUser, index) => {
        return(
          <p key={organizationUser.id}>{`${index + 1}: ${organizationUser?.user?.first_name || "-"} ${organizationUser?.user?.last_name || "-"} - ${organizationUser?.user?.email || "-"}`}</p>
        )
      })}
    </div>
  );
};

export default MyOrganizationUserList;
