import React from "react";
import { createUseStyles } from "react-jss";
import DeleteIcon from '@mui/icons-material/Delete';
import { Theme } from "../styles/theme";

// <editor-fold desc="Chip type definitions ...">
type RuleNames = "chipRoot" | "chipTextContainer" | "chipIconContainer";

export interface ChipProps {
  disabled?: boolean;
  handleClick?: (args?: any) => void
  handleClose?: (args?: any) => void
  name: string;
}

export type ChipStyleProps = {
  disabled: boolean;
};
// </editor-fold>

// <editor-fold desc="Button style definition ...">
const useStyles = createUseStyles<RuleNames, ChipStyleProps, Theme>(
  (theme) => {
    return {
      chipRoot: {
        ...theme.typography.p,
        cursor: ({ disabled }) => {
          return disabled ? "default" : "pointer"
        },
        display: "inline-flex",
        padding: theme.spacing(2),
        borderRadius: theme.spacing(4),
        backgroundColor: theme.palette.common.test,
        color: theme.palette.common.black,
        alignItems: "center"
      },
      chipTextContainer: {
        margin: "auto"
      },
      chipIconContainer: {
        display: "flex",
        alignItems: "center"
      }
    };
  }
);
// </editor-fold>

const Chip = (props: ChipProps) => {
  const {
    disabled = false,
    handleClick = () => console.debug("chip clicked"),
    handleClose = () => console.debug("chip close clicked"),
    name
  } = props
  const { chipRoot, chipTextContainer, chipIconContainer } = useStyles({ disabled })
  return (
    <div className={chipRoot} onClick={handleClick}>
      <span className={chipTextContainer}>{name}</span>
      <span className = {chipIconContainer} onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      ><DeleteIcon fontSize="small"></DeleteIcon></span>
    </div>
  );
};

export default Chip;
