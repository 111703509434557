import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { createUseStyles } from "react-jss";
import { Theme } from "../../styles/theme";
import Button from "../../components/Buttons";
import Alert from "../../components/Alert";
import { getApiBaseUrl } from "../../utils/functions";
import { useSnackbar } from "notistack";
import TextField from "../../components/Inputs/TextField";
import CheckBox from "../../components/Inputs/Checkbox";

// <editor-fold desc="Register type definitions ...">
type RuleNames = "registerRoot";

export interface RegisterProps {
}

export type RegisterStyleProps = {
};
// </editor-fold>

// <editor-fold desc="Register style definition ...">
const useStyles = createUseStyles<RuleNames, RegisterStyleProps, Theme>(
  (theme) => {
    return {
      registerRoot: {
        maxWidth: theme.breakpoints.sizes.sm,
        flex: 1,
        marginLeft: "auto",
        marginRight: "auto"
      }
    };
  }
);
// </editor-fold>

const Register = (props: RegisterProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { registerRoot } = useStyles();
  const [me, setMe] = useState<{
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    password: string;
  }>({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
  })

  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState<boolean>(false)
  const informationMissing = me.first_name === "" || me.last_name === "" || me.username === "" || me.email === "" || me.password === ""
  const queryParams = new URLSearchParams(window.location.search);
  const registration_token = queryParams.get('registration_token');

  const handleRegisterUser = async () => {

    const formData = new FormData()
    for (const [key, value] of Object.entries(me)) {
      formData.append(key, value)

    }
    if (registration_token) {
      formData.append('registration_token', registration_token)
    }
    const createRegisterNewUserResult = await fetch(
      `${getApiBaseUrl()}/users/`,
      {
        method: 'POST',
        body: formData
      }
    )
    const registerNewUserResult = await createRegisterNewUserResult.json()
    if (createRegisterNewUserResult.status === 201) {
      enqueueSnackbar("Rekisteröityminen onnistui.", {
        preventDuplicate: true,
        variant: "success"
      });
      navigate("/login/", { replace: true })
    }
    else {
      enqueueSnackbar("Rekisteröityminen epäonnistui, ota yhteyttä WTC-tukeen.", {
        preventDuplicate: true,
        variant: "error"
      });
    }
  }


  useEffect(() => {
    document.title = "WTC Omat tiedot";
  }, [])

  useEffect(() => {
    console.debug("token", registration_token)
  }, [registration_token])

  if (!registration_token) {
    return (
      <div className={registerRoot}>
        <Alert title="Virhe" message="Rekisteröintiavainta ei ole määritetty, ole hyvä ja käytä rekisteröintiin tarkoitettua linkkiä" />
      </div>
    )
  }

  return (
    <div className={registerRoot}>
      <PageTitle component="h2">
        Rekisteröidy
      </PageTitle>
      <TextField
        id={`register-first-name`}
        name="firstName"
        label="Etunimi:"
        value={me?.first_name || ""}
        type={"text"}
        handleChange={(e) => setMe(s => ({ ...s, first_name: e.target.value }))}
        required
      />
      <TextField
        id={`register-last-name`}
        name="lastName"
        label="Sukunimi:"
        value={me?.last_name || ""}
        type={"text"}
        handleChange={(e) => setMe(s => ({ ...s, last_name: e.target.value }))}
        required
      />
      <TextField
        id={`register-username`}
        name="username"
        label="Käyttäjätunnus:"
        value={me?.username || ""}
        type={"text"}
        handleChange={(e) => setMe(s => ({ ...s, username: e.target.value }))}
        required
      />
      <TextField
        id={`register-email`}
        name="email"
        label="Sähköposti:"
        value={me?.email || ""}
        type={"text"}
        handleChange={(e) => setMe(s => ({ ...s, email: e.target.value }))}
        required
      />
      <TextField
        id={`register-password`}
        name="password"
        label="Salasana:"
        value={me?.password || ""}
        type={"password"}
        handleChange={(e) => setMe(s => ({ ...s, password: e.target.value }))}
        required
      />
      <CheckBox
        id={`accept-terms-of-use`}
        name="termsOfUse"
        value={acceptTermsOfUse}
        handleChange={(e) => setAcceptTermsOfUse(e.target.checked)}
        urlText={"Hyväksy käyttöehdot"}
        url="https://wtc-turku.fi/legal"
        disabled={informationMissing}
      />
      <div style={{ margin: "16px 0" }}>
        <Button color="blue" handleClick={() => handleRegisterUser()} disabled={informationMissing || acceptTermsOfUse === false}>
          Rekisteröidy
        </Button>
      </div>
    </div>
  );
};

export default Register;
